import { Component, OnInit } from '@angular/core';
import swal from'sweetalert2';
import { Router, ActivatedRoute } from '@angular/router';
import {SessionService} from 'src/app/service/session.service';
import { User } from 'src/app/model/user';
import { Response } from 'selenium-webdriver/http';
import { FinancieraService } from 'src/app/service/financiera.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  hidePass = true;
  isLoading = false;
  public rutLogin = "";
  public passLogin = "";
  public logo = "logo_tei.png";

  //VAR usuario tercero
  public rutTerceroLogin = "";
  public mailTerceroLogin = "";
  public passTerceroLogin = "";

  constructor(private router: Router,
    private route: ActivatedRoute,
    private sesionService: SessionService,
    private financieraService: FinancieraService) { }

  ngOnInit() {

    var hostname = window.location.hostname;

    if (hostname.indexOf('global') > -1 || hostname.indexOf('gps.grupoglobal') > -1 ) {

      this.logo = "global_logo.png";
    } 
    
    if (hostname.indexOf('gpsone') > -1 || hostname.indexOf('127.0.0.1') > -1 ) {

      this.logo = "logo_gps-one.png";
    }

    this.financieraService.getDataDominio(hostname).subscribe(
      response => {
        console.log(response)
        if (response['url_logo_login'] != undefined ) {

          this.logo = response['url_logo_login'] as string
        }
      }
    );
  }

  logIn() {

    this.isLoading = true;

    if(this.validarForm() == false) {
      this.isLoading = false;
      return false;
    }

    this.rutLogin = this.formatRut(this.rutLogin);

    this.sesionService.login(this.rutLogin.toUpperCase(), this.passLogin).subscribe( response => {

      if (response['login'] == "OFF") {
    
        this.isLoading = false;
     
        swal.fire({
          title: 'Login Incorrecto',
          type: 'error',
          backdrop: 'left center no-repeat rgba(0, 0, 0, 0.88)',
          html: 'Tu nombre de usuario y/o contraseña son incorrectos.',
          confirmButtonText: 'Ok'
        });
      }

      if (response['login'] == true && response['user']['contrato'] == null || response['user']['contrato']['estado'] == "3") {

        swal.fire({
          title: 'Login Incorrecto',
          type: 'warning',
          backdrop: 'left center no-repeat rgba(0, 0, 0, 0.88)',
          html: 'Tu usuario no tiene permisos.',
          confirmButtonText: 'Ok'
        });
        this.isLoading = false;
        return false;
      }

      if (response['login'] == true) {

        localStorage.setItem("sess", response['token']);
        let user = response['user'];
        
        localStorage.setItem("isAdmin", 'NO');

        localStorage.setItem("user", JSON.stringify(response['user']['cuenta']));
        let usurModel = user["cuenta"] as User;

        localStorage.setItem("contratos", JSON.stringify(response['user']['contrato']));
        window.location.href = "/home";
        //this.router.navigate(['/home'], { relativeTo: this.route});
      }
    }, error => {

      swal.fire({
        type: 'warning',
        title: 'No se puede iniciar sesión',
        text: 'Intente nuevamente más tarde.'
      });  
    });
  }

  openRecoveryPass() {

    swal.mixin({
      input: 'text',
      confirmButtonText: 'Recuperar',
      cancelButtonText: 'Cancelar',
      showCancelButton: true,
      progressSteps: [],
      backdrop: 'left center no-repeat rgba(0, 0, 0, 0.88)'
    }).queue([
      {
        title: 'Recuperar Contraseña',
        text: 'Ingrese el rut de la cuenta'
      }
    ]).then((result) => {
      if (result.value) {

        var rutRecovery: string = result.value[0];

        rutRecovery = this.formatRut(rutRecovery);
        rutRecovery = rutRecovery.toUpperCase();

        if (!/^[0-9]+[-|‐]{1}[0-9kK]{1}$/.test( rutRecovery.replace('.','').replace('.','') ) == false && rutRecovery != '') {

          this.isLoading = true;
          var callback = (response) => {
            swal.fire({
              title: 'Listo!',
              type: 'success',
              backdrop: 'left center no-repeat rgba(0, 0, 0, 0.88)',
              html: 'Se envió la contraseña a la dirección de correo electrónico.',
              confirmButtonText: 'Gracias!'
            });
            this.isLoading = false;
          };

          var hostname = window.location.hostname;
          if (hostname.indexOf('gpsone') > -1 || hostname.indexOf('127.0.0.1') > -1 ) {

            this.sesionService.recoveryPassGpsone(rutRecovery).subscribe(callback);            
          } else {

            this.sesionService.recoveryPass(rutRecovery).subscribe(callback);
          }         
        } else {

          swal.fire({
            title: 'Rut no válido',
            type: 'error',
            backdrop: 'left center no-repeat rgba(0, 0, 0, 0.88)',
            html: '',
            confirmButtonText: 'Ok'
          })
        }

        
      }
    })
  }

  logInTercero() {

    this.isLoading = true;

    if(this.validarFormTercero() == false) {
      this.isLoading = false;
      return false;
    }

    this.rutTerceroLogin = this.formatRut(this.rutTerceroLogin);

    this.sesionService.loginTercero(this.rutTerceroLogin.toUpperCase(), this.mailTerceroLogin, this.passTerceroLogin).subscribe( response => {

      if (response['login'] == "OFF") {
    
        this.isLoading = false;
     
        swal.fire({
          title: 'Login Incorrecto',
          type: 'error',
          backdrop: 'left center no-repeat rgba(0, 0, 0, 0.88)',
          html: 'Tu nombre de usuario y/o contraseña son incorrectos.',
          confirmButtonText: 'Ok'
        });
      }

      if (response['login'] == true && response['user']['contrato'] == null || response['user']['contrato']['estado'] == "3") {

        swal.fire({
          title: 'Login Incorrecto',
          type: 'warning',
          backdrop: 'left center no-repeat rgba(0, 0, 0, 0.88)',
          html: 'Tu usuario no tiene permisos.',
          confirmButtonText: 'Ok'
        });
        this.isLoading = false;
        return false;
      }

      if (response['login'] == true) {

        localStorage.setItem("sess", response['token']);
        let user = response['user'];
        
        localStorage.setItem("isAdmin", 'NO');

        localStorage.setItem("user", JSON.stringify(response['user']['cuenta']));
        let usurModel = user["cuenta"] as User;

        localStorage.setItem("contratos", JSON.stringify(response['user']['contrato']));
        window.location.href = "/home";
        //this.router.navigate(['/home'], { relativeTo: this.route});
      }
    }, error => {

      swal.fire({
        type: 'warning',
        title: 'No se puede iniciar sesión',
        text: 'Intente nuevamente más tarde.'
      });  
    });
  }

  validarForm() {

    let valido = true;

    if (this.rutLogin == "") {
      valido = false;

      swal.fire('Login Incorrecto', 'Usuario Vacíos. Favor llenar campos.', 'warning');

    } else if (this.passLogin == "") {

      valido = false;
      swal.fire('Login Incorrecto', 'Contraseña Vacíos. Favor llenar campos.', 'warning');
    }

    return valido;
  }

  validarFormTercero() {

    let valido = true;

    if (this.rutTerceroLogin.trim() == "") {
      valido = false;

      swal.fire('Login Incorrecto', 'Código de cliente vacíos. Favor llenar campos.', 'warning');

    } else if (this.mailTerceroLogin.trim() == "") {

      valido = false;
      swal.fire('Login Incorrecto', 'E-Mail vacíos. Favor llenar campos.', 'warning');
    } else if (this.passTerceroLogin.trim() == "") {

      valido = false;
      swal.fire('Login Incorrecto', 'Contraseña vacíos. Favor llenar campos.', 'warning');
    }

    return valido;
  }

  formatRut(rutText) {

    rutText = rutText.replace('-', '');
    rutText = rutText.replace('.', '');
    rutText = rutText.replace('.', '');

    var auxRut = "";

    for (let index = rutText.length - 1; index >= 0; index--) {

      const element = rutText[index];
      if (auxRut.length == 0) {

        auxRut = '-' + element;
      } else if (auxRut.length == 5) {

        auxRut = element + '.' + auxRut;
      } else if (auxRut.length == 9) {

        auxRut = element + '.' + auxRut;
      } else {

        auxRut = element + auxRut;
      } 
      
    }

    return auxRut;
  }

  
}
