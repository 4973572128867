import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MainManuContentComponent, DialogContact, DialogConfiguracionUsuario, DialogTerminosCondiciones } from './component/main-manu-content/main-manu-content.component';
import { LayoutModule } from '@angular/cdk/layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import swal from 'sweetalert2';
import { OwlDateTimeModule, OwlNativeDateTimeModule } from 'ng-pick-datetime';
//import { PdfViewerModule } from 'ng2-pdf-viewer';


import {
  MatAutocompleteModule,
  MatBadgeModule, 
  MatBottomSheetModule,
  MatButtonModule,
  MatButtonToggleModule,
  MatCardModule,
  MatCheckboxModule,
  MatChipsModule,
  MatDatepickerModule,
  MatDialogModule,
  MatDividerModule,
  MatExpansionModule,
  MatGridListModule,
  MatIconModule,
  MatInputModule,
  MatListModule,
  MatMenuModule,
  MatNativeDateModule,
  MatPaginatorModule,
  MatProgressBarModule,
  MatProgressSpinnerModule,
  MatRadioModule,
  MatRippleModule,
  MatSelectModule,
  MatSidenavModule,
  MatSliderModule,
  MatSlideToggleModule,
  MatSnackBarModule,
  MatSortModule,
  MatStepperModule,
  MatTableModule,
  MatTabsModule,
  MatToolbarModule,
  MatTooltipModule,
  MatTreeModule,
  MatFormFieldModule
} from '@angular/material';
import { LoginComponent } from './component/login/login.component';
import { HomeComponent } from './component/home/home.component';
import { MapComponent } from './component/map/map.component';
import { RutaReportComponent } from './component/ruta-report/ruta-report.component';
import { UltimasUbicacionesReportComponent } from './component/ultimas-ubicaciones-report/ultimas-ubicaciones-report.component';
import { DireccionesFrecuentesReportComponent } from './component/direcciones-frecuentes-report/direcciones-frecuentes-report.component';
import { MainHomeContentComponent, BottomSheetMenu, DialogControlVelocidad, DialogCambiarAlias, DialogCambiarConductor } from './component/main-home-content/main-home-content.component';
import { GpsListContentComponent } from './component/gps-list-content/gps-list-content.component';
import { ReportListComponentComponent } from './component/report-list-component/report-list-component.component';
import { DesconexiongGpsReportComponent } from './component/report/desconexiong-gps-report/desconexiong-gps-report.component';
import { ReportCrashEventComponent } from './component/crash-event/report-crash-event/report-crash-event.component';
import { DetailCrashEventComponent } from './component/crash-event/detail-crash-event/detail-crash-event.component';
import { ListCrashEventComponent } from './component/crash-event/list-crash-event/list-crash-event.component';
import { ListDetailCrashEventComponent } from './component/crash-event/list-detail-crash-event/list-detail-crash-event.component';
import { ExcesoVelocidadesReportComponent } from './component/exceso-velocidades-report/exceso-velocidades-report.component';
import { NotificacionesComponent } from './component/notificaciones/notificaciones.component';
import { ScrollingModule, ScrollDispatchModule } from '@angular/cdk/scrolling';
import { FormGeozonaComponent } from './component/form-geozona/form-geozona.component';
import { FormDeleteGeozonaComponent } from './component/form-geozona/form-delete-geozona/form-delete-geozona.component';
import { BotonPanicoReportComponent } from './component/boton-panico-report/boton-panico-report.component';
import { OdometroReportComponent } from './component/odometro-report/odometro-report.component';
import { OdometroGlobalReportComponent } from './component/odometro-global-report/odometro-global-report.component';
import { PantallaReporteOdometroComponent } from './component/report/pantalla-reporte-odometro/pantalla-reporte-odometro.component';
import { TablaReportOdometroComponent } from './component/report/pantalla-reporte-odometro/tabla-report-odometro/tabla-report-odometro.component';
import { PantallaAdminUsuarioTerceroComponent } from './component/pantalla-admin-usuario-tercero/pantalla-admin-usuario-tercero.component';
import { FormularioUsuarioTerceroComponent } from './component/pantalla-admin-usuario-tercero/formulario-usuario-tercero/formulario-usuario-tercero.component';
import { TableUsuarioTerceroComponent } from './component/pantalla-admin-usuario-tercero/table-usuario-tercero/table-usuario-tercero.component';
import { FormularioVehiculoTerceroComponent } from './component/pantalla-admin-usuario-tercero/formulario-vehiculo-tercero/formulario-vehiculo-tercero.component';
import { PantallaReporteKilometroRutaComponent } from './component/report/pantalla-reporte-kilometro-ruta/pantalla-reporte-kilometro-ruta.component';
import { TimeLineKilometrajeComponent } from './component/report/pantalla-reporte-kilometro-ruta/time-line-kilometraje/time-line-kilometraje.component';
import { FormularioConductorComponent } from './component/pantalla-admin-conductores/formulario-conductor/formulario-conductor.component';
import { PantallaAdminConductorComponent } from './component/pantalla-admin-conductores/pantalla-admin-conductor.component';
import { TableConductorComponent } from './component/pantalla-admin-conductores/table-conductor/table-conductor.component';
import { PantallaReporteConductorVehiculoComponent } from './component/report/pantalla-reporte-conductor-vehiculo/pantalla-reporte-conductor-vehiculo.component';
import { PantallaAdminSmartreportComponent } from './component/pantalla-admin-smartreport/pantalla-admin-smartreport.component';
import { FormularioSmartreportComponent } from './component/pantalla-admin-smartreport/formulario-smartreport/formulario-smartreport.component';
import { TableSmartreportComponent } from './component/pantalla-admin-smartreport/table-smartreport/table-smartreport.component';
import { BlackListNotificacionesComponent } from './component/notificaciones/black-list-notificaciones/black-list-notificaciones.component';
import { PantallaReporteBateriaExternaComponent } from './component/report/pantalla-reporte-bateria-externa/pantalla-reporte-bateria-externa.component';
import { AlertaRemolqueReportComponent } from './component/report/alerta-remolque-report/alerta-remolque-report.component';

@NgModule({
  declarations: [
    AppComponent,
    MainManuContentComponent,
    LoginComponent,
    HomeComponent,
    MapComponent,
    RutaReportComponent,
    UltimasUbicacionesReportComponent,
    DireccionesFrecuentesReportComponent,
    MainHomeContentComponent,
    GpsListContentComponent,
    BottomSheetMenu,
    DialogContact,
    DialogConfiguracionUsuario,
    DialogTerminosCondiciones,
    DialogControlVelocidad,
    ReportListComponentComponent,
    DesconexiongGpsReportComponent,
    ReportCrashEventComponent,
    DetailCrashEventComponent,
    ListCrashEventComponent,
    ListDetailCrashEventComponent,
    ExcesoVelocidadesReportComponent,
    NotificacionesComponent,
    FormGeozonaComponent,
    FormDeleteGeozonaComponent,
    BotonPanicoReportComponent,
    OdometroReportComponent,
    OdometroGlobalReportComponent,
    PantallaReporteOdometroComponent,
    TablaReportOdometroComponent,
    PantallaAdminUsuarioTerceroComponent,
    FormularioUsuarioTerceroComponent,
    TableUsuarioTerceroComponent,
    FormularioVehiculoTerceroComponent,
    PantallaReporteKilometroRutaComponent,
    TimeLineKilometrajeComponent,
    DialogCambiarAlias,
    FormularioConductorComponent,
    PantallaAdminConductorComponent,
    TableConductorComponent,
    DialogCambiarConductor,
    PantallaReporteConductorVehiculoComponent,
    PantallaAdminSmartreportComponent,
    FormularioSmartreportComponent,
    TableSmartreportComponent,
    BlackListNotificacionesComponent,
    PantallaReporteBateriaExternaComponent,
    AlertaRemolqueReportComponent
  ],
  entryComponents: [
    BottomSheetMenu, 
    MainHomeContentComponent, 
    DialogContact, 
    DialogConfiguracionUsuario, 
    DialogControlVelocidad, 
    NotificacionesComponent, 
    FormGeozonaComponent, 
    FormDeleteGeozonaComponent, 
    DialogTerminosCondiciones, 
    FormularioUsuarioTerceroComponent,
    FormularioVehiculoTerceroComponent,
    FormularioConductorComponent,
    DialogCambiarAlias,
    DialogCambiarConductor,
    PantallaReporteConductorVehiculoComponent,
    FormularioSmartreportComponent,
    BlackListNotificacionesComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    BrowserAnimationsModule,
    LayoutModule,
    FormsModule,
    ReactiveFormsModule,
    MatAutocompleteModule,
    MatBadgeModule,
    MatBottomSheetModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatCardModule,
    MatCheckboxModule,
    MatChipsModule,
    MatDatepickerModule,
    MatDialogModule,
    MatDividerModule,
    MatExpansionModule,
    MatFormFieldModule,
    MatGridListModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatMenuModule,
    MatNativeDateModule,
    MatPaginatorModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatRadioModule,
    MatRippleModule,
    MatSelectModule,
    MatSidenavModule,
    MatSliderModule,
    MatSlideToggleModule,
    MatSnackBarModule,
    MatSortModule,
    MatStepperModule,
    MatTableModule,
    MatTabsModule,
    MatToolbarModule,
    MatTooltipModule,
    MatTreeModule,
    OwlDateTimeModule,
    OwlNativeDateTimeModule,
    ScrollingModule,
    ScrollDispatchModule,
    //PdfViewerModule,
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
