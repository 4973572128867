import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { EndPoindService } from './end-poind.service';

@Injectable({
  providedIn: 'root'
})
export class ComunaService {

  BASE_URL: String = this.api.END_POINT; 
  httpOptions = {};

  constructor(private http: HttpClient, public api: EndPoindService) {

    var token = localStorage.getItem('sess');
    if (token != undefined && token != null) {
    
      this.httpOptions = {
        headers: new HttpHeaders({
          'content-type':  'application/json',
          'authorization-x': token.toString()
        })
      };
    } 
  }

  getAll() {

    return this.http.get(this.BASE_URL.toString().concat('/comuna'), this.httpOptions);
  }


}
