import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { GpsMap } from 'src/app/model/gps-map';
import { Report } from 'src/app/model/report';
import { FormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { catchError, map, tap, startWith, switchMap, debounceTime, distinctUntilChanged, takeWhile, first } from 'rxjs/operators';
import swal from 'sweetalert2';
import { ActivatedRoute } from '@angular/router';
import { Response } from 'selenium-webdriver/http';
import { MapService } from '../../service/map.service';
import { ReportService } from '../../service/report.service';
import { GoogleService } from 'src/app/service/google.service';
import { OmsLayerService } from 'src/app/service/oms-layer.service';
import { GoogleLayerService } from 'src/app/service/google-layer.service';
import { MatDialog, MatSnackBar } from '@angular/material';
import { FormGeozonaComponent } from '../form-geozona/form-geozona.component';
import { GeozonaService } from 'src/app/service/geozona.service';
import { Geozona } from 'src/app/model/geozona';
import { parse } from '../../../../node_modules/wkt'
import Swal from 'sweetalert2';
import { resolve } from 'url';

declare let L;


@Component({
  selector: 'app-map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.css']
})
export class MapComponent implements OnInit {

  public pointMap = null;
  public mapMain;
  public markers;
  public baseMaps;

  @Input() controlGeozonaOn: boolean; 
  @Output() emitRecargaGeozona:EventEmitter<Boolean> = new EventEmitter<Boolean>();

  drawnItems: any;

  poligonEditable: any;
  
  geozonasList: Geozona[] = [];

  private styleFeature = {
    content: "off",
    weight: 1,
    color: "#999",
    opacity: 0.7,
    fillColor: '#00eccf',
    fillOpacity: 0.3
  };

  private styleSelectFeature = {
      content: "on",
      fillColor: '#f15e5e',
      fillOpacity: 0.8,
      weight: 0.5
  }



  constructor(private route: ActivatedRoute,
    public mapService: MapService,
    private reportService: ReportService,
    private _omsLayer: OmsLayerService,
    private _googleLayer: GoogleLayerService,
    private googleService: GoogleService,
    public dialog: MatDialog,
    private _geozonaService: GeozonaService,
    private _snackBar: MatSnackBar
  ) {
    this.drawnItems = new L.FeatureGroup();


    
  }

  ngOnInit() {

    this.loadMap();
  }

  loadMap() {

    this.mapMain = L.map('map', {editable: true}).setView([-33.4075903, -70.5762495], 10);
    this.mapMain.listAux = [];

    this.baseMaps = {
      "Open Street Map": this._omsLayer.osm(),
      "Google Silver": this._googleLayer.silver(),
      "Google Roadmap": this._googleLayer.roadmap(),
      "Google Roadmap Aubergine": this._googleLayer.roadmapAubergine(),
      "Google Trafico": this._googleLayer.trafficMutant(),
      "Google Hybrid": this._googleLayer.hybrid(),
      "Google Satellite": this._googleLayer.satellite(),
      "Google Terrain": this._googleLayer.terrain(),
      "Grayscale": this._omsLayer.osm_hot(),
      "Esri World Topo Map": this._omsLayer.esri_WorldTopoMap(),
      "Esri World Imagery": this._omsLayer.esri_WorldImagery()
    }

    var ctrlLayers = L.control.layers(this.baseMaps, (this.controlGeozonaOn) ? {
      'Geo Zonas': this.drawnItems
    }: {}, {
      autoZIndex: false,
      hideSingleBase: true,
      position: 'topleft'
    });
    this._omsLayer.osm().addTo(this.mapMain);
    ctrlLayers.addTo(this.mapMain);

    this.mapMain.locate({ setView: true, maxZoom: 16 });
    
    /*
    L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
          attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
    }).addTo(this.mapMain);*/

    //this.markers = L.markerClusterGroup({ disableClusteringAtZoom: 13 });
    var ws = this.mapService;

    this.mapMain.on('dblclick', function (e) {

      ws.getDireccionTextByLatLon(e.latlng.lat, e.latlng.lng).subscribe((response) => {

        L.popup().setLatLng(e.latlng).setContent(
          "<center><img src='assets/img/photo.png' height='62' width='62'></center>" +
          "<br><p style='color: #757e87; margin-top: 0px; margin-bottom: 0px;'>" +
          response['direccion']
          + "</p>  "
        ).openOn(this);
      });
    });

    this.mapMain.on('moveend', function () {

      var bounds = this.getBounds();
      var isMarkerInsideBounds = (marker, bounds) => {
        var matrisPoint = [];
        matrisPoint.push(bounds.getNorthEast());
        matrisPoint.push(bounds.getNorthWest());
        matrisPoint.push(bounds.getSouthWest());
        matrisPoint.push(bounds.getSouthEast());
        var polyPoints = matrisPoint;
        var x = marker.getLatLng().lat, y = marker.getLatLng().lng;
        var inside = false;
        for (var i = 0, j = polyPoints.length - 1; i < polyPoints.length; j = i++) {
          var xi = polyPoints[i].lat, yi = polyPoints[i].lng;
          var xj = polyPoints[j].lat, yj = polyPoints[j].lng;
          var intersect = ((yi > y) != (yj > y)) && (x < (xj - xi) * (y - yi) / (yj - yi) + xi);
          if (intersect) inside = !inside;
        }
        return inside;
      };

      /*if (layer.options.name === 'XXXXX') {
          layer.setLatLng([newLat,newLon])
      } */
      this.listAux.forEach(layer => {

        if (layer.options.nameClass == "GpsMap") {

          //layer.remove()
          //this.markers.addLayer(layer);
          //this.mapMain.addLayer(this.markers);

          if (!isMarkerInsideBounds(layer, bounds)) {

            layer.remove()
          } else {

            layer.addTo(this);
          }
        }
      });
    });

    this.addFeatureGroup();


    this.poligonEditable = new L.Editable(this.mapMain);

    console.dir(this.poligonEditable)
    //this.poligonEditable.addTo(this.mapMain)
  }

  public selectMapSilver() {
    this._googleLayer.silver().addTo(this.mapMain);
  }

  /**
   * Centra el mapa en base a una geocerca.
   */
  public centerGeozonnaMap(geozona: Geozona) {

    const parseWKT = parse(geozona.figura);

    if (parseWKT.coordinates.length > 0) {

      const figura = parseWKT.coordinates[0];
      this.mapMain.flyTo([ figura[0][1], figura[0][0]], 17);
      this.mapMain.invalidateSize();
    }
  }

  public centerMap(latitud, longitud, zoom: number = 15) {

    this.mapMain.flyTo([latitud, longitud], zoom);
    this.mapMain.invalidateSize();
  }

  public removeAllLayer() {

    this.mapMain.listAux.forEach(layer => {

      this.mapMain.removeLayer(layer);
    });
    this.mapMain.listAux = [];
  }

  public updateGps(gps: GpsMap) {

    /*if (layer.options.name === 'XXXXX') {
            layer.setLatLng([newLat,newLon])
        } */
    this.mapMain.listAux.forEach((layer, index) => {
      if (layer.options.nameClass == "GpsMap" && layer.options.data.serial == gps.serial) {
        this.mapMain.removeLayer(layer);
        this.mapMain.listAux.splice(index, 1);
        this.addPonintMap(gps);
      }
    });

  }

  public updateSeeViewGps(gps: GpsMap) {

    /*if (layer.options.name === 'XXXXX') {
      layer.setLatLng([newLat,newLon])
    } */
    var noExiste = true;
    this.mapMain.listAux.forEach(layer => {

      if (layer.options.nameClass == "GpsMap" && layer.options.data.imei == gps.imei) {

        if (!gps.seeView) {

          noExiste = false;
          layer.remove();
        } else {

          noExiste = false;
          layer.addTo(this.mapMain);
        }
      }
    });

    //Si el gps no se encontraba en la lista del mapa se agrega.
    if (noExiste) {

      this.addPonintMap(gps);
    }
  }

  public obtenerPuntoVerde(gps: GpsMap) {

    if (gps.angulo > 350 || (gps.angulo < -1 && gps.angulo < 21)) {

      return 'assets/img/market-verde-351-20.png'
    } else if (gps.angulo > 20 && gps.angulo < 81) {

      return 'assets/img/market-verde-21-80.png'
    } else if (gps.angulo > 80 && gps.angulo < 101) {

      return 'assets/img/market-verde-81-100.png'
    } else if (gps.angulo > 100 && gps.angulo < 171) {

      return 'assets/img/market-verde-101-170.png'
    } else if (gps.angulo > 170 && gps.angulo < 191) {

      return 'assets/img/market-verde-171-190.png'
    } else if (gps.angulo > 190 && gps.angulo < 251) {

      return 'assets/img/market-verde-191-250.png'
    } else if (gps.angulo > 250 && gps.angulo < 291) {

      return 'assets/img/market-verde-251-290.png'
    } else if (gps.angulo > 290 && gps.angulo < 251) {

      return 'assets/img/market-verde-291-350.png'
    } else {

      return 'assets/img/market-verde-171-190.png'
    }

  }
  public addPonintMap(gps: GpsMap) {

    var greenIcon = L.icon({

      iconUrl: (gps.diff < 5) ? this.obtenerPuntoVerde(gps) : (gps.diff > 4 && gps.diff < 24) ? 'assets/img/market-amarillo-2.png' : 'assets/img/market-rojo-2.png',
      iconSize: [30, 40]
    });

    const ws = this.mapService;
    const wsReport = this.reportService;
    const user = JSON.parse(localStorage.getItem('user'));
    const rut: string = user['rut'];

    var getTextPop = (g) => "<center><img src='assets/img/route-vehiculo.png' height='62' width='62'></center>" +
      "<br><p style='color: #757e87; margin-top: 0px; margin-bottom: 0px;'><b style='color: #00265b;'>DISPOSITIVO " + (g.alias ? g.alias : g.vin) + "</b><br>" +
      "<b>Conductor:</b> " + (g.conductor_nombre_completo ? g.conductor_nombre_completo : 'Sin conductor asociado') + "<br>" +
      "<b>N° Teléfono:</b> " + g.telefono + "<br>" +
      "<b>Fecha Posición:</b> " + g.fechaGPS + "<br>" +

      "<b>Dirección:</b> " + g.geo + "<br>" +

      ((g.ignicion == null) ? '' : "<b>Ignición del Vehiculo:</b> " + ((g.ignicion == 1) ? 'Encendido' : 'Apagado') + "<br>") +
      ((g.desconectado == null) ? '' : "<b>Estado de Conexión:</b> " + ((g.desconectado == 1) ? 'GPS Desconectado' : 'GPS Conectado') + "<br>") +

      "<b>Velocidad Actual:</b> " + g.velocidadActual + " km/h<br>" +
      "<b>Velocidad Permitida:</b> " + g.limiteVelocidad + " km/h<br>" +
      "<b>Promedio 1 Hora:</b> " + ((g.velocidadPromedio) ? g.velocidadPromedio : 0) + '  km/h<br>' +
      "<b>Promedio 12 Hora:</b> " + ((g.velocidadPromedioDoce) ? g.velocidadPromedioDoce : 0) + '  km/h<br></p>' +

      "<details style='color: #757e87;'>" +
      "<summary>Ver más..</summary>" +
      "<b>Fecha Registro:</b> " + g.fechaEvento + "<br>" +
      "<b>Equipo GPS:</b> " + g.serial + "<br>" +
      "<b>Imei:</b> " + g.imei + "<br>" +
      "<b>Modelo:</b> " + g.nombreModelo + "<br>" +
      "<b>Coordenadas:</b> " + g.latitud + ", " + g.longitud + "<br>" +
      ((g.voltajeExterno == null) ? '' : "<b>Voltaje Externo:</b>" + g.voltajeExterno + " mV<br>") +
      ((g.movimiento == null) ? '' : "<b>Movimiento:</b>" + ((g.movimiento == 1) ? 'Si' : 'No') + "<br>") +
      "</details>" +
      "<br>" +
      "<p style='color: #757e87;  margin-top: 0px; margin-bottom: 0px;'><b style='color: #00265b;'>CLIENTE</b><br>" +
      ((rut == "1-9") ? "<b>Operación:</b> " + ((g.operacion == null) ? 'N/A' : g.operacion) + "<br>" : "") +
      ((rut == "1-9") ? "<b>Nombre:</b> " + ((g.nombreCliente == null) ? 'N/A' : g.nombreCliente) + "<br>" : "") +
      ((rut == "1-9") ? "<b>Rut:</b> " + ((g.rutCliente == null) ? 'N/A' : g.rutCliente) + "<br>" : "") +
      ((rut == "1-9") ? "<b>Teléfono Cliente:</b> " + ((g.telefono_cliente == null) ? 'N/A' : g.telefono_cliente) + "<br>" : "") +
      ((rut == "1-9") ? "<b>Correo Electrónico:</b> " + ((g.mail == null) ? 'N/A' : g.mail) + "<br>" : "") +


      "<b>Vehículo:</b>" + ((g.vehiculo == null) ? 'N/A' : g.vehiculo) + "<br>" +
      "<b>Patente/Vin:</b>" + ((g.vin == null) ? 'N/A' : g.vin) + "<br></p>";

    gps.geo = "Cargando...";
    var textPopup = getTextPop(gps);

    //var pointMap = L.marker([gps.latitud, gps.longitud], {nameClass: 'GpsMap', icon: greenIcon, data: gps}).bindPopup(textPopup);
    var pointMap = L.marker([gps.latitud, gps.longitud], { nameClass: 'GpsMap', icon: greenIcon, data: gps }).bindPopup(textPopup).on('popupopen', function (e) {

      ws.getDireccionTextAWSByLatLon(gps.latitud, gps.longitud).subscribe((response) => {

        const direccion: string = response['direccion']
        if (direccion.includes("coordenadas")) {
          ws.getDireccionTextByLatLon(gps.latitud, gps.longitud).subscribe((response) => {

            gps.geo = response['direccion'];
            e.popup._content = getTextPop(gps);
            e.popup.setContent(getTextPop(gps));
          });
        } else {
          gps.geo = response['direccion'];
          e.popup._content = getTextPop(gps);
          e.popup.setContent(getTextPop(gps));
        }
      })

      // Promedio de velocidad 1 hora.
      var dateInit = new Date();
      dateInit.setHours(dateInit.getHours() - 1);
      var dateEnd = new Date();
      wsReport.getRuta(gps.serial, dateInit.getTime(), dateEnd.getTime()).subscribe((r) => {

        var total = 0;
        var dataList = r as Report[];
        dataList.forEach(d => {
          total = total + Number.parseInt(d.velocidad)
        });

        var promedio = total / dataList.length;

        gps.velocidadPromedio = Math.round(promedio);
        e.popup._content = getTextPop(gps);
        e.popup.setContent(getTextPop(gps));
      });

      //Promedio de velocidad 12 horas
      var dateInit2 = new Date();
      dateInit2.setHours(dateInit2.getHours() - 12);
      wsReport.getRuta(gps.serial, dateInit2.getTime(), dateEnd.getTime()).subscribe((r) => {

        var total = 0;
        var dataList = r as Report[];
        dataList.forEach(d => {
          total = total + Number.parseInt(d.velocidad)
        });

        var promedio = total / dataList.length;

        gps.velocidadPromedioDoce = Math.round(promedio);
        e.popup._content = getTextPop(gps);
        e.popup.setContent(getTextPop(gps));
      });
    });

    this.mapMain.listAux.push(pointMap);
    if (this.isMarkerInsideBounds(pointMap, this.mapMain.getBounds())) {
      //this.markers.addLayer(pointMap);
      //this.mapMain.addLayer(this.markers);
      pointMap.addTo(this.mapMain);
    }
  }

  public setPointReportMap(report: Report, iconRuta: string = 'assets/img/market-default.png', iconSize: Array<number> = [30, 40]) {

    var greenIcon = L.icon({
      iconUrl: iconRuta,
      iconSize: iconSize
    });
    var textPopup = //"<b>DETALLE </b><br>" +
      "<center><img src='assets/img/route-vehiculo.png' height='62' width='62'></center>" +
      "<p style='color: #757e87; margin-top: 0px; margin-bottom: 0px;'><b>Fecha:</b>" + report.fechaGPS + "<br>" +
      "<b>Dirección:</b>" + report.geo + "<br></p>";
    // "<b>Velocidad:</b>" + ( report.velocidad != undefined  ) ? report.velocidad: '' + " (km/hr)<br>";

    var pointMap = L.marker([report.latitud, report.longitud], { nameClass: 'PointReportMap', icon: greenIcon, data: report }).bindPopup(textPopup);
    this.mapMain.listAux.push(pointMap);
    pointMap.addTo(this.mapMain);

  }

  public clearPointReportMap() {

    this.mapMain.listAux.forEach(layer => {

      if (layer.options.nameClass == "PointReportMap") {

        layer.remove();
      }
    });
  }

  public setLineByReports(reports: Report[]) {

    this.mapMain.listAux.forEach(layer => {

      if (layer.options.nameClass == "LineReportMap") {

        layer.remove();
      }
    });

    var pointList = reports.map(r => new L.LatLng(r.latitud, r.longitud));
    var firstpolyline = new L.Polyline(pointList, {
      nameClass: 'LineReportMap',
      color: 'red',
      weight: 5,
      opacity: 0.5,
      dashArray: '5,15',
      lineJoin: 'round'
      //,
      //smoothFactor: 1
    });
    firstpolyline.addTo(this.mapMain);
    this.mapMain.listAux.push(firstpolyline);

    if (reports.length > 0) {

      this.mapMain.flyTo([reports[0].latitud, reports[0].longitud], 13);
    }
  }

  isMarkerInsideBounds = (marker, bounds) => {
    var matrisPoint = [];
    matrisPoint.push(bounds.getNorthEast());
    matrisPoint.push(bounds.getNorthWest());
    matrisPoint.push(bounds.getSouthWest());
    matrisPoint.push(bounds.getSouthEast());
    var polyPoints = matrisPoint;
    var x = marker.getLatLng().lat, y = marker.getLatLng().lng;
    var inside = false;
    for (var i = 0, j = polyPoints.length - 1; i < polyPoints.length; j = i++) {
      var xi = polyPoints[i].lat, yi = polyPoints[i].lng;
      var xj = polyPoints[j].lat, yj = polyPoints[j].lng;
      var intersect = ((yi > y) != (yj > y)) && (x < (xj - xi) * (y - yi) / (yj - yi) + xi);
      if (intersect) inside = !inside;
    }
    return inside;
  };

  addFeatureGroup() {

    if(!this.controlGeozonaOn) {
      return;
    }

    let drawnItems = this.drawnItems;
    let context = this;
    this.mapMain.addLayer(drawnItems);

    var drawControl = new L.Control.Draw({
      position: 'topright',
      draw: {
        polyline: false,
        polygon: {
          allowIntersection: false,
          showArea: true,
          drawError: {
            color: '#b00b00',
            timeout: 1000
          },
          shapeOptions: {
            weight: 1,
            color: "#999",
            opacity: 0.7,
            fillColor: '#00eccf',
            fillOpacity: 0.3
          }
        },
        
        circle: false,
        marker: false
      },
      edit: {
        featureGroup: drawnItems,
        edit: false, 
        remove: true
      }
    });

    this.mapMain.addControl(drawControl);

    this.mapMain.on('draw:created', function (this, e) {
      const type = e.layerType,
        layer = e.layer;

      if (type === 'marker') {
        layer.bindPopup('A popup!');
      }

      const data= context.transformLayerToJson(layer);
      data.action = 'agregar';
      let dialogRef = context.dialog.open(FormGeozonaComponent, { data: data });

      dialogRef.afterClosed().subscribe(result => {
        
        if (result) {

          const geo = result as Geozona

          layer.bindPopup(context.buildPopupGeo(geo));
       
          //drawnItems.addLayer(layer);
          //context.getGeozonas()
          context.emitRecargaGeozona.emit(true)
        }
      });

    });

    this.mapMain.on('draw:edited', function (e) {
      const layers = e.layers;
      var countOfEditedLayers = 0;
      layers.eachLayer(function (layer) {

        const geojsonFromLayer = layer.toGeoJSON();

        console.log('EDIT GEOJSON', geojsonFromLayer);
        
        
      });

      console.dir(layers, 'LAYER FROM EDIT')
      console.log("Edited " + countOfEditedLayers + " layers");
    });

    const selectFeature = (e) => {

      console.log(e);
      e.layer.setStyle(context.styleSelectFeature);
    }

    this.mapMain.on('draw:deletestart', function (e) {

      //Se activa el evento click para la selección
      context.drawnItems.eachLayer(layer => {
        layer.on('click', selectFeature)
      });
    })

    this.mapMain.on('draw:deletestop', function (e) {

      console.dir(e)
      context.drawnItems.eachLayer(layer => {
        layer.off('click', selectFeature)
        layer.setStyle(context.styleFeature);
      });

      //TODO: Aqui se debe detectar si el evento se lanza desde "Guardar"
      //TODO: No ejecutar getGeozonas(), cuando el evento se dispare desde guargar.
      context.getGeozonas()
    })

    this.mapMain.on('draw:deleted', function (e) {

      const geojsonFromLayer = e.layers.toGeoJSON();

      e.layers.eachLayer(layer => {
        context.drawnItems.removeLayer(layer);
        context.mapMain.removeLayer(layer);
      });

      context._geozonaService.deleteGeozonaById(geojsonFromLayer.features[0].properties.geozonas_id).subscribe(
        res => {

          context.getGeozonas();
          context._snackBar.open('Geozona eliminada!', "Ok", {
            duration: 4000,
          });
        },
        err=>{

          context.getGeozonas();
          Swal.fire('ERROR', 'Se ha producido un error al intentar eliminar la geozona','warning');
        }
      );
    });
  }

  transformLayerToJson(layer) {

    const json = layer.toGeoJSON();

    if (layer instanceof L.Circle) json.properties.radius = layer.getRadius();
    console.dir(json, 'geojson poligono');

    return json;
  }

  /**
   * Carga las geozonas guardadas en la base de datos.
   */
  getGeozonas() {

    this._geozonaService.getGeozonas().subscribe(data => {

      this.drawnItems.eachLayer(layer => {
        this.drawnItems.removeLayer(layer);
        this.mapMain.removeLayer(layer);
      });

      this.geozonasList = data;
      this.setGeozonasToMap();
    });
  }

  public loadGeoZona(geozonas: Geozona[]) {

    this.drawnItems.eachLayer(layer => {
      this.drawnItems.removeLayer(layer);
      this.mapMain.removeLayer(layer);
    });

    this.geozonasList = geozonas;
    this.setGeozonasToMap();
  }

  /**
   * Funcion que dibuba las geozonas en el mapa.
   */
  setGeozonasToMap() {

    
    this.geozonasList.forEach(geozona => {

      const parseWKT = parse(geozona.figura);

      const geozonaGeojson = {
        type: 'Feature',
        geometry: {
          type: 'Polygon',
          coordinates: parseWKT.coordinates
        },
        properties: {
          descripcion: geozona.descripcion,
          evento_activo: geozona.evento_activo,
          geozonas_id: geozona.geozonas_id,
          nombre: geozona.nombre,
          tipo_event_geo_id: geozona.tipo_event_geo_id,
          usuario_cod: geozona.usuario_cod,
          scope: geozona,
        }
      }


      const layer = new L.geoJSON(geozonaGeojson, {
        style: this.styleFeature
      }).bindPopup(this.buildPopupGeo(geozona));
      layer.addTo(this.drawnItems);
    });
  }

  /**
   * Funcion que se encarga de crear el contenido de un popup de la geozona.
   * @param geozona 
   */
  buildPopupGeo(geozona: Geozona): String {

    const getTextPop = "<center><img src='assets/img/popup-geozona.png' height='96' width='96'></center>" +
      "<br><p style='color: #757e87; margin-top: 0px; margin-bottom: 0px;'><b style='color: #00265b;'>Geozona</b><br>" +
      "<b>Nombre:</b> " + geozona.nombre + "<br>" +
      "<b>Descripción:</b> " +geozona.descripcion + "<br>"
    return getTextPop;
  }
}
