import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { GpsMap } from 'src/app/model/gps-map';
import { HistorialAsignacionConductorService } from 'src/app/service/historial-asignacion-conductor.service';


@Component({
  selector: 'app-pantalla-reporte-conductor-vehiculo',
  templateUrl: './pantalla-reporte-conductor-vehiculo.component.html',
  styleUrls: ['./pantalla-reporte-conductor-vehiculo.component.css']
})
export class PantallaReporteConductorVehiculoComponent implements OnInit {

  public listHistorica:  HistorialAsignacionConductorService[] = [];
  public isLoad = false;
  public nombresMes = ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre" ];


  constructor(public dialogRef: MatDialogRef<PantallaReporteConductorVehiculoComponent>,
    @Inject(MAT_DIALOG_DATA) public data: GpsMap, public historialConductoresService: HistorialAsignacionConductorService) { }

  ngOnInit() {

    this.isLoad = true;
    this.historialConductoresService.getAllByOperacion(this.data.operacion).subscribe( response => {

      this.listHistorica = response as HistorialAsignacionConductorService[]
      this.isLoad = false;
    }, error => this.isLoad = false);
    
  }

  transformarFechaText(fecha_text) {

    var date = new Date(fecha_text);
    let mes = this.nombresMes[date.getMonth()]
    return date.getDate() + ' de ' + mes + ' del ' + date.getFullYear()

  }

}
