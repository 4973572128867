import { Component, OnInit } from '@angular/core';
import { Push } from 'src/app/model/push';
import { PushService } from 'src/app/service/push.service';

@Component({
  selector: 'app-black-list-notificaciones',
  templateUrl: './black-list-notificaciones.component.html',
  styleUrls: ['./black-list-notificaciones.component.css']
})
export class BlackListNotificacionesComponent implements OnInit {

  public listTipoPush: Push[] = [];

  public listPushExcluidas: Push[] = [];

  public loading: boolean = false;


  public tipo_push_id: number;

  constructor(public pushService: PushService) { }

  ngOnInit() {

    this.loadData()
  }

  loadData() {

    this.loading = true;
    this.pushService.getTipoPush().subscribe(response => {
      this.listTipoPush = response as Push[]
      this.listTipoPush = this.listTipoPush.filter(t => t.tipo_push_id != 1)
    })

    console.log("getPushexclusion")
    this.pushService.getPushexclusion().subscribe(response => {
      console.log("getPushexclusion", response)

      if (response) {

        this.listPushExcluidas = response as Push[]
      } else {
        this.listPushExcluidas = []
      }
      this.loading = false;
    }, error => {
      console.error("getPushexclusion", error)
      this.loading = false
    
    })

    this.tipo_push_id = 0;
  }

  addNotificacion() {

    if (this.tipo_push_id) {

      const filtradas = this.listPushExcluidas.filter(pe => pe.tipo_push_id == this.tipo_push_id)

      if (filtradas && filtradas.length > 0) {

        this.loadData()
        return;
      }

      this.loading = true
      this.pushService.createPushexclusion({"tipo_push_id" : this.tipo_push_id}).subscribe(response => {

        this.loadData()
        this.loading = false
      }, error => this.loading = false)
    }
  }

  removeNotificacion(id) {

    if (id) {

      this.listPushExcluidas = this.listPushExcluidas.filter(d => d.push_exclusion_id != id)

      this.pushService.deletePushexclusion(id).subscribe(response => {

        this.loadData()
      })
    }
  }

}
