import { Component, OnInit, ViewChild } from '@angular/core';
import { ReportOdometro } from 'src/app/model/reporte-odometro';
import { User } from 'src/app/model/user';
import { ExcelService } from 'src/app/service/excel.service';
import { ReportService } from 'src/app/service/report.service';
import { TablaReportOdometroItem } from './tabla-report-odometro/tabla-report-odometro-datasource';
import { TablaReportOdometroComponent } from './tabla-report-odometro/tabla-report-odometro.component';

@Component({
  selector: 'app-pantalla-reporte-odometro',
  templateUrl: './pantalla-reporte-odometro.component.html',
  styleUrls: ['./pantalla-reporte-odometro.component.css']
})
export class PantallaReporteOdometroComponent implements OnInit {

  public listaActiva = true;
  public notificacionIcon = 'notifications_none';
  public total;
  public loading = false;
  public user: User;
  public fecha_creacion = new Date();
  public lista: TablaReportOdometroItem[];

  @ViewChild('table') table: TablaReportOdometroComponent;

  constructor(private _reportService: ReportService,
    private excelService:ExcelService) { }

  ngOnInit() {
    this.cargarDatos() 
  }

  cargarDatos() {

    this.loading = true;
    this.user = JSON.parse(localStorage.getItem('user')) as User;
    this._reportService.getOdometroGlobal(this.user.rut).subscribe(
      response => {
        console.log(response)
        const report = response as ReportOdometro[]
        this.lista = report.filter(r => r.serial).map(r => Object({
          patente: r.vin,
          gps: r.serial,
          odometro_actual: r.odometro_total,
          status: (r.diff <= 5 ), 
          fecha_ultimo_reporte: r.fechaEvento
        }) as TablaReportOdometroItem)


        this.total =this.lista.length
        this.table.setData(this.lista)

        this.loading = false;
      }, error => {
        this.loading = false
        console.error(error)
      }
    )
  }

  exportar() {

    
    let nombre = "Reporte_odometro-"+(new Date()).getTime();
    this.excelService.exportAsExcelFile(this.lista, nombre, true, 'Fecha de Generación: ' +this.fecha_creacion);
  }
}
