import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { MatPaginator, MatSort } from '@angular/material';
import { DataSmartReport } from '../formulario-smartreport/formulario-smartreport.component';
import { TableSmartreportDataSource } from './table-smartreport-datasource';

@Component({
  selector: 'app-table-smartreport',
  templateUrl: './table-smartreport.component.html',
  styleUrls: ['./table-smartreport.component.css']
})
export class TableSmartreportComponent implements OnInit {
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  @Output() emitEventEditRow:EventEmitter<DataSmartReport> = new EventEmitter<DataSmartReport>();
  @Output() emitEventDeletedRow:EventEmitter<DataSmartReport> = new EventEmitter<DataSmartReport>();
  dataSource: TableSmartreportDataSource;

  /** Columns displayed in the table. Columns IDs can be added, removed, or reordered. */
  /*
    operacion: string;
  rut_cliente: string;
  patente: string;
  descripcion: string;
  */
  displayedColumns = ['operacion', 'patente', 'descripcion', 'opciones'];

  ngOnInit() {
    this.dataSource = new TableSmartreportDataSource(this.paginator, this.sort, []);
  }

  ngRefreshData(data: DataSmartReport[]) {
    this.dataSource = new TableSmartreportDataSource(this.paginator, this.sort, data);
  }
 
  edit(data: DataSmartReport){

    this.emitEventEditRow.emit(data)
  }

  del(data: DataSmartReport) {

    this.emitEventDeletedRow.emit(data)
  }
}
