import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { MatPaginator, MatSort, throwMatDialogContentAlreadyAttachedError } from '@angular/material';
import { UserTercero } from 'src/app/model/user-tercero';
import { TableUsuarioTerceroDataSource } from './table-usuario-tercero-datasource';

@Component({
  selector: 'app-table-usuario-tercero',
  templateUrl: './table-usuario-tercero.component.html',
  styleUrls: ['./table-usuario-tercero.component.css']
})
export class TableUsuarioTerceroComponent implements OnInit {
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  @Output() emitEventEditRow:EventEmitter<UserTercero> = new EventEmitter<UserTercero>();
  @Output() emitEventEditPassRow:EventEmitter<UserTercero> = new EventEmitter<UserTercero>();
  @Output() emitEventActivaRow:EventEmitter<UserTercero> = new EventEmitter<UserTercero>();
  @Output() emitEventAddCarRow:EventEmitter<UserTercero> = new EventEmitter<UserTercero>();
  dataSource: TableUsuarioTerceroDataSource;

  /** Columns displayed in the table. Columns IDs can be added, removed, or reordered. */
  displayedColumns = ['email_tercero','nombre_tercero','telefono','activo','accion'];

  ngOnInit() {
    this.dataSource = new TableUsuarioTerceroDataSource(this.paginator, this.sort, []);
  }

  ngRefreshData(data: UserTercero[]) {
    this.dataSource = new TableUsuarioTerceroDataSource(this.paginator, this.sort, data);
  }

  onChangeStatus(status: boolean, user: UserTercero) {

    user.activo = (status) ? 1: 0
    this.emitEventActivaRow.emit(user)
  }

  edit(user: UserTercero){

    this.emitEventEditRow.emit(user)
  }

  changePass(user: UserTercero) {

    this.emitEventEditPassRow.emit(user)
  }

  addCar(user: UserTercero) {

    this.emitEventAddCarRow.emit(user)
  }
}
