import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MatSnackBar, MAT_DIALOG_DATA } from '@angular/material';
import { GpsMap } from 'src/app/model/gps-map';
import { UserTercero, UserTerceroVehiculo } from 'src/app/model/user-tercero';
import { MapService } from 'src/app/service/map.service';
import { UsuarioTerceroService } from 'src/app/service/usuario-tercero.service';

@Component({
  selector: 'app-formulario-vehiculo-tercero',
  templateUrl: './formulario-vehiculo-tercero.component.html',
  styleUrls: ['./formulario-vehiculo-tercero.component.css']
})
export class FormularioVehiculoTerceroComponent implements OnInit {

  
  usuario: UserTercero;

  selectall: boolean = false;

  vehicleList = [];
  vehiculosSeleccionados= [];

  selectedVehicles = [];
  loading = false;


  allComplete: boolean = false;


  constructor(
    public dialogRef: MatDialogRef<FormularioVehiculoTerceroComponent>,
    @Inject(MAT_DIALOG_DATA) public data: UserTercero,
    private _snackBar: MatSnackBar,
    private _mapService: MapService,
    private _usuarioTerceroService: UsuarioTerceroService
  ) {

  }

  ngOnInit() {

    this.loading = true;
    console.log(this.data)

    this._mapService.getAllLastPosition().subscribe(r => {

      this.vehicleList = r as Array<GpsMap>;
      this.usuario = this.data; 

      this.loading = true;
      this._usuarioTerceroService.getAllByClientTercero(this.usuario.id).subscribe(respose => {

        this.loading = false;
        this.vehiculosSeleccionados = respose as Array<UserTerceroVehiculo>

        this.vehicleList.forEach( g => {
          const gpsConf = this.vehiculosSeleccionados.filter(c => c.operacion == g.operacion);
          g.isChecked = gpsConf.length > 0 ? true : false;
          this.selectall = this.selectall && g.isChecked;
        })

      }, error => this.loading = false)
    }, error => this.loading = false)

    
    
  }

  selectVehiculo(checked:boolean, vehiculo: GpsMap){

    this.vehicleList.forEach(v => this.selectall = this.selectall && v.isChecked)

    if (checked) {

      var seleccionado = {
        id: 0,
        operacion: vehiculo.operacion,
        user_cliente_tercero_id: this.usuario.id,
        fecha: new Date()
      } as UserTerceroVehiculo
      this.loading = true
      this._usuarioTerceroService.createUserClienteTerceroOperacion(seleccionado).subscribe(response => {
        this._snackBar.open("Vehículo " + vehiculo.vin, 'Asociado')
        this.loading = false
        this.reloadListaSeleccionados()
      }, error => this.loading = false)
    } else {
      var listaFiltrada = this.vehiculosSeleccionados.filter( v => v.operacion == vehiculo.operacion)
      listaFiltrada.forEach( v => {

        this._usuarioTerceroService.deleteUserClienteTerceroOperacion(v.id).subscribe( r => {
          this._snackBar.open("Vehículo " + vehiculo.vin, 'Desasociado')
          this.reloadListaSeleccionados()
        })
      })
    }

  }

  reloadListaSeleccionados() {
    this.loading = true;
      this._usuarioTerceroService.getAllByClientTercero(this.usuario.id).subscribe(respose => {

        this.loading = false;
        this.vehiculosSeleccionados = respose as Array<UserTerceroVehiculo>

      }, error => this.loading = false)
  }

  /**
   * Función que permite seleccionar todo los vehiculos en el check de geozona
   */
  selectAllVehiculo(checked:boolean){

    this.vehicleList.forEach(v => v.isChecked = checked)

  }


  /**
   * Función que cierra el dialog.
   */
  cerrar() {
    this.dialogRef.close();
  }

  /**
   * Función que filtra los vehiculos asociados al cliente por la patente de estos.
   * @param event 
   */
  filterByVinAndAlias(event) {


    
    this.vehicleList = this._mapService.gpsList;
    const filterValue = event.target.value != null ? event.target.value.toLowerCase() : '';

    this.vehicleList = this.vehicleList.filter(v => v.vin.toLowerCase().includes(filterValue));
  }

  guardar(): void {
    this.dialogRef.close();
  }
}
