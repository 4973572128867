import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatSnackBar } from '@angular/material';
import { User } from 'src/app/model/user';
import { UserTercero } from 'src/app/model/user-tercero';
import { UsuarioTerceroService } from 'src/app/service/usuario-tercero.service';
import { FormularioUsuarioTerceroComponent } from './formulario-usuario-tercero/formulario-usuario-tercero.component';
import { TableUsuarioTerceroDataSource } from './table-usuario-tercero/table-usuario-tercero-datasource';
import { TableUsuarioTerceroComponent } from './table-usuario-tercero/table-usuario-tercero.component';
import swal from'sweetalert2';
import { FormularioVehiculoTerceroComponent } from './formulario-vehiculo-tercero/formulario-vehiculo-tercero.component';

@Component({
  selector: 'app-pantalla-admin-usuario-tercero',
  templateUrl: './pantalla-admin-usuario-tercero.component.html',
  styleUrls: ['./pantalla-admin-usuario-tercero.component.css']
})
export class PantallaAdminUsuarioTerceroComponent implements OnInit {

  public loading: Boolean = false;
  public user: User = {} as User;
  public lista: UserTercero[] = [];
  public filtro: string = ''; 

  
  @ViewChild('table') table: TableUsuarioTerceroComponent;

  constructor(public usuarioTerceroService: UsuarioTerceroService, public dialog: MatDialog, private _snackBar: MatSnackBar) { }

  ngOnInit() {

    this.load();
    this.subscripcion();
  }

  load() {

    this.loading = true;
    this.user = JSON.parse(localStorage.getItem('user')) as User
    this.filtro = ''

    this.usuarioTerceroService.getAllByClient(this.user.rut).subscribe( 
      r => {

        console.log(r)

        this.lista = r as UserTercero[]
        this.lista.forEach( row => {
          row.isactivo = (row.activo == 1)
        })
        this.table.ngRefreshData(this.lista)
        this.loading = false;
      }, error => {

        this.loading = false;
      }
    )
  }

  filtrar() {

    var listFilter = this.lista
    if (this.filtro.trim() != '') {

      listFilter = this.lista.filter( row => (
        row.email_tercero.toUpperCase().includes(this.filtro.toUpperCase())
        || row.nombre_tercero.toUpperCase().includes(this.filtro.toUpperCase())
        || row.telefono.toUpperCase().includes(this.filtro.toUpperCase())
      ))
    }

    this.table.ngRefreshData(listFilter)
  }

  subscripcion() {

    this.table.emitEventActivaRow.subscribe( row => {

      const usuario = row as UserTercero
      this.usuarioTerceroService.updateUserClienteTercero(usuario).subscribe( response => {

        this._snackBar.open("Usuario " + usuario.email_tercero, (usuario.activo) ? 'Activado': 'Desactivado');
      })
    })

    this.table.emitEventEditRow.subscribe( row => {

      const usuario = row as UserTercero
      const dialogRef = this.dialog.open(FormularioUsuarioTerceroComponent, {
        width: '550px',
        data: usuario,
      });
  
      dialogRef.afterClosed().subscribe(result => {

        if (result) {
  
          this.load();
        }
      });
    })

    this.table.emitEventEditPassRow.subscribe( row => {

      const usuario = row as UserTercero
      this.openChangePass(usuario)
    })

    this.table.emitEventAddCarRow.subscribe( row => {

      const usuario = row as UserTercero
      
      const dialogRef = this.dialog.open(FormularioVehiculoTerceroComponent, {
        width: '550px',
        data: usuario,
      });
  
      dialogRef.afterClosed().subscribe(result => {
        console.log('The dialog was closed');
        
      });
    })
  }

  openChangePass(usuario: UserTercero) {

    swal.mixin({
      input: 'password',
      confirmButtonText: 'Siguiente',
      cancelButtonText: 'Cancelar',
      showCancelButton: true,
      progressSteps: ['1','2'],
      backdrop: 'left center no-repeat rgba(0, 0, 0, 0.78)'
    }).queue([
      {
        title: 'Nueva Contraseña',
        text: 'Ingrese la nueva contraseña'
      },{
        title: 'Nueva Contraseña',
        text: 'Repetir la nueva contraseña'
      }
    ]).then((result) => {
      if (result.value) {

        var passNew1: string = result.value[0];
        var passNew2: string = result.value[1];


        if (passNew1 == passNew2) {


          usuario.pass = passNew1
              this.usuarioTerceroService.updateUserClienteTercero(usuario).subscribe( response => {

                swal.fire({
                  title: 'Listo!',
                  type: 'success',
                  backdrop: 'left center no-repeat rgba(0, 0, 0, 0.88)',
                  html: 'Las contraseñas fue actualizada.',
                  confirmButtonText: 'Gracias!'
                })
              });
              
        } else {

          swal.fire({
            title: 'Las contraseñas ingresadas no coinciden.',
            type: 'error',
            backdrop: 'left center no-repeat rgba(0, 0, 0, 0.88)',
            html: '',
            confirmButtonText: 'Ok'
          })
        }

        
      }
    
  })
  }

  openCrearUsuario() {

    const dialogRef = this.dialog.open(FormularioUsuarioTerceroComponent, {
      width: '550px',
      data: {
        rut_cliente: '',
        email_tercero: '',
        pass:'',
        nombre_tercero:'',
        telefono: '',
        activo: '1'
      },
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
      if (result) {

        this.load();
      }
    });
  }

}
