import { DataSource } from '@angular/cdk/collections';
import { MatPaginator, MatSort } from '@angular/material';
import { map } from 'rxjs/operators';
import { Observable, of as observableOf, merge } from 'rxjs';
import { Report } from 'src/app/model/report';

export class ReportListComponentDataSource extends DataSource<Report> {
  data: Report[] = [];

  constructor(private paginator: MatPaginator, private sort: MatSort, private rows: Report[]) {
    super();
    this.data = rows;
  }

  /**
   * Connect this data source to the table. The table will only update when
   * the returned stream emits new items.
   * @returns A stream of the items to be rendered.
   */
  connect(): Observable<Report[]> {
    // Combine everything that affects the rendered data into one update
    // stream for the data-table to consume.
    const dataMutations = [
      observableOf(this.data),
      this.paginator.page,
      this.sort.sortChange
    ];

    // Set the paginator's length
    this.paginator.length = this.data.length;

    return merge(...dataMutations).pipe(map(() => {
      return this.getPagedData(this.getSortedData([...this.data]));
    }));
  }

  /**
   *  Called when the table is being destroyed. Use this function, to clean up
   * any open connections or free any held resources that were set up during connect.
   */
  disconnect() {}

  /**
   * Paginate the data (client-side). If you're using server-side pagination,
   * this would be replaced by requesting the appropriate data from the server.
   */
  private getPagedData(data: Report[]) {
    const startIndex = this.paginator.pageIndex * this.paginator.pageSize;
    return data.splice(startIndex, this.paginator.pageSize);
  }

  /**
   * Sort the data (client-side). If you're using server-side sorting,
   * this would be replaced by requesting the appropriate data from the server.
   */
  private getSortedData(data: Report[]) {
    if (!this.sort.active || this.sort.direction === '') {
      return data;
    }

    return data.sort((a, b) => {
      const isAsc = this.sort.direction === 'asc';
      switch (this.sort.active) {
        case 'fechaGPS': return compare(new Date(a.fechaGPS).getTime(), new Date(b.fechaGPS).getTime(), isAsc);
        case 'fecha_evento': return compare(new Date(a.fecha_evento).getTime(), new Date(b.fecha_evento).getTime(), isAsc);
        case 'geo': return compare(+a.geo, +b.geo, isAsc);
        case 'distribucion': return compare(+a.count, +b.count, isAsc);
        case 'ignicion': return compare(+a.ignicion, +b.ignicion, isAsc);
        case 'movimiento': return compare(+a.movimiento, +b.movimiento, isAsc);
        case 'voltaje_externo': return compare(a.voltaje_externo, b.voltaje_externo, isAsc);
        case 'dias': return compare(+a.dias, +b.dias, isAsc);
        case 'horas': return compare(+a.horas, +b.horas, isAsc);
        case 'minutos': return compare(+a.minutos, +b.minutos, isAsc);
        default: return 0;
      }
    });
  }
}

/** Simple sort comparator for example ID/Name columns (for client-side sorting). */
function compare(a, b, isAsc) {
  return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
}
