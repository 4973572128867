import { Component, OnInit, ViewChild, ModuleWithComponentFactories } from '@angular/core';
import { MAT_MOMENT_DATE_FORMATS, MomentDateAdapter, MomentDateModule } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { DateTimeAdapter, OWL_DATE_TIME_FORMATS, OWL_DATE_TIME_LOCALE, OwlDateTimeIntl } from 'ng-pick-datetime';

import { MomentDateTimeAdapter } from 'ng-pick-datetime-moment';
import swal from 'sweetalert2';

import { ActivatedRoute } from '@angular/router';
import { ReportListComponentComponent } from 'src/app/component/report-list-component/report-list-component.component';
import { MapComponent } from 'src/app/component/map/map.component';
import { ReportService } from 'src/app/service/report.service';
import { Report } from 'src/app/model/report';
import { GpsMap } from '../../model/gps-map';
import {
  DatetimeAdapter,
  MAT_DATETIME_FORMATS,
  MAT_NATIVE_DATETIME_FORMATS,
  MatDatetimepickerFilterType,
  NativeDatetimeAdapter
} from "@mat-datetimepicker/core";

import * as _moment from 'moment';
import Swal from 'sweetalert2';
import { MapService } from '../../service/map.service';
import { User } from '../../model/user';
import { ReportOdometro } from 'src/app/model/reporte-odometro';
import * as CanvasJS from '../../../assets/js/canvasjs.min';


const moment = _moment;

export const MY_FORMATS = {
  parseInput: 'l LT',
  fullPickerInput: 'DD/MM/YYYY LT',
  datePickerInput: 'l',
  timePickerInput: 'LT',
  monthYearLabel: 'MMM YYYY',
  dateA11yLabel: 'LL',
  monthYearA11yLabel: 'MMMM YYYY'
};

@Component({
  selector: 'app-odometro-report',
  templateUrl: './odometro-report.component.html',
  styleUrls: ['./odometro-report.component.css'],
  providers: [
    { provide: MAT_DATE_LOCALE, useValue: 'es-CL' },
    { provide: DateTimeAdapter, useClass: MomentDateTimeAdapter, deps: [OWL_DATE_TIME_LOCALE] },
    { provide: OWL_DATE_TIME_FORMATS, useValue: MY_FORMATS }
  ],
})
export class OdometroReportComponent implements OnInit {

  public isRefresh: boolean = false;
  public operacion: string;
  public total: number;
  public ultima_actualizacion: string;

  public user: User;
  public indexRute: number;
  public isView: boolean = false;
  public dataList: Report[];

  public timeRuta;
  public playEnable = false;
  public stopEnable = false;
  public listado: ReportOdometro[];

  public textDireccionSelect: string;

  @ViewChild('table') table: ReportListComponentComponent;
  @ViewChild('map') map: MapComponent;

  constructor(private route: ActivatedRoute, private reportService: ReportService, private mapService: MapService, private adapter: DateAdapter<any>) { }

  ngOnInit() {

    this.operacion = this.route.snapshot.paramMap.get('operacion');
    this.total = 0;
    this.user = JSON.parse(localStorage.getItem('user')) as User;

    this.indexRute = 0;

    this.loadData()
  }

  loadData() {

    const rut = this.user.rut
    const operacion = this.operacion
    this.isRefresh = true

    this.reportService.getOdometroByOp(rut, operacion).subscribe(
      response => {

        this.listado = response as ReportOdometro[]

        if (this.listado.length > 0) {

          const primera = this.listado[0]
          this.total = primera.odometro_total
          this.ultima_actualizacion = primera.act_odometro
          this.ngOnInitChart(this.listado)
        }

        this.isRefresh = false

      }
    )

  }

  ngOnInitChart(lista: ReportOdometro[]) {

    let dataOdometro = [];
    let dataTime = [];

    lista.forEach(r => {

      var odometro = r.odometro

      var rowOdometro = { x: new Date(r.hasta.toString()), y: odometro, };

      dataOdometro.push(rowOdometro)


      var rowTime = { label: r.hasta.toString(), y: r.hasta.toString() };
      dataTime.push(rowTime)

    });
  }



}
