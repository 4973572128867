import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { EndPoindService } from './end-poind.service';

@Injectable({
  providedIn: 'root'
})
export class SessionService {

  BASE_URL: String = this.api.END_POINT;
  
  httpOptionsDefault = {
    headers: new HttpHeaders({
      'Content-Type':  'application/json'
    })
  };

  constructor(private http: HttpClient, public api: EndPoindService) { }

  login(rut: String, password: String) {

    return this.http.post(this.BASE_URL.toString().concat('/login'), { rut: rut, pass: password}, this.httpOptionsDefault);
  }

  loginTercero(rut: string, mail: string, password: string) {

    return this.http.post(this.BASE_URL.toString().concat('/logintercero'), { rut: rut, mail: mail, pass: password}, this.httpOptionsDefault);
  }

  logout(token: String) {

    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'authorization-x': token.toString()
      })
    };
    return this.http.delete(
        this.BASE_URL.toString().concat('/logout/').concat(token.toString()),
        httpOptions
      );
  }

  statusSession(token: String) {

    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'authorization-x': token.toString()
      })
    };
    return this.http.get(this.BASE_URL.toString().concat('/statusUser'), httpOptions);
  }

  recoveryPass(rut) {

    return this.http.post(this.BASE_URL.toString().concat('/recoveryPassByRut'), { rut: rut }, this.httpOptionsDefault);
  }

  recoveryPassGpsone(rut) {

    return this.http.post(this.BASE_URL.toString().concat('/gpsone/recoveryPassByRut'), { rut: rut }, this.httpOptionsDefault);
  }

  updatePass(passNew) {

    var token = localStorage.getItem('sess');
    var httpOptions = {};
    if (token != undefined && token != null) {
    
      httpOptions = {
        headers: new HttpHeaders({
          'content-type':  'application/json',
          'authorization-x': token.toString()
        })
      };
    }
    return this.http.put(this.BASE_URL.toString().concat('/updatePassCliente'), { clave: passNew }, httpOptions);
  }

  sendMsgAtencionClient(data) {

    var token = localStorage.getItem('sess');
    var httpOptions = {};
    if (token != undefined && token != null) {
    
      httpOptions = {
        headers: new HttpHeaders({
          'content-type':  'application/json',
          'authorization-x': token.toString()
        })
      };
    }
    return this.http.post(this.BASE_URL.toString().concat('/atencionClientMsg'), data, httpOptions);
  }

  sendMsgAtencionClienteGpsone(data) {

    var token = localStorage.getItem('sess');
    var httpOptions = {};
    if (token != undefined && token != null) {
    
      httpOptions = {
        headers: new HttpHeaders({
          'content-type':  'application/json',
          'authorization-x': token.toString()
        })
      };
    }
    return this.http.post(this.BASE_URL.toString().concat('/gpsone/atencionClientMsg'), data, httpOptions);
  }

  updateDatosUsuario(data) {

    var token = localStorage.getItem('sess');
    var httpOptions = {};
    if (token != undefined && token != null) {
    
      httpOptions = {
        headers: new HttpHeaders({
          'content-type':  'application/json',
          'authorization-x': token.toString()
        })
      };
    }
    return this.http.put(this.BASE_URL.toString().concat('/updateDataClient'), data, httpOptions);
  } 
}
