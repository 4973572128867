import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MatSnackBar, MAT_DIALOG_DATA } from '@angular/material';
import { User } from 'src/app/model/user';
import { UserTercero } from 'src/app/model/user-tercero';
import { UsuarioTerceroService } from 'src/app/service/usuario-tercero.service';

@Component({
  selector: 'app-formulario-usuario-tercero',
  templateUrl: './formulario-usuario-tercero.component.html',
  styleUrls: ['./formulario-usuario-tercero.component.css']
})
export class FormularioUsuarioTerceroComponent implements OnInit {

  usuario: UserTercero;
  user: User

  notificacion_activa_bool = false;
  close = false;

  public msg_error: string = ""
  public _error: boolean = false

  constructor( public dialogRef: MatDialogRef<FormularioUsuarioTerceroComponent>,
    @Inject(MAT_DIALOG_DATA) public data: UserTercero, private usuarioTerceroService: UsuarioTerceroService,
    private _snackBar: MatSnackBar) {

      this.usuario = data
  }

  ngOnInit(): void {
      this.load();
  }
  
  load() {

    this.user = JSON.parse(localStorage.getItem('user')) as User;
    
  }

  cerrarSave() {

    this.isError() 
    if (this._error || this.usuario.email_tercero.trim() == '' || this.usuario.nombre_tercero.trim() == '' || this.usuario.telefono.trim() == '') {

      return;
    }

    this.usuario.activo = 1;
    this.usuario.rut_cliente = this.user.rut;
    this.usuario.nombre_tercero = this.usuario.nombre_tercero.toUpperCase();
    this.usuario.email_tercero = this.usuario.email_tercero.toUpperCase();
    this.usuario.pass = 'gps.' + Math.round((new Date()).getTime()/ (Math.random() * ((99999999 - 999999100) + 999999100))) //Se auto genera 
    this.usuarioTerceroService.createUserClienteTercero(this.usuario).subscribe( r => {

      this.usuarioTerceroService.enviarCorreoUserClienteTercero({
        nombre_tercero: this.usuario.nombre_tercero,
        nom_cliente: this.user.nombre,
        url_login: location.origin,
        cod_cliente: this.usuario.rut_cliente.replace(".", "").replace(".", "").replace("-", ""),
        mail: this.usuario.email_tercero,
        pass: this.usuario.pass
      }).subscribe( r => console.log("Correo enviado"), error => console.error("Error al enviar el correo."))

      this._snackBar.open("Creación de usuario " + this.usuario.email_tercero, "OK");
      this.dialogRef.close(true)
    });

    this.close = true;
  }

  cerrarSaveUpdate() {

    this.isError() 
    if (this._error || this.usuario.email_tercero.trim() == '' || this.usuario.nombre_tercero.trim() == '' || this.usuario.telefono.trim() == '') {

      return;
    }

    this.usuario.nombre_tercero = this.usuario.nombre_tercero.toUpperCase();
    this.usuario.email_tercero = this.usuario.email_tercero.toUpperCase();
    this.usuarioTerceroService.updateUserClienteTercero(this.usuario).subscribe( r => {

      this._snackBar.open("Actualización del usuario " + this.usuario.email_tercero, "OK");
      this.dialogRef.close(true)
    });

    this.close = true;
  }

  isError() {

    this._error = false;
    this.msg_error = "";
  
    if (this.usuario.email_tercero != undefined && this.usuario.email_tercero != null && this.validarCorreo(this.usuario.email_tercero) == false) {

      this.msg_error = this.msg_error + " El Correo electrónico no es valido. Ejemplo: juanito@gmail.com "
      this._error = true;
    }

    if (this.usuario.nombre_tercero != undefined && this.usuario.nombre_tercero != null && this.usuario.nombre_tercero.trim() == '') {

      this.msg_error = this.msg_error + " El Nombre no es valido."
      this._error = true;
    }

    if ((this.usuario.telefono != undefined  && this.usuario.telefono != null && this.usuario.telefono.length != 9 ) || isNaN(Number.parseInt(this.usuario.telefono))) {

      this.msg_error = this.msg_error + " El teléfono celular tiene que ser de una linea móvil y debe ser de solo 9 dígitos. Ejemplo: 967899997."
      this._error = true;
    }

    return this.msg_error;
  }

  validarCorreo(email) {

    var emailRegex = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
    return emailRegex.test(email)
  }
 
}
