import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { EndPoindService } from './end-poind.service';
import { GpsMap } from '../model/gps-map';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class GoogleService {
 
  httpOptions = {};
  API_GOOGLE_KEY: string = "AIzaSyAKBogW4Hm5mJ9Bh9jmh355NdgtiamjFJ8"

  constructor(private http: HttpClient, public api: EndPoindService) {

      this.httpOptions = {
        headers: new HttpHeaders({
          'content-type':  'application/json'
        })
      };
  }

  obtenerTextoByCoordenada(latitud, longitud) {

    return this.http.get(`https://maps.googleapis.com/maps/api/geocode/json?sensor=false&key=${this.API_GOOGLE_KEY}&latlng=${latitud},${longitud}`, this.httpOptions);
  }
}
