import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './component/login/login.component';
import { HomeComponent } from './component/home/home.component';
import { MainHomeContentComponent } from './component/main-home-content/main-home-content.component';
import { MapComponent } from './component/map/map.component';
import { RutaReportComponent } from './component/ruta-report/ruta-report.component';
import { UltimasUbicacionesReportComponent } from './component/ultimas-ubicaciones-report/ultimas-ubicaciones-report.component';
import { DireccionesFrecuentesReportComponent } from './component/direcciones-frecuentes-report/direcciones-frecuentes-report.component';
import { DesconexiongGpsReportComponent } from './component/report/desconexiong-gps-report/desconexiong-gps-report.component';
import { ReportCrashEventComponent } from './component/crash-event/report-crash-event/report-crash-event.component';
import { DetailCrashEventComponent } from './component/crash-event/detail-crash-event/detail-crash-event.component';
import { ExcesoVelocidadesReportComponent } from './component/exceso-velocidades-report/exceso-velocidades-report.component';
import { NotificacionesComponent } from './component/notificaciones/notificaciones.component';
import { LoginGuard } from './guards/login.guard';
import { BotonPanicoReportComponent } from './component/boton-panico-report/boton-panico-report.component';
import { OdometroReportComponent } from './component/odometro-report/odometro-report.component';
import { PantallaReporteOdometroComponent } from './component/report/pantalla-reporte-odometro/pantalla-reporte-odometro.component';
import { PantallaAdminUsuarioTerceroComponent } from './component/pantalla-admin-usuario-tercero/pantalla-admin-usuario-tercero.component';
import { PantallaReporteKilometroRutaComponent } from './component/report/pantalla-reporte-kilometro-ruta/pantalla-reporte-kilometro-ruta.component';
import { PantallaAdminConductorComponent } from './component/pantalla-admin-conductores/pantalla-admin-conductor.component';
import { PantallaAdminSmartreportComponent } from './component/pantalla-admin-smartreport/pantalla-admin-smartreport.component';
import { PantallaReporteBateriaExternaComponent } from './component/report/pantalla-reporte-bateria-externa/pantalla-reporte-bateria-externa.component';
import { AlertaRemolqueReportComponent } from './component/report/alerta-remolque-report/alerta-remolque-report.component';

const routes: Routes = [
  {
    path:'',
    component: LoginComponent
  },
  {
    path:'login',
    component: LoginComponent
  },
  {
    path:'home',
    component: MainHomeContentComponent,
    canActivate: [LoginGuard]
  },
  {
    path:'reporte-rutas/:serial',
    component: RutaReportComponent,
    canActivate: [LoginGuard]
  },
  {
    path:'reporte-kilometraje/:serial/:operacion',
    component: PantallaReporteKilometroRutaComponent,
    canActivate: [LoginGuard]
  },
  {
    path:'reporte-rutas/:serial/:operacion',
    component: RutaReportComponent,
    canActivate: [LoginGuard]
  },{
    path:'reporte-bateria-externa/:serial/:operacion/:imeiid',
    component: PantallaReporteBateriaExternaComponent,
    canActivate: [LoginGuard]
  },{
    path:'reporte-odometro/:operacion',
    component: OdometroReportComponent,
    canActivate: [LoginGuard]
  },
  {
    path:'ultimas-ubicaciones/:serial',
    component: UltimasUbicacionesReportComponent,
    canActivate: [LoginGuard]
  },
  {
    path:'direcciones-frecuentes/:serial',
    component: DireccionesFrecuentesReportComponent,
    canActivate: [LoginGuard]
  },
  {
    path:'historial-desconexion/:imeiid',
    component: DesconexiongGpsReportComponent,
    canActivate: [LoginGuard]
  },{
    path:'historial-alerta-remolque/:imeiid',
    component: AlertaRemolqueReportComponent,
    canActivate: [LoginGuard]
  },{
    path:'boton-panico-report/:imeiid',
    component: BotonPanicoReportComponent,
    canActivate: [LoginGuard]
  },
  {
    path:'reporte-deteccion-choque/:imeiid',
    component: ReportCrashEventComponent,
    canActivate: [LoginGuard]
  },
  {
    path:'detalle-deteccion-choque/:json',
    component: DetailCrashEventComponent,
    canActivate: [LoginGuard]
  },
  {
    path:'reporte-exceso-velocidad/:serial',
    component: ExcesoVelocidadesReportComponent,
    canActivate: [LoginGuard]
  },
  {
    path:'mis-notificaciones',
    component: NotificacionesComponent,
    canActivate: [LoginGuard]
  },
  {
    path:'reporte-global-odometro',
    component: PantallaReporteOdometroComponent,
    canActivate: [LoginGuard]
  },
  {
    path:'admin-usuario-tercero',
    component: PantallaAdminUsuarioTerceroComponent,
    canActivate: [LoginGuard]
  },
  {
    path: 'admin-smartreport',
    component: PantallaAdminSmartreportComponent,
    canActivate: [LoginGuard]
  },
  {
    path:'admin-conductor',
    component: PantallaAdminConductorComponent,
    canActivate: [LoginGuard]
  }           
];


@NgModule({
  imports: [RouterModule.forRoot(routes, {
    scrollPositionRestoration: 'enabled'
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
