import { Component, OnInit, ViewChild, Output, EventEmitter } from '@angular/core';
import { MatPaginator, MatSort } from '@angular/material';
import { GpsListContentDataSource } from './gps-list-content-datasource';
import { GpsMap } from 'src/app/model/gps-map';
import { ExcelService } from 'src/app/service/excel.service';

@Component({
  selector: 'app-gps-list-content',
  templateUrl: './gps-list-content.component.html',
  styleUrls: ['./gps-list-content.component.css']
})
export class GpsListContentComponent implements OnInit {

  @Output() emitEventCenterGps: EventEmitter<GpsMap> = new EventEmitter<GpsMap>();
  @Output() emitSeeViewMap: EventEmitter<GpsMap> = new EventEmitter<GpsMap>();
  @Output() emitSeeViewAllMap: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() emitOpenManu: EventEmitter<GpsMap> = new EventEmitter<GpsMap>();

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  dataSource: GpsListContentDataSource;
  public hide: boolean = false;
  /** Columns displayed in the table. Columns IDs can be added, removed, or reordered. */
  displayedColumns = ['data', 'option'];

  constructor(public excelService: ExcelService) {

  }

  ngOnInit() {
    this.dataSource = new GpsListContentDataSource(this.paginator, this.sort, []);
  }

  public addData(datos: Array<GpsMap>) {

    this.dataSource = new GpsListContentDataSource(this.paginator, this.sort, datos);
  }

  public centerGps(dato: GpsMap) {

    this.emitEventCenterGps.emit(dato);
    dato.seeView = false;
    this.seeView(dato);
  }

  public seeView(dato: GpsMap) {

    dato.seeView = !dato.seeView;
    this.emitSeeViewMap.emit(dato);
  }

  public openMenu(dato: GpsMap) {

    localStorage.setItem('gpsSelector', JSON.stringify(dato));
    this.emitOpenManu.emit(dato);
  }

  public seeViewAll() {

    this.hide = !this.hide;
    this.emitSeeViewAllMap.emit(this.hide);
  }

  public exportarExcel() {

    const now = new Date();

    const datosExcel = this.dataSource.data.map( v => Object({

      "Operación": v.operacion,
      "RUT Cliente": v.rutCliente,
      "Cliente": v.nombreCliente,
      "Mail Cli": v['mail'],
      "Telefono Cli": v['telefono_cliente'],
      "Vehículo": v.vehiculo,
      "VIN - Patente": v.vin,
      "GPS": v.serial,
      "IMEI": v.imei,
      "Nº Tel GPS": v.telefono,
      "Model GPS": v.nombreModelo,
      "Última Coordenada": + v.longitud + "," + v.latitud,
      "Fecha Última Posición": v.fechaGPS,
      "Última Transmisión": v.fechaEvento,
      //"Última Dirección": v.geo,
      "Desconectado": (v.desconectado == 1) ? 'Si' : 'No'
    }))
    this.excelService.exportAsExcelFile(datosExcel, "Equipos_" + now.getTime(), false);
  }
}
