export class Conductor {
    conductores_id: number;
    nombre_completo: string;
    num_documento: string;
    ca_licencia_conducir: string;
    fecha_nacimiento: string;
    correo_electronico: string;
    telefono: string;
    empresa: string;
    cargo: string;
    horas_conduccion: number;
    evaluacion_comportamiento: number;
    activo: number;
    ultima_actualizacion: string;
    observacion: string;
    estado_conductor_id: number;
    op_vehiculo: string;
    cliente_key: string;
    kilometraje_recorrido: number;
    fecha_ingreso: string;
    edad: number;
    isactivo: boolean;
    nombre_estado: string;
    vin: string;
    comuna_id: number;
    direccion: string;
    licencia_file: string;
    nombre_file: string;
    vencimiento_licencia: string; 
    region_id: number;
    region_nombre: string;
    comuna_nombre: string;

  }