import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MatSnackBar, MAT_DIALOG_DATA } from '@angular/material';
import { Conductor } from 'src/app/model/conductor';
import { EstadoConductor } from 'src/app/model/estado-conductor';
import { User } from 'src/app/model/user';
import { ConductorService } from 'src/app/service/conductor.service';
import { EstadosConductorService } from 'src/app/service/estados-conductor.service';
import { ComunaService } from 'src/app/service/comuna.service';
import { RegionService } from 'src/app/service/region.service';
import { Comuna } from 'src/app/model/comuna';
import { Region } from 'src/app/model/region';
import swal from 'sweetalert2';
@Component({
  selector: 'app-formulario-conductor',
  templateUrl: './formulario-conductor.component.html',
  styleUrls: ['./formulario-conductor.component.css']
})
export class FormularioConductorComponent implements OnInit {

  public conductores_id: number;
  public nombre_completo: string;
  public num_documento: string;
  public ca_licencia_conducir: string;
  public fecha_nacimiento: Date;
  public fecha_vencimiento_licencia: Date;
  public correo_electronico: string;
  public telefono: string;
  public empresa: string;
  public cargo: string;
  public horas_conduccion: number;
  public evaluacion_comportamiento: number;
  public activo: number;
  public ultima_actualizacion: Date;
  public observacion: string;
  public estado_conductor_id: number;
  public op_vehiculo: string;
  public cliente_key: string;
  public kilometraje_recorrido: number;
  public fecha_ingreso: Date;
  public conductorOriginal: Conductor;
  public conductorActivo: number;
  public notificacion_activa_bool = false;
  public close = false;
  public user: User;
  public listaEstadosConductor: Array<EstadoConductor>;
  public listaRegiones: Array<Region>;
  public listaComunasBackend: Array<Comuna>;
  public listaComunasPorRegion: Array<Comuna>;
  public region_conductor: Region;
  public comuna_conductor: Comuna;
  public licencia_file: string;
  public nombre_file: string;
  public direccion_conductor: string;
  public comuna_id: number;
  public msg_error: string = ""
  public _error: boolean = false

  constructor( public dialogRef: MatDialogRef<FormularioConductorComponent>,
    @Inject(MAT_DIALOG_DATA) public data: Conductor, private conductorService: ConductorService, private estadosConductorService: EstadosConductorService,
    private _snackBar: MatSnackBar, public comunaService: ComunaService, public regionService: RegionService) {

      if(data != null){
        this.conductorOriginal = data;
        this.conductores_id = data.conductores_id;
        this.nombre_completo = data.nombre_completo;
        this.num_documento = data.num_documento;
        this.ca_licencia_conducir = data.ca_licencia_conducir;
        let fecha_nacimiento = new Date(data.fecha_nacimiento);
        this.fecha_nacimiento = new Date(fecha_nacimiento.getFullYear() + "-" + (fecha_nacimiento.getMonth()+1) + "-" + (fecha_nacimiento.getDate()));
        this.correo_electronico = data.correo_electronico;
        this.telefono = data.telefono;
        this.empresa = data.empresa;
        this.cargo = data.cargo;
        this.horas_conduccion = data.horas_conduccion;
        this.evaluacion_comportamiento = data.evaluacion_comportamiento;
        this.activo = data.activo;
        let fecha_ultima_actualizacion = new Date(data.ultima_actualizacion);
        this.ultima_actualizacion = new Date(fecha_ultima_actualizacion.getFullYear() + "-" + (fecha_ultima_actualizacion.getMonth()+1) + "-" + (fecha_ultima_actualizacion.getDate()));
        this.observacion = data.observacion;
        this.estado_conductor_id = data.estado_conductor_id;
        this.op_vehiculo = data.op_vehiculo;
        this.cliente_key = data.cliente_key;
        this.kilometraje_recorrido = data.kilometraje_recorrido;
        let fecha_ingreso = new Date(data.fecha_ingreso);
        this.fecha_ingreso = new Date(fecha_ingreso.getFullYear() + "-" + (fecha_ingreso.getMonth()+1) + "-" + (fecha_ingreso.getDate()));
        if(data.vencimiento_licencia){
          this.fecha_vencimiento_licencia = new Date(data.vencimiento_licencia)
        }
        if(data.licencia_file){
          this.licencia_file = data.licencia_file
        }
        if(data.nombre_file){
          this.nombre_file = data.nombre_file
        }

        this.comuna_id = data.comuna_id

        this.direccion_conductor = data.direccion
        
      }else{

        this.conductorOriginal = {} as Conductor;
        this.conductores_id = -1;
        this.nombre_completo = '';
        this.num_documento = '';''
        this.ca_licencia_conducir = '';
        this.fecha_nacimiento = null
        this.correo_electronico = '';
        this.telefono = '';
        this.empresa = '';
        this.cargo = '';
        this.horas_conduccion = 0;
        this.evaluacion_comportamiento = 0;
        this.activo = 1
        this.ultima_actualizacion = null;
        this.observacion = ''
        this.estado_conductor_id = -1
        this.op_vehiculo = '';
        this.cliente_key = ''
        this.kilometraje_recorrido = 0;
        let fecha_ingreso = new Date();
        this.fecha_ingreso = new Date(fecha_ingreso.getFullYear() + "-" + (fecha_ingreso.getMonth()+1) + "-" + (fecha_ingreso.getDate()));
        this.licencia_file = "";
        this.nombre_file = "N/A";
        this.fecha_vencimiento_licencia = null;
      }
      
  }

  ngOnInit(): void {
      this.load();

  }
  
  load() {

    this.user = JSON.parse(localStorage.getItem('user')) as User;
    this.estadosConductorService.getAll().subscribe(result => {
      this.listaEstadosConductor = result as Array<EstadoConductor>;
    })

    this.regionService.getAll().subscribe(result => {
      this.listaRegiones = result as Array<Region>;

      if(this.data != null && this.data.region_id != null){
        this.region_conductor = this.listaRegiones.filter(x => x.id_region == this.data.region_id)[0]
      }
      
      this.comunaService.getAll().subscribe(result => {
        this.listaComunasBackend = result as Array<Comuna>;
        this.listaComunasPorRegion = this.listaComunasBackend;
        if(this.data != null && this.data.comuna_id != null){
          this.comuna_conductor = this.listaComunasBackend.filter(x => x.id == this.data.comuna_id)[0]
        }
      })
    })
  }

  cerrarSave() {

    this.isError() 

    if (this._error) {

      return;
    }
    let crearConductor = {} as Conductor;
    crearConductor.num_documento = this.num_documento.trim().toUpperCase();
    crearConductor.nombre_completo = this.nombre_completo.trim().toUpperCase();
    crearConductor.ca_licencia_conducir = this.ca_licencia_conducir.trim().toUpperCase();
    crearConductor.correo_electronico = this.correo_electronico.trim().toUpperCase();
    crearConductor.telefono = this.telefono.trim();
    if(this.empresa == undefined || this.empresa == null || this.empresa == ''){
      this.empresa = '';
    }else{
      crearConductor.empresa = this.empresa.trim().toUpperCase();
    }
    
    if(this.cargo == undefined || this.cargo == null || this.cargo == ''){
      this.cargo = '';
    }else{
      crearConductor.cargo = this.cargo.trim().toUpperCase();
    }
    
    crearConductor.activo = 1
    crearConductor.estado_conductor_id = this.estado_conductor_id;

    crearConductor.fecha_nacimiento = this.fecha_nacimiento.toISOString().split("T")[0];
    if(this.observacion != null){
      crearConductor.observacion = this.observacion.trim().toUpperCase();
    }
    crearConductor.cliente_key = this.user.rut;
    crearConductor.estado_conductor_id = this.estado_conductor_id;
    crearConductor.fecha_ingreso = this.fecha_ingreso.toISOString().split("T")[0];
    crearConductor.evaluacion_comportamiento = 0;
    crearConductor.horas_conduccion = 0;
    crearConductor.kilometraje_recorrido = 0;
    if(this.licencia_file != null){
      if(this.licencia_file.trim() != ""){
        crearConductor.licencia_file = this.licencia_file;
      }
    } 
    if(this.nombre_file != null){
      if(this.nombre_file.trim() != ""){
        crearConductor.nombre_file = this.nombre_file
      }
    }
    if(this.fecha_vencimiento_licencia != null){
      crearConductor.vencimiento_licencia = this.fecha_vencimiento_licencia.toISOString().split("T")[0];
    }
    crearConductor.direccion = this.direccion_conductor.toUpperCase()
    crearConductor.comuna_id = this.comuna_conductor.id

    this.conductorService.getAll().subscribe( r => {
      const conductores = r as Array<Conductor>;

      if(conductores.filter(f => f.num_documento == crearConductor.num_documento)[0] != null){
        this._snackBar.open("El conductor con número de documento " + crearConductor.num_documento, " Ya existe");
        this.dialogRef.close(false)
        return;
      }else{
        this.conductorService.createConductor(crearConductor).subscribe( r => {

        this._snackBar.open("Conductor " + crearConductor.nombre_completo, " Creado");
        this.dialogRef.close(true)
        });
      }
    })
    this.close = true;
  }

  cerrarActualizar() {

    this.isError() 

    if (this._error) {

      return;
    }

    let actualizarConductor = {} as Conductor;
    if(this.num_documento.trim() != this.conductorOriginal.num_documento){
      actualizarConductor.num_documento = this.num_documento.trim()
    }
    if(this.nombre_completo.trim() != this.conductorOriginal.nombre_completo){
      actualizarConductor.nombre_completo = this.nombre_completo.trim()
    }
    if(this.ca_licencia_conducir.trim() != this.conductorOriginal.ca_licencia_conducir){
      actualizarConductor.ca_licencia_conducir = this.ca_licencia_conducir.trim()
    }
    
    if(this.fecha_nacimiento.toISOString().split("T")[0] != new Date(this.conductorOriginal.fecha_nacimiento).toISOString().split("T")[0]){
      actualizarConductor.fecha_nacimiento = this.fecha_nacimiento.toISOString().split("T")[0]
    }

    if(this.fecha_ingreso.toISOString().split("T")[0] != new Date(this.conductorOriginal.fecha_ingreso).toISOString().split("T")[0]){
      actualizarConductor.fecha_ingreso = this.fecha_ingreso.toISOString().split("T")[0]
    }

    if(this.correo_electronico.trim() != this.conductorOriginal.correo_electronico){
      actualizarConductor.correo_electronico = this.correo_electronico.trim()
    }
    if(this.telefono != this.conductorOriginal.telefono){
      actualizarConductor.telefono = this.telefono.trim()
    }
    if(this.empresa.trim() != this.conductorOriginal.empresa){
      actualizarConductor.empresa = this.empresa.trim()
    }
    if(this.cargo.trim() != this.conductorOriginal.cargo){
      actualizarConductor.cargo = this.cargo.trim()
    }
    if(this.observacion != this.conductorOriginal.observacion){
      actualizarConductor.observacion = this.observacion.trim()
    }
    if(this.estado_conductor_id != this.conductorOriginal.estado_conductor_id){
      actualizarConductor.estado_conductor_id = this.estado_conductor_id
    }
    if(this.fecha_vencimiento_licencia){
      if(this.fecha_vencimiento_licencia.toISOString().split("T")[0] != new Date(this.conductorOriginal.vencimiento_licencia).toISOString().split("T")[0]){
        actualizarConductor.vencimiento_licencia = this.fecha_vencimiento_licencia.toISOString().split("T")[0]
      }
    }

    if(this.licencia_file){
      if(this.licencia_file.trim() != ""){
        if(this.licencia_file != this.conductorOriginal.licencia_file){
          actualizarConductor.licencia_file = this.licencia_file
        }
      }
    }
    
    if(this.nombre_file){
      if(this.nombre_file.trim() != ""){
        if(this.nombre_file != this.conductorOriginal.nombre_file){
          actualizarConductor.nombre_file = this.nombre_file
        }
      }
    }
    
    if(this.direccion_conductor != this.conductorOriginal.direccion){
      actualizarConductor.direccion = this.direccion_conductor.toUpperCase()
    }
    if(this.comuna_conductor.id != this.conductorOriginal.comuna_id){
      actualizarConductor.comuna_id = this.comuna_conductor.id 
    }

    actualizarConductor.cliente_key = this.user.rut;
    actualizarConductor.conductores_id = this.conductorOriginal.conductores_id;
    
    this.conductorService.updateConductor(actualizarConductor).subscribe( r => {

      this._snackBar.open("Conductor " + this.conductorOriginal.nombre_completo, " Actualizado");
      this.dialogRef.close(true)
    });
   
    this.close = true;

  }

  isError() {

    this._error = false;
    this.msg_error = "";
  
    // Nro de documento
    if (this.num_documento == undefined || this.num_documento == null || this.num_documento.trim().length > 20) {

      this.msg_error = this.msg_error + " El número de documento es obligatorio. Revise si no excede los 20 caracteres."
      this._error = true;
    }

    // Nombre completo
    if (this.nombre_completo == undefined || this.nombre_completo == null || this.nombre_completo.trim().length > 100 || this.nombre_completo.trim() == '') {

      this.msg_error = this.msg_error + " El Nombre no es valido. No puede estar vacío ni superar los cien caracteres"
      this._error = true;
    }

    // CA Licencia de conducir
    if(this.ca_licencia_conducir != undefined || this.ca_licencia_conducir != null){
      if(this.ca_licencia_conducir.trim().length > 15){
        this.msg_error = this.msg_error + " El CA no puede exceder los quince caracteres"
        this._error = true;
      }
    }
     
    if(this.region_conductor == null || this.region_conductor.id_region == null || this.comuna_conductor == null || this.comuna_conductor.id == null || this.direccion_conductor == undefined || this.direccion_conductor == null || this.direccion_conductor.trim() == ""){
      this.msg_error = this.msg_error + " Los campos Región, Comuna y Dirección son obligatorios"
        this._error = true;
    }

    // Correo electrónico
    if(this.correo_electronico == undefined || this.correo_electronico == null || this.correo_electronico.length > 100 || this.validarCorreo(this.correo_electronico) == false){
      this.msg_error = this.msg_error + " El correo electrónico es obligatorio. Valide el formato (XXXXXX@XXXX.XXX) y que no supere los 100 caracteres"
      this._error = true;
    }
    
    // Teléfono
    if (this.telefono == undefined || this.telefono == null || (this.telefono.length < 7 || this.telefono.length > 20 ) || isNaN(Number.parseInt(this.telefono))) {

      this.msg_error = this.msg_error + " El teléfono celular tiene que ser de una linea móvil y debe ser menor a 20 dígitos. Ejemplo: 967899997."
      this._error = true;
    }

    // Fecha de nacimiento
    if (this.fecha_nacimiento == undefined || this.fecha_nacimiento == null) {

      this.msg_error = this.msg_error + " La fecha de nacimiento es obligatoria."
      this._error = true;
    }

    // Empresa
    if(this.empresa != undefined || this.empresa != null){
      if(this.empresa.length > 30){
        this.msg_error = this.msg_error + "  La empresa no puede exceder los treinta caracteres"
        this._error = true;
      }
    }

    // Cargo
    if(this.cargo != undefined || this.cargo != null){
      if(this.cargo.length > 30){
        this.msg_error = this.msg_error + " El cargo no puede exceder los treinta caracteres"
        this._error = true;
      }
    } 

    // Observación
    if(this.observacion != undefined && this.observacion != null){
      if(this.observacion.length > 200){
        this.msg_error = this.msg_error + " La observación no puede exceder los doscientos caracteres"
        this._error = true;
      }
    }

    if(this.estado_conductor_id == undefined || this.estado_conductor_id == null){
      this.msg_error = this.msg_error + " Debe seleccionar un estado para el conductor"
      this._error = true;      
    }

    return this.msg_error;
  }

  validarCorreo(email) {

    var emailRegex = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
    return emailRegex.test(email)
  }

  change_region(id_region){
    this.listaComunasPorRegion = this.listaComunasBackend.filter(x => x.regionId == id_region)
  }

  onLicenciaSeleccionada(event) {
    const file: File = event.target.files[0];
    if (file) {
      if (file.size > 2 * 1024 * 1024) {
        swal.fire({
          type: 'warning',
          title: 'Archivo excede el tamaño permitido.',
          text: 'El peso del archivo debe ser inferior a 2 MB.'
        });
        event.target.value = '';
      } else {
        this.nombre_file = file.name
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
          this.licencia_file = reader.result.toString(); 
        };
      }
    } else{
      this.nombre_file = ""
      this.licencia_file = ""
    }
  }
 
}
