import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { GpsMap } from 'src/app/model/gps-map';
import { MapService } from 'src/app/service/map.service';
import { SmartreportVehiculoService } from 'src/app/service/smartreport-vehiculo.service';

export interface DataSmartReport {
  operacion: string;
  rut_cliente: string;
  patente: string;
  descripcion: string;
}

export interface DataFormSmartReport {
  dataSmartReport: DataSmartReport;
  gpsList: GpsMap[];
  listSmartReport: DataSmartReport[];

}


@Component({
  selector: 'app-formulario-smartreport',
  templateUrl: './formulario-smartreport.component.html',
  styleUrls: ['./formulario-smartreport.component.css']
})
export class FormularioSmartreportComponent implements OnInit {

  public isLoad: boolean;
  public dataCreate = {} as DataSmartReport;
  public gpsSelect: GpsMap;

  
  constructor(
    public dialogRef: MatDialogRef<FormularioSmartreportComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DataFormSmartReport,
    private mapService: MapService,
    private smartreportVehiculoService: SmartreportVehiculoService,
  ) {

    this.isLoad = false
  }

  ngOnInit(): void {

    this.isLoad = true;
    const dataActivaMap = {}
    this.data.listSmartReport.forEach( sr => {

      dataActivaMap[sr.operacion] = true
    })
    this.mapService.getAllLastPosition().subscribe(response => {

      this.data.gpsList = response as GpsMap[]
      this.data.gpsList = this.data.gpsList.filter(row => !dataActivaMap[row.operacion])
      
      this.isLoad = false
    }, err => this.isLoad = false)
      
  }

  selecion() {

    console.dir(this.gpsSelect)
    if (this.gpsSelect) {

      this.dataCreate.patente = this.gpsSelect.vin
      this.dataCreate.operacion = this.gpsSelect.operacion
      this.dataCreate.rut_cliente = this.gpsSelect.rutCliente
      this.dataCreate.descripcion = this.gpsSelect.vehiculo
    } else {
      this.dataCreate = {} as DataSmartReport;
    }
  }

  guardar() {

    this.isLoad = true;
    this.smartreportVehiculoService.create(this.dataCreate).subscribe( response => {

      this.dialogRef.close(response);
      this.isLoad = false
    }, error => this.isLoad = false)
  }

  onNoClick(): void {
    this.dialogRef.close();
  }
}
