import { Component, OnInit, ViewChild, Output, EventEmitter  } from '@angular/core';
import { MatPaginator, MatSort } from '@angular/material';
import { ReportListComponentDataSource } from './report-list-component-datasource';
import { Report } from 'src/app/model/report';

@Component({
  selector: 'app-report-list-component',
  templateUrl: './report-list-component.component.html',
  styleUrls: ['./report-list-component.component.css']
})
export class ReportListComponentComponent implements OnInit {

  @Output() emitEventSelectedRow:EventEmitter<Report> = new EventEmitter<Report>();
  @Output() emitEventSelectedOptions:EventEmitter<Map<string, Report>> = new EventEmitter<Map<string, Report>>();
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  dataSource: ReportListComponentDataSource;

  /** fechaGPS: string;
    geo: string;
    latitud: string;
    longitud: string;
    velocidad: string; */
  displayedColumns = ['latitud', 'longitud', 'geo', 'fechaGPS', 'velocidad'];

  ngOnInit() {
    this.dataSource = new ReportListComponentDataSource(this.paginator, this.sort, []);
  }

  setData(data: Report[]) {

    this.dataSource = new ReportListComponentDataSource(this.paginator, this.sort, data);
  }

  setDisplayedColumns(columns: string[]) {

    this.displayedColumns = columns;
  }

  eventSelectedRow(row: Report) {

    this.emitEventSelectedRow.emit(row);
  }

  eventSelectedOption(row: Report, option: string) {

    let hash: Map<string, Report> = new Map();
    hash.set(option, row);
    this.emitEventSelectedOptions.emit(hash);
  }
}
