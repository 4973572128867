import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { Geozona } from 'src/app/model/geozona';

@Component({
  selector: 'app-form-delete-geozona',
  templateUrl: './form-delete-geozona.component.html',
  styleUrls: ['./form-delete-geozona.component.css']
})
export class FormDeleteGeozonaComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<FormDeleteGeozonaComponent>, @Inject(MAT_DIALOG_DATA) public data: Geozona) {}

  ngOnInit() {
  }

}
