import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { EndPoindService } from './end-poind.service';

@Injectable({
  providedIn: 'root'
})
export class UsuarioTerceroService {

  BASE_URL: String = this.api.END_POINT; 
  httpOptions = {};

  constructor(private http: HttpClient, public api: EndPoindService) {

    var token = localStorage.getItem('sess');
    if (token != undefined && token != null) {
    
      this.httpOptions = {
        headers: new HttpHeaders({
          'content-type':  'application/json',
          'authorization-x': token.toString()
        })
      };
    } 
  }

  getAllByClient(rut: string) {

    return this.http.post(this.BASE_URL.toString().concat('/userclientetercero/allbyrut'),{"rut" : rut}, this.httpOptions);
  }

  getAllByClientTercero(id: number) {

    return this.http.get(this.BASE_URL.toString().concat('/userclientetercerooperacion/all/').concat(id.toString()), this.httpOptions);
  }

  createUserClienteTercero(data) {

    return this.http.post(this.BASE_URL.toString().concat('/userclientetercero'), data, this.httpOptions);
  }

  enviarCorreoUserClienteTercero(data) {

    return this.http.post(this.BASE_URL.toString().concat('/mailuserclientetercero'), data, this.httpOptions);
  }

  createUserClienteTerceroOperacion(data) {

    return this.http.post(this.BASE_URL.toString().concat('/userclientetercerooperacion'), data, this.httpOptions);
  }
  
  updateUserClienteTercero(data) {

    return this.http.put(this.BASE_URL.toString().concat('/userclientetercero'), data, this.httpOptions);
  }

  deleteUserClienteTerceroOperacion(id: number) {

    return this.http.delete(this.BASE_URL.toString().concat('/userclientetercerooperacion/' + id.toString()), this.httpOptions);
  }

}
