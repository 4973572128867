import { Component, OnInit, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { MapService } from '../../../service/map.service';
import { ReportService } from '../../../service/report.service';
import { DateAdapter } from '@angular/material';
import { Report } from '../../../model/report';
import { ListDetailCrashEventComponent } from '../list-detail-crash-event/list-detail-crash-event.component';
import * as CanvasJS from '../../../../assets/js/canvasjs.min';
import { MapComponent } from '../../map/map.component';


@Component({
  selector: 'app-detail-crash-event',
  templateUrl: './detail-crash-event.component.html',
  styleUrls: ['./detail-crash-event.component.css']
})
export class DetailCrashEventComponent implements OnInit {

  jsonParam: string;
  idImei: string;
  databaseReport: Report;
  total: number;
  textoSinInfo: string;
  listDetalleReport: Array<Report>;
  
  breakpoint: number;
  colspanChart: number;

  public mapSize: string;

  totalDuracion: number;

  totalEjeY: number;
  totalEjeX: number;
  totalEjeZ: number;

  public lugarChoque: string;

  @ViewChild('table') table: ListDetailCrashEventComponent;
  @ViewChild('map') map: MapComponent;

  constructor(private router: Router, private route: ActivatedRoute, private mapService: MapService, private reportService: ReportService, private adapter: DateAdapter<any>) { }

  ngOnInit() {

    this.jsonParam = this.route.snapshot.paramMap.get('json');
    this.total= 0;
    this.mapSize = '100';
    this.textoSinInfo = '';
    this.databaseReport = JSON.parse(this.jsonParam) as Report;
    this.totalDuracion = this.databaseReport.duracion;
    this.idImei = this.databaseReport.id_gps;
    this.breakpoint = (window.innerWidth <= 400) ? 1 : 4;
    this.colspanChart = (window.innerWidth <= 400) ? 1 : 2;
    this.loadData();
  }

  onResize(event) {
    this.breakpoint = (event.target.innerWidth <= 400) ? 1 : 4;
    this.colspanChart = (event.target.innerWidth <= 400) ? 1 : 2;
  }

  loadData() {

    this.totalEjeY = 0;
    this.totalEjeX = 0;
    this.totalEjeZ = 0;

    this.reportService.getDetalleChoques(this.databaseReport).subscribe((response) => {

      this.listDetalleReport = response as Array<Report>;
      this.total = this.listDetalleReport.length;    
      this.table.setData(this.listDetalleReport);
      this.ngOnInitChart();
      this.calcularZonaChoque();
    });

   
  }

  ngOnInitChart() {

    let dataAxisY = [];
    let dataAxisX = [];
    let dataAxisZ = [];
    let dataSpeed = [];
    let dataTime = [];
    this.listDetalleReport.forEach( r => {

      var Y = Number.parseInt(r.axis_y.toString())
      var X = Number.parseInt(r.axis_x.toString()) 
      var Z = Number.parseInt(r.axis_z.toString())

      this.totalEjeY = Y;
      this.totalEjeX = X;
      this.totalEjeZ = Z; 

      var rowY =  {  label: "Eje Y", y: Y,};
      dataAxisY.push(rowY)

      var rowX = { label: "Eje X", y: X };
      dataAxisX.push(rowX)

      var rowZ = { label: "Eje Z", y: Z };
      dataAxisZ.push(rowZ)

      var rowSpeed = { label: "Velocidad",  y: Number.parseInt(r.speed.toString()) };
      dataSpeed.push(rowSpeed)

      var rowTime = { label: r.fecha_evento.toString().split(' ')[1],  y: r.fecha_evento.toString()};
      dataTime.push(rowTime)

      r.fechaEvento = r.fecha_evento;
      r.fechaGPS = r.fecha_evento;
      r.geo = this.databaseReport.geo;
      
    });

    var reporteMap  = this.listDetalleReport[0]; 
    this.mapService
    .getDireccionTextByLatLon(reporteMap.latitud, reporteMap.longitud)
    .subscribe( response => {

      reporteMap.geo = response['direccion'];
      this.map.clearPointReportMap();
      this.map.setPointReportMap(reporteMap, 'assets/img/crash.png', [50, 50]);
      this.map.centerMap(reporteMap.latitud, reporteMap.longitud, 19);
    }, err => console.log(err));
    
    this.mapSize = '100';

    //this.map.setLineByReports(this.listDetalleReport);

    let chart = new CanvasJS.Chart("chartContainer", {
      zoomEnabled: true,
      animationEnabled: true,
      exportEnabled: true,
      toolTip: {
        shared: true
      },
      title: {
        fontColor: "red", 
        text: ""
      },
      subtitles:[{
        text: ""
      }],
      axisY: {
        title: "Valores en mG",
        titleFontColor: "#4F81BC",
        labelFontColor: "#4F81BC"
      },
      axisY2: {
        title: "Velocidad (km/h)",
        titleFontColor: "#EA3556",
        labelFontColor: "#EA3556"
      },
      data: [{
        type: "line",   
        name: "Eje Y (DERECHA)",
        color: "#FFE44D",
		    showInLegend: true, 
        dataPoints: dataAxisY
      }
      ,{
        type: "line",
        name: "Eje X (ADELANTE)",
        color: "#B5E156",
		    showInLegend: true,                 
        dataPoints: dataAxisX
      },{
        type: "line", 
        name: "Eje Z (ABAJO)",
        color: "#61D2D6",
		    showInLegend: true, 
        dataPoints: dataAxisZ
      },{
        type: "line",    
        name: "Velocidad",         
        color: "#EA3556",
        showInLegend: true,
        axisYType: "secondary",
        dataPoints: dataSpeed
      }, {
        type: "line",
        name: "Tiempo",
		    showInLegend: false,
		    axisYType: "secondary",   
        dataPoints: dataTime
      }]
    });
      
    chart.render();
  }

  calcularZonaChoque() {

    let positovoDataAxisY = [];
    let positovoDataAxisX = [];
    let positovoDataAxisZ = [];

    let negativoDataAxisY = [];
    let negativoDataAxisX = [];
    let negativoDataAxisZ = [];

    this.listDetalleReport.forEach( r => {

      var Y = Number.parseInt(r.axis_y.toString())
      var X = Number.parseInt(r.axis_x.toString()) 
      var Z = Number.parseInt(r.axis_z.toString())

      if (Y >= 0) {

        positovoDataAxisY.push(Y);
      } else {

        negativoDataAxisY.push(Y);
      }

      if (X >= 0) {

        positovoDataAxisX.push(X);
      } else {

        negativoDataAxisX.push(X);
      }

      if (Z >= 0) {

        positovoDataAxisZ.push(Z);
      } else {

        negativoDataAxisZ.push(Z);
      }

    });

    var minY = Math.min(...negativoDataAxisY);
    var maxY = Math.max(...positovoDataAxisY);

    var minX = Math.min(...negativoDataAxisX);
    var maxX = Math.max(...positovoDataAxisX);

    var minZ = Math.min(...negativoDataAxisZ);
    var maxZ = Math.max(...positovoDataAxisZ);

    var minPico = {eje : "", value: 0};
    var maxPico = {eje : "", value: 0};
    
    if (minY <= minX && minY <= minZ) {

      minPico.eje = 'Y';
      minPico.value = minY;
    } else if (minX <= minY && minX <= minZ) {

      minPico.eje = 'X';
      minPico.value = minX;
    } else if (minZ <= minX && minZ <= minY) {

      minPico.eje = 'Z';
      minPico.value = minZ;
    }

    if (maxY >= maxX && maxY >= maxZ) {

      maxPico.eje = 'Y';
      maxPico.value = maxY;
    } else if (maxX >= maxY && maxX >= maxZ) {

      maxPico.eje = 'X';
      maxPico.value = maxX;
    } else if (maxZ >= maxX && maxZ >= maxY) {

      maxPico.eje = 'Z';
      maxPico.value = maxZ;
    }

    if (maxPico.value > (minPico.value * -1)) {

      switch (maxPico.eje) {
        case 'Y':
          this.lugarChoque = 'DERECHA';
          break;
        case 'X':
          this.lugarChoque = 'ADELANTE';
          break;
        case 'Z':
          this.lugarChoque = 'ABAJO';
          break;
      }

    } else {

      switch (minPico.eje) {
        case 'Y':
          this.lugarChoque = 'IZQUIERDA';
          break;
        case 'X':
          this.lugarChoque = 'ATRAS';
          break;
        case 'Z':
          this.lugarChoque = 'ARRIBA';
          break;
      }

    }
    
  }

  convertToCSV(objArray: any): string {
    var array = typeof objArray != 'object' ? JSON.parse(objArray) : objArray;
    var str = '';
    var row = "";

    for (var index in objArray[0]) {

      row += index + ';';
    }
    row = row.slice(0, -1);

    str += row + '\r\n';

    for (var i = 0; i < array.length; i++) {
      var line = '';
      for (var index in array[i]) {
        if (line != '') line += ';'

        line += array[i][index];
      }
      str += line + '\r\n';
    }
    return str;
  }

  downloadButtonPush() {

    var dataRows = [];
    this.listDetalleReport.forEach( detalle => dataRows.push({

      "Fecha Hora": detalle.fecha_evento,
      "Velocidad": detalle.speed,
      "Eje X": detalle.axis_x,
      "Eje Y": detalle.axis_y,
      "Eje Z": detalle.axis_z, 
      "Longitud": detalle.longitud,
      "Latitud": detalle.latitud
    }));

    var csvData = this.convertToCSV(dataRows);
    var blob = new Blob(["\ufeff", csvData], { type: 'text/csv' });
    var url = window.URL.createObjectURL(blob);

    var filename = "detalle-choque-gps-" + this.idImei + ".csv";

    if (navigator.msSaveOrOpenBlob) {

      navigator.msSaveBlob(blob, filename);
    } else {
      var a = document.createElement("a");
      a.href = url;
      a.download = filename;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    }
    window.URL.revokeObjectURL(url);
  }
}
