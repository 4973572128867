import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { MatPaginator, MatSort, throwMatDialogContentAlreadyAttachedError } from '@angular/material';
import { Conductor } from 'src/app/model/conductor';
import { TableConductorDataSource } from './table-conductor-datasource';

@Component({
  selector: 'app-table-conductor',
  templateUrl: './table-conductor.component.html',
  styleUrls: ['./table-conductor.component.css']
})
export class TableConductorComponent implements OnInit {
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  @Output() emitEventEditRow:EventEmitter<Conductor> = new EventEmitter<Conductor>();
  @Output() emitEventActivaRow:EventEmitter<Conductor> = new EventEmitter<Conductor>();
  @Output() emitEventAddCarRow:EventEmitter<Conductor> = new EventEmitter<Conductor>();
  dataSource: TableConductorDataSource;

  /** Columns displayed in the table. Columns IDs can be added, removed, or reordered. */
  displayedColumns = ['nombre_completo','correo_electronico','telefono','comuna','direccion','fecha_vencimiento_licencia', 'nombre_estado', 'vin','activo','accion'];

  ngOnInit() {
    this.dataSource = new TableConductorDataSource(this.paginator, this.sort, []);
  }

  ngRefreshData(data: Conductor[]) {
    this.dataSource = new TableConductorDataSource(this.paginator, this.sort, data);
  }

  public onChangeStatus(status: boolean, conductor: Conductor) {
    console.log(status);
    conductor.activo = (status) ? 1: 0
    this.emitEventActivaRow.emit(conductor)
    
  }

  edit(conductor: Conductor){

    this.emitEventEditRow.emit(conductor)
  }

  addCar(conductor: Conductor) {

    this.emitEventAddCarRow.emit(conductor)
  }

  openLink(conductor: Conductor) {
    const matches = conductor.licencia_file.match(/^data:([A-Za-z-+/]+);base64,(.+)$/);
    const contentType = matches[1];
    const binaryData = atob(matches[2]);
    const arrayBuffer = new ArrayBuffer(binaryData.length);
    const uint8Array = new Uint8Array(arrayBuffer);
    for (let i = 0; i < binaryData.length; i++) {
      uint8Array[i] = binaryData.charCodeAt(i);
    }
    const blob = new Blob([arrayBuffer], { type: contentType });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.download = conductor.nombre_file;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    URL.revokeObjectURL(url);
  }

}
