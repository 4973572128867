import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { EndPoindService } from './end-poind.service';
import { Cortacorriente } from '../model/cortacorriente';
import { Cortacorrientepass } from '../model/cortacorrientepass';

@Injectable({
  providedIn: 'root'
})
export class CortacorrienteService {

  BASE_URL: String = this.api.END_POINT; 
  httpOptions = {};

  constructor(private http: HttpClient, public api: EndPoindService) {

    var token = localStorage.getItem('sess');
    if (token != undefined && token != null) {
    
      this.httpOptions = {
        headers: new HttpHeaders({
          'content-type':  'application/json',
          'authorization-x': token.toString()
        })
      };
    } 
  }

  getByOperacion(operacion: string) {

    return this.http.get(this.BASE_URL.toString().concat('/cortacorriente/').concat(operacion), this.httpOptions);
  }

  getByRutIsPass(rut: string) {

    return this.http.get(this.BASE_URL.toString().concat('/cortacorriente/ispass/').concat(rut), this.httpOptions);
  }

  create(cortacorriente: Cortacorriente) {

    return this.http.post(this.BASE_URL.toString().concat('/cortacorriente'), cortacorriente, this.httpOptions);
  }

  createPass(cortacorriente: Cortacorrientepass) {

    return this.http.post(this.BASE_URL.toString().concat('/cortacorriente/pass'), cortacorriente, this.httpOptions);
  }

  flowCortaCorriente(cortacorriente: Cortacorriente) {

    return this.http.post(this.BASE_URL.toString().concat('/cortacorriente/flow'), cortacorriente, this.httpOptions);
  }

  update(cortacorriente: Cortacorriente) {

    return this.http.put(this.BASE_URL.toString().concat('/cortacorriente'), cortacorriente, this.httpOptions);
  }

  updatePass(cortacorriente: Cortacorrientepass) {

    return this.http.put(this.BASE_URL.toString().concat('/cortacorriente/pass'), cortacorriente, this.httpOptions);
  }
}
