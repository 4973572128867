import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { EndPoindService } from './end-poind.service';
import { Report } from '../model/report';

@Injectable({
  providedIn: 'root'
})
export class ReportService {

  
  BASE_URL: String = this.api.END_POINT; 
  httpOptions = {};

  constructor(private http: HttpClient, public api: EndPoindService) {

    var token = localStorage.getItem('sess');
    if (token != undefined && token != null) {
    
      this.httpOptions = {
        headers: new HttpHeaders({
          'content-type':  'application/json',
          'authorization-x': token.toString()
        })
      };
    } 
  }

  getRuta(serial, dataTimeInit, dataTimeEnd ) {

    return this.http.get(this.BASE_URL.toString().concat('/report/getRuta/').concat(serial).concat("/").concat(dataTimeInit).concat("/").concat(dataTimeEnd), this.httpOptions);
  }

  getEventoGpsVelocidad(serial, dataTimeInit, dataTimeEnd ) {

    return this.http.get(this.BASE_URL.toString().concat('/report/getEventoGpsVelocidad/').concat(serial).concat("/").concat(dataTimeInit).concat("/").concat(dataTimeEnd), this.httpOptions);
  }

  getExcesosVelocidades(serial, dataTimeInit, dataTimeEnd ) {

    return this.http.get(this.BASE_URL.toString().concat('/report/getExcesosVelocidades/').concat(serial).concat("/").concat(dataTimeInit).concat("/").concat(dataTimeEnd), this.httpOptions);
  }

  getExcesosVelocidadesTop(serial) {

    return this.http.get(this.BASE_URL.toString().concat('/report/getExcesosVelocidadesTop/').concat(serial), this.httpOptions);
  }

  getLastPositions(serial) {

    return this.http.get(this.BASE_URL.toString().concat('/report/getLastPositions/').concat(serial), this.httpOptions);
  }

  getLastFrequentAddresses(serial) {

    return this.http.get(this.BASE_URL.toString().concat('/report/getLastFrequentAddresses/').concat(serial), this.httpOptions);
  }

  getLastFrequentAddressesByDayDiff(serial, limite, dias) {

    return this.http.get(this.BASE_URL.toString().concat('/report/getLastFrequentAddressesByDayDiff/').concat(serial).concat("/").concat(limite).concat("/").concat(dias), this.httpOptions);
  }

  getLastFrequentAddressesByRango(serial, dataTimeInit, dataTimeEnd) {

    return this.http.get(this.BASE_URL.toString().concat('/report/getLastFrequentAddressesByRango/').concat(serial).concat("/").concat(dataTimeInit).concat("/").concat(dataTimeEnd), this.httpOptions);
  }

  getHistorialDeConexion(imeiId) {

    return this.http.get(this.BASE_URL.toString().concat('/report/getHistorialDeConexion/').concat(imeiId), this.httpOptions);
  }

  getAlertasRemolque(imeiId) {

    return this.http.get(this.BASE_URL.toString().concat('/report/getalertasremolque/').concat(imeiId), this.httpOptions);
  }

  getHistorialAlertasSos(imeiId) {

    return this.http.get(this.BASE_URL.toString().concat('/report/getalertassos/').concat(imeiId), this.httpOptions);
  }

  getHistorialChoques(imeiId) {

    return this.http.get(this.BASE_URL.toString().concat('/report/getcrashevents/').concat(imeiId), this.httpOptions);
  }

  getDetalleChoques(dataQuery: Report) {

    return this.http.post(this.BASE_URL.toString().concat('/report/getdetallecrashevents'), dataQuery, this.httpOptions);
  }

  getOdometroByOp(rut_client: string, operacion: string) {

    return this.http.get(this.BASE_URL.toString().concat(`/report/getodometrobyop/${rut_client}/${operacion}`), this.httpOptions);
  }

  getOdometroGlobal(rut_client: string) {

    return this.http.get(this.BASE_URL.toString().concat(`/report/getallodometro/${rut_client}`), this.httpOptions);
  }

  getByReportRutaPreprocesado(operacion: string, mes, year ) {

    return this.http.get(this.BASE_URL.toString().concat('/reportruta/getByReport/').concat(operacion).concat("/").concat(mes).concat("/").concat(year), this.httpOptions);
  }

  getListReportRutaDisponiblesByOp(operacion: string ) {

    return this.http.get(this.BASE_URL.toString().concat(`/reportruta/getListReportByOp/${operacion}`), this.httpOptions);
  }
  
}
