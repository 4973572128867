import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { EndPoindService } from './end-poind.service';
import { ContactoCliente } from '../model/contacto-cliente';


@Injectable({
  providedIn: 'root'
})
export class ContactoClienteService {

  BASE_URL: String = this.api.END_POINT; 
  httpOptions = {};

  constructor(private http: HttpClient, public api: EndPoindService) {

    var token = localStorage.getItem('sess');
    if (token != undefined && token != null) {
    
      this.httpOptions = {
        headers: new HttpHeaders({
          'content-type':  'application/json',
          'authorization-x': token.toString()
        })
      };
    } 
  }

  getByRut(rut: string) {

    return this.http.get(this.BASE_URL.toString().concat('/contact/').concat(rut), this.httpOptions);
  }

  create(data: ContactoCliente) {

    return this.http.post(this.BASE_URL.toString().concat('/contact'), data, this.httpOptions);
  }

  update(data: ContactoCliente) {

    return this.http.put(this.BASE_URL.toString().concat('/contact'), data, this.httpOptions);
  }

}
