import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatSnackBar } from '@angular/material';
import { ExcelService } from 'src/app/service/excel.service';
import { MapService } from 'src/app/service/map.service';
import { SmartreportVehiculoService } from 'src/app/service/smartreport-vehiculo.service';
import { DataSmartReport, FormularioSmartreportComponent } from './formulario-smartreport/formulario-smartreport.component';
import { TableSmartreportComponent } from './table-smartreport/table-smartreport.component';

@Component({
  selector: 'app-pantalla-admin-smartreport',
  templateUrl: './pantalla-admin-smartreport.component.html',
  styleUrls: ['./pantalla-admin-smartreport.component.css']
})
export class PantallaAdminSmartreportComponent implements OnInit {

  loading: boolean = false;
  public lista = [] as DataSmartReport[];
  @ViewChild ('table') table: TableSmartreportComponent;
  public filtro: string;

  constructor(public dialog: MatDialog,
    private mapService: MapService,
    private smartreportVehiculoService: SmartreportVehiculoService,
    private _snackBar: MatSnackBar,
    public _excelService: ExcelService) { }

  ngOnInit() {

    this.loadData()
    this.subscripcion() 

  }


  loadData() {

    this.loading = true;
    this.smartreportVehiculoService.getAll().subscribe(response => {

      this.lista = response as DataSmartReport[];
      this.table.ngRefreshData(this.lista)
      this.loading = false;
    }, error => this.loading = false)
  }

  subscripcion() {

    this.table.emitEventDeletedRow.subscribe( row => {

      const dataSmartReport = row as DataSmartReport;
      this.loading = true;
      this.smartreportVehiculoService.delete(dataSmartReport.operacion).subscribe(response => {

        this.loadData();
        this._snackBar.open("Operación " + dataSmartReport.operacion,  'Desactivada');
      }, error => this.loading = false);        

    })
  }
  

  agregarVehiculo() {

    const dialogRef = this.dialog.open(FormularioSmartreportComponent, {
      data: {data: {}, listSmartReport: this.lista,  gpsList: []},
      width: '400px',
    });

    dialogRef.afterClosed().subscribe(result => {

      if (result) {


        this.lista.push(result)
        this.table.ngRefreshData(this.lista)
        this.loadData();
      }
    });
  }

  filtrar() {

    var listFilter = this.lista
    if (this.filtro.trim() != '') {

      listFilter = this.lista.filter( row => (
        row.operacion.toUpperCase().includes(this.filtro.toUpperCase())
        || row.patente.toUpperCase().includes(this.filtro.toUpperCase())
        || row.descripcion.toUpperCase().includes(this.filtro.toUpperCase())
        || row.rut_cliente.toUpperCase().includes(this.filtro.toUpperCase())
      ))
    }

    this.table.ngRefreshData(listFilter)
  }

  downloadButtonPush() {
    let dataExport = this.table.dataSource.data.map(c => {
      return {
        'N° Operación': c.operacion,
        'Patente': c.patente,
        'Descrición': c.descripcion
      } 
    })
    this._excelService.exportAsExcelFile(dataExport, `smart-report-activos`,false);
  }
}
