import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { EndPoindService } from './end-poind.service';

@Injectable({
  providedIn: 'root'
})
export class SmartreportVehiculoService {

  
  BASE_URL: String = this.api.END_POINT; 
  httpOptions = {};

  constructor(private http: HttpClient, public api: EndPoindService) {

    var token = localStorage.getItem('sess');
    if (token != undefined && token != null) {
    
      this.httpOptions = {
        headers: new HttpHeaders({
          'content-type':  'application/json',
          'authorization-x': token.toString()
        })
      };
    } 
  }


  getAll() {

    return this.http.get(this.BASE_URL.toString().concat('/smartreportvehiculo'), this.httpOptions);
  }

  create(data) {

    return this.http.post(this.BASE_URL.toString().concat('/smartreportvehiculo'), data, this.httpOptions);
  }

  
  update(data) {

    return this.http.put(this.BASE_URL.toString().concat('/smartreportvehiculo'), data, this.httpOptions);
  }

  delete(op: string) {

    return this.http.delete(this.BASE_URL.toString().concat('/smartreportvehiculo/' + op), this.httpOptions);
  }

}
