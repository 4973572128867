import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator, MatSort } from '@angular/material';
import { TablaReportOdometroDataSource, TablaReportOdometroItem } from './tabla-report-odometro-datasource';

@Component({
  selector: 'app-tabla-report-odometro',
  templateUrl: './tabla-report-odometro.component.html',
  styleUrls: ['./tabla-report-odometro.component.css']
})
export class TablaReportOdometroComponent implements OnInit {

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  dataSource: TablaReportOdometroDataSource;

  /** Columns displayed in the table. Columns IDs can be added, removed, or reordered. */
  displayedColumns = ['patente', 'gps', 'odometro_actual', 'status', 'fecha_ultimo_reporte'];

  ngOnInit() {
    this.dataSource = new TablaReportOdometroDataSource(this.paginator, this.sort, []);
  }

  setData(data: TablaReportOdometroItem[]) {

    this.dataSource = new TablaReportOdometroDataSource(this.paginator, this.sort, data);
  }
}
