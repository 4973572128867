import {CdkVirtualScrollViewport} from '@angular/cdk/scrolling';
import {ChangeDetectionStrategy, Component, OnInit, ViewChild, ElementRef} from '@angular/core';
import { PushService } from '../../service/push.service';
import { Push } from '../../model/push';
import swal from'sweetalert2';

import Swal from 'sweetalert2';
import { MatDialog } from '@angular/material';
import { BlackListNotificacionesComponent } from './black-list-notificaciones/black-list-notificaciones.component';

@Component({
  selector: 'app-notificaciones',
  templateUrl: './notificaciones.component.html',
  styleUrls: ['./notificaciones.component.css'],
})
export class NotificacionesComponent implements OnInit {

  public listaActiva = true;
  public notificacionIcon = 'notifications_none';
  public totalNotificacionesPendientes;
  public loading = false;
  limitPush = 200;
  offSetPush = 0;
  primerId = -1;


  public listTipoPush: Push[] = [];
  public listTipoPushFilter: Push[] = [];
  public listPushExcluidas: Push[] = [];

  @ViewChild(CdkVirtualScrollViewport) scrollPush: CdkVirtualScrollViewport;

  notificaciones = [];

  constructor(public pushService: PushService, public dialog: MatDialog ) { }

  ngOnInit() {

    this.notificaciones = [] as Array<Push>;
    this.loadPush(this.limitPush, this.offSetPush);
    this.loadDataOption();
    this.runGetTotalPush();
  }

  loadDataOption() {

    this.pushService.getTipoPush().subscribe(response => {
      this.listTipoPush = response as Push[]
      this.listTipoPush.forEach(element => {
        element.selected = false
      });
      this.listTipoPush = this.listTipoPush.filter(t => t.tipo_push_id != 1)
    })

    this.pushService.getPushexclusion().subscribe(response => {

      if (response) {

        this.listPushExcluidas = response as Push[]

      } else {
        this.listPushExcluidas = []
      }
      this.loading = false;
    }, error => {
      console.error("getPushexclusion", error)
      this.loading = false
    
    })

  }

  loadPush(limit, offset) {

    this.loading = true;
    this.pushService.getAllPushOrder(limit, offset).subscribe( response => {

      var list = response as Array<Push>
      list = this.listTipoPushFilter.length > 0 ? list.filter(push => {

        return  this.listTipoPushFilter.filter(tipo => tipo.tipo_push_id == push.tipo_push_id).length > 0 
      }) : list;
      if (list.length > 0) {

        this.offSetPush = this.offSetPush + this.limitPush;
      }

      if (this.notificaciones.length == 0 && list.length > 0) {
        this.primerId = list[0].id;
      }
      this.notificaciones = this.notificaciones.concat(list); 
      this.listaActiva = true;
      this.loading = false;
    }, error => this.loading = false);
  }

  runGetTotalPush() {

    
    this.execTotalPush(this);
    setInterval( () => this.execTotalPush(this), 60000);
  }

  selectFilterOption(tipo_push) {

    this.listTipoPush.forEach(element => {
     

      if(element.tipo_push_id === tipo_push.tipo_push_id && element.selected == false) {
        element.selected = true;

      } else  if(element.tipo_push_id === tipo_push.tipo_push_id && element.selected == true) {
        element.selected = false;
      }

  
    });

    
    this.listTipoPushFilter = this.listTipoPush.filter(element => element.selected == true)

    this.limitPush = 200;
    this.offSetPush = 0;
    this.primerId = -1;
    this.notificaciones = []; 
    this.listaActiva = false;
    this.notificaciones = [] as Array<Push>;
    this.loadPush(this.limitPush, this.offSetPush);
  }

  execTotalPush(control) {


    this.loading = true;
    control.pushService.getTotalPendiente().subscribe(response => {
      var r = response as Array<any>;
      if (r.length > 0) {

        var nuevoTotal = r[0]['totalPendientes'];

        var cantidadNuevas = nuevoTotal - control.totalNotificacionesPendientes;

        if (cantidadNuevas > 0) {

          this.pushService.getAllPushOrder(cantidadNuevas, 0).subscribe( response => {

            var list = response as Array<Push>

            var listaNueva = [];

            list.filter( push => push.id > this.primerId).forEach(push => { 
              listaNueva.push(push);
            });

            if (listaNueva.length > 0) {
              this.primerId = listaNueva[0].id;
            }
            this.notificaciones = listaNueva.concat(this.notificaciones);
          });

        }

        control.totalNotificacionesPendientes = r[0]['totalPendientes'];
 
      } else {
        control.totalNotificacionesPendientes = 0;
      }
      this.loading = false;
    },error => this.loading = false);
  }

  checkNotificacion(push: Push) {

    push.visto = 1;
    this.pushService.updateCheckPush(push).subscribe(r => {

      this.execTotalPush(this);
    });
  }

  checkAllNotificacion() {

    swal.fire({
      title: '¿Está seguro que desea marcar toda como "leídas"?',
      text: "No podrás revertir esto!",
      type: 'question',
      showCancelButton: true,
      confirmButtonColor: '#00265b',
      cancelButtonColor: '#b32b4473',
      confirmButtonText: 'Si',
      cancelButtonText: 'No'
    }).then((result) => {
      if (result.value) {

        this.loading = true;
        this.pushService.updateCheckAllPush().subscribe(r => {

          this.execTotalPush(this);
          Swal.fire(
            'Su notificaciones ha sido marcadas como leídas!',
            '',
            'success'
          );

          this.notificaciones.forEach( n => n.visto = 1);

          this.execTotalPush(this);
          this.loading = false;
        }, error => this.loading = false);        
      }
    })
   
  }

  scrolledIndexChangeEvent($event) {

    const resto = this.notificaciones.length - $event;
    
    if (resto < 6 && this.offSetPush > 0) {

      this.loadPush(this.limitPush, this.offSetPush);
      
    }
  }

  getTiempoAtras(fecha){

    var date1 = new Date().getTime();
    var date2 = new Date(fecha).getTime();
    
    var diff = date2 - date1;
    
    var seconds = diff / 1000;
    var minutos = Math.floor((diff / 1000) / 60) * -1;
    var horas = Math.floor(minutos / 60) ;
    var dia = Math.trunc(horas / 24);

    if (dia > 9) {

      return fecha;
    } else if(dia > 0 && dia < 10) {

      return 'Hace '+dia+' días';
    } else if(horas > 0 && horas < 25) {

      return 'Hace '+horas+' horas';
    } else if(minutos > 0 && minutos < 60) {

      return 'Hace '+minutos+' minutos';
    } else {

      return 'Hace menos de un minuto ' + dia + ' - ' + horas;
    }
  }

  abrirNotificacionesDesactivadas() {

    const dialogRef = this.dialog.open(BlackListNotificacionesComponent, {
     
      width: '700px',
    });

    dialogRef.afterClosed().subscribe(result => {

      this.ngOnInit();
    });
  }

}
