import {
  Component,
  OnInit,
  ViewChild,
  ModuleWithComponentFactories,
} from "@angular/core";
import {
  MAT_MOMENT_DATE_FORMATS,
  MomentDateAdapter,
  MomentDateModule,
} from "@angular/material-moment-adapter";
import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE,
} from "@angular/material/core";
import { ActivatedRoute } from "@angular/router";
import { ReportListComponentComponent } from "src/app/component/report-list-component/report-list-component.component";
import { MapComponent } from "src/app/component/map/map.component";
import { ReportService } from "src/app/service/report.service";
import { Report } from "src/app/model/report";
import { GpsMap } from "../../../model/gps-map";
import Swal from "sweetalert2";
import { MapService } from "../../../service/map.service";
import { ExcelService } from "src/app/service/excel.service";

@Component({
  selector: "app-alerta-remolque-report",
  templateUrl: "./alerta-remolque-report.component.html",
  styleUrls: ["./alerta-remolque-report.component.css"],
})
export class AlertaRemolqueReportComponent implements OnInit {
  public isRefresh: boolean = false;
  public imeiId: string;
  public total: number;
  public dateInit: Date;
  public dateEnd: Date;

  public indexRute: number;
  public isView: boolean = false;
  public dataList: Report[];

  public textoSinInfo: string;

  @ViewChild("table") table: ReportListComponentComponent;
  @ViewChild("map") map: MapComponent;

  constructor(
    private route: ActivatedRoute,
    private mapService: MapService,
    private reportService: ReportService,
    private adapter: DateAdapter<any>,
    private excelService: ExcelService,
  ) {}

  ngOnInit() {
    this.imeiId = this.route.snapshot.paramMap.get("imeiid");
    this.total = 0;
    this.textoSinInfo = "";

    this.indexRute = 0;
    this.suscriber();
    var displayedColumns = ["fecha_evento", "geo", "ignicion"];
    this.table.setDisplayedColumns(displayedColumns as string[]);
    this.loadData();
  }

  loadData() {
    this.isRefresh = true;

    this.reportService.getAlertasRemolque(this.imeiId).subscribe(
      (response) => {
        this.dataList = response as Report[];

        this.dataList.forEach((d) => {
          // this.mapService.getDireccionTextByLatLon(d.latitud, d.longitud).subscribe( response => d.geo = response['direccion'], err => console.log(err));
          //this.mapService.getDireccionTextOpenStreetMapByLatLon(d.latitud, d.longitud).subscribe( response => d.geo = response['display_name'], err => console.log(err));
          d.fechaGPS = d.fechaEvento;

          if (d.desconectado == 1) {
            d.estadoConexion = "DESCONECTADO";
          } else {
            d.estadoConexion = "CONECTADO";
          }
        });

        this.total = this.dataList.length;

        this.isRefresh = false;
        this.table.setData(this.dataList);

        var displayedColumns = ["fecha_evento", "geo", "ignicion"];
        this.table.setDisplayedColumns(displayedColumns as string[]);
        //this.map.setLineByReports(this.dataList);
        this.indexRute = 0;
        this.nextView(true);
      },
      (error) => {
        Swal.fire({
          type: "warning",
          title: "No se puede obtener la información",
          text: "Intente nuevamente más tarde.",
        });
        this.isRefresh = false;
      }
    );
  }

  suscriber() {
    this.table.emitEventSelectedRow.subscribe((row) =>
      this.selectedRowEvent(row)
    );
  }

  nextView(isNext) {
    if (this.dataList != null && this.dataList.length > 0) {
      if (isNext) {
        if (this.dataList.length > this.indexRute) {
          this.indexRute = this.indexRute + 1;
          if (this.dataList.length == this.indexRute) {
            this.indexRute = 0;
          }
        } else {
          this.indexRute = 0;
        }
        this.selectedRowEvent(this.dataList[this.indexRute]);
      } else {
        if (this.indexRute == 0) {
          this.indexRute = this.dataList.length - 1;
        } else {
          this.indexRute = this.indexRute - 1;
        }
        this.selectedRowEvent(this.dataList[this.indexRute]);
      }
    }
  }

  selectedRowEvent(row: Report) {
    this.map.clearPointReportMap();
    this.map.setPointReportMap(row);
    this.map.centerMap(row.latitud, row.longitud);
  }

  downloadButtonPush() {

    var filename = "historial_alerta_remolque-gps-" + this.imeiId;
    
    const rows = this.dataList.map( d => {
      return {
        "Fecha Evento": d.fecha_evento,
        "Longitud": d.longitud,
        "Latitud": d.latitud,
        "Dirección": d.geo,
        "Link GoogleMap": "https://maps.google.com/?q=" + d.latitud + "," + d.longitud,
      }
    })

    this.excelService.exportAsExcelFile(rows,filename, false )
  }
}
