import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Router
} from '@angular/router';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LoginGuard implements CanActivate {
  
  constructor(
    private router: Router
  ) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
      // const user = JSON.parse(localStorage.getItem('sess'));
      const token = localStorage.getItem('sess');

      if (token != null) {
          return true;
      } else {
          this.router.navigate(['/login']);
          return false;
      }
  }
}
