import { Component, OnInit, ViewChild, ModuleWithComponentFactories } from '@angular/core';
import {MAT_MOMENT_DATE_FORMATS, MomentDateAdapter, MomentDateModule} from '@angular/material-moment-adapter';
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from '@angular/material/core';
import { ActivatedRoute } from '@angular/router';
import { ReportListComponentComponent } from 'src/app/component/report-list-component/report-list-component.component';
import { MapComponent } from 'src/app/component/map/map.component';
import { ReportService } from 'src/app/service/report.service';
import { Report } from 'src/app/model/report';
import { GpsMap } from '../../model/gps-map';
import Swal from 'sweetalert2';
import { MapService } from '../../service/map.service';

@Component({
  selector: 'app-ultimas-ubicaciones-report',
  templateUrl: './ultimas-ubicaciones-report.component.html',
  styleUrls: ['./ultimas-ubicaciones-report.component.css']
})
export class UltimasUbicacionesReportComponent implements OnInit {

  public isRefresh: boolean = false; 
  public serialGps: string;
  public total: number;
  public dateInit: Date;
  public dateEnd: Date;

  public indexRute: number;
  public isView: boolean = false;
  public dataList: Report[];


  @ViewChild('table') table: ReportListComponentComponent;
  @ViewChild('map') map: MapComponent;

  constructor(private route: ActivatedRoute, private reportService: ReportService, private mapService: MapService, private adapter: DateAdapter<any>) { }

  ngOnInit() {

    this.serialGps = this.route.snapshot.paramMap.get('serial');
    this.total= 0;

    this.indexRute = 0;
    this.suscriber();
    var displayedColumns = ['fechaGPS', 'dias', 'horas', 'minutos', 'geo'];
    this.table.setDisplayedColumns(displayedColumns as string[]);
  }

  loadData() {

      this.isRefresh = true;

      this.reportService.getLastPositions(this.serialGps).subscribe( response => {

        this.dataList = response as Report[];
        this.dataList.forEach( d => {

          let date = new Date(d.fechaGPS);
          let diff = Math.abs(new Date().getTime() - date.getTime());
          d.dias = Math.round( diff / (1000 * 3600 * 24));
          d.horas = Math.round( diff / 3600000 );
          d.minutos = Math.round( diff / 3600000 * 60 );

          if (d.geo == null || d.geo == "") {

            this.mapService.getDireccionTextByLatLon(d.latitud, d.longitud).subscribe( response => d.geo = response['direccion'], err => console.log(err));
            //this.mapService.getDireccionTextOpenStreetMapByLatLon(d.latitud, d.longitud).subscribe( response => d.geo = response['display_name'], err => console.log(err));
          }
        });
        this.isRefresh = false;
        this.table.setData(this.dataList);
        var displayedColumns = ['fechaGPS', 'dias', 'horas', 'minutos', 'geo'];
        this.table.setDisplayedColumns(displayedColumns as string[]);
        //this.map.setLineByReports(this.dataList);
        this.indexRute = 0;
        this.nextView(true);
      }, error => {

        Swal.fire({
          type: 'warning',
          title: 'No se puede obtener la información',
          text: 'Intente nuevamente más tarde.'
        });
        this.isRefresh = false;
      });

  }

  suscriber() {

    this.table.emitEventSelectedRow.subscribe( row => this.selectedRowEvent(row));
  }

  nextView(isNext) {

    if (this.dataList != null && this.dataList.length > 0) {

      if (isNext) {

        if(this.dataList.length > this.indexRute) {

          this.indexRute = this.indexRute + 1;
          if ( this.dataList.length == this.indexRute) {
            this.indexRute = 0;
          }
        } else {

          this.indexRute = 0;
        }
        this.selectedRowEvent(this.dataList[this.indexRute]);
      } else {

        if(this.indexRute == 0) {

          this.indexRute = this.dataList.length -  1;
          
        } else {

          this.indexRute = this.indexRute - 1;
        }
        this.selectedRowEvent(this.dataList[this.indexRute]);
      }
    }
  }

  selectedRowEvent(row: Report) {

    this.map.clearPointReportMap();
    this.map.setPointReportMap(row);
    this.map.centerMap(row.latitud, row.longitud);
  }
  convertToCSV(objArray: any): string {
    var array = typeof objArray != 'object' ? JSON.parse(objArray) : objArray;
    var str = '';
    var row = "";

    for (var index in objArray[0]) {

        row += index + ';';
    }
    row = row.slice(0, -1);

    str += row + '\r\n';

    for (var i = 0; i < array.length; i++) {
        var line = '';
        for (var index in array[i]) {
            if (line != '') line += ';'

            line += array[i][index];
        }
        str += line + '\r\n';
    }
    return str;
  }

  downloadButtonPush() {

    var csvData = this.convertToCSV(this.dataList);
    var blob = new Blob(["\ufeff",csvData], { type: 'text/csv' });
    var url = window.URL.createObjectURL(blob);
  
    var filename = "reporte-ultimas-ubicaciones-gps-"+this.serialGps+".csv";

    if(navigator.msSaveOrOpenBlob) {

      navigator.msSaveBlob(blob, filename);
    } else {
      var a = document.createElement("a");
      a.href = url;
      a.download = filename;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    }
    window.URL.revokeObjectURL(url);
  }
}
