import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { EndPoindService } from './end-poind.service';
import { LimiteVelocidadRequest } from '../model/limite-velocidad-request';

@Injectable({
  providedIn: 'root'
})
export class PushService {

  BASE_URL: String = this.api.END_POINT; 
  httpOptions = {};

  totalNotificacionesPendientes: number = 0;

  constructor(private http: HttpClient, public api: EndPoindService) {

    var token = localStorage.getItem('sess');
    if (token != undefined && token != null) {
    
      this.httpOptions = {
        headers: new HttpHeaders({
          'content-type':  'application/json',
          'authorization-x': token.toString()
        })
      };
    } 
  }

  getAllPush() {

    return this.http.get(this.BASE_URL.toString().concat('/push'), this.httpOptions);
  }

  getAllPushOrder(limit, offset) {

    return this.http.get(this.BASE_URL.toString().concat('/push/').concat(limit.toString()).concat('/').concat(offset.toString()), this.httpOptions);
  }

  getTotalPendiente() {

    return this.http.get(this.BASE_URL.toString().concat('/push/totalpendiente'), this.httpOptions);
  }

  updateCheckPush(push) {

    return this.http.put(this.BASE_URL.toString().concat('/push/check'), push, this.httpOptions);
  }

  updateCheckAllPush() {

    return this.http.put(this.BASE_URL.toString().concat('/push/checkall'), {}, this.httpOptions);
  }

  getTipoPush() {

    return this.http.get(this.BASE_URL.toString().concat('/tipopush'), this.httpOptions);
  }

  getPushexclusion() {

    return this.http.get(this.BASE_URL.toString().concat('/pushexclusion'), this.httpOptions);
  }

  createPushexclusion(data) {

    return this.http.post(this.BASE_URL.toString().concat('/pushexclusion'),data , this.httpOptions);
  }

  deletePushexclusion(id) {

    return this.http.delete(this.BASE_URL.toString().concat('/pushexclusion/'+id) , this.httpOptions);
  }
}
