import { Component, OnInit } from '@angular/core';

export interface TimeLineKilometraje {
  mes: string;
  dia: string;
  hora: string;
  fecha_evento: string;
  velocidad_promedio: number;
  count_prod: number;
  kilometraje: number;

  latitud: string;
  longitud: string;
  velocidad: string;
  isTotal: boolean;
}

@Component({
  selector: 'app-time-line-kilometraje',
  templateUrl: './time-line-kilometraje.component.html',
  styleUrls: ['./time-line-kilometraje.component.css']
})
export class TimeLineKilometrajeComponent implements OnInit {

  timeLine: TimeLineKilometraje[] = [];

  constructor() { }

  ngOnInit() {
  }

  setTimeLine(list: TimeLineKilometraje[]) {

    this.timeLine = list;
  }
}
