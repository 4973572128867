import { Component, AfterViewChecked, ElementRef, ViewChild } from '@angular/core';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Router, ActivatedRoute } from '@angular/router';
import { User } from 'src/app/model/user';
import { SessionService } from 'src/app/service/session.service';
import swal from'sweetalert2';
import {MatDialog} from '@angular/material/dialog';
import {MatBottomSheet, MatBottomSheetRef, MatDialogConfig} from '@angular/material';
import { ReportService } from '../../service/report.service';
import { ContactoCliente } from '../../model/contacto-cliente';
import { ContactoClienteService } from '../../service/contacto-cliente.service';
import { PushService } from '../../service/push.service';
import { Contrato } from 'src/app/model/contrato';
import { FinancieraService } from 'src/app/service/financiera.service';

@Component({
  selector: 'app-main-manu-content',
  templateUrl: './main-manu-content.component.html',
  styleUrls: ['./main-manu-content.component.css']
})
export class MainManuContentComponent {

  public isHome;
  public rutUsuario: string;
  public userNameComplet: String;
  public userName: String;
  public isloging = false;
  public isAdmin = false;
  public token: String;
  public scrollMainContent: String = '';
  public logo = "logo-TEIGPS-bco-300x63.png";
  public noEsMovil: boolean = true;
  public notificacionIcon = 'notifications_none';
  public totalNotificacionesPendientes = 0;
  public totalNotificacionesView = "";
  public reporteOdometroOn: boolean = false
  public user: User = {} as User;
  public viewTercero: boolean = false;
  public viewAdminSmartReport: boolean = false;

  @ViewChild('scrollMe') private myScrollContainer: ElementRef;

  isHandset$: Observable<boolean> = this.breakpointObserver.observe(Breakpoints.Handset)
    .pipe(
      map(result => result.matches)
    );

  constructor(private breakpointObserver: BreakpointObserver, 
    private route: ActivatedRoute, 
    private router: Router, 
    public sessionService: SessionService,
    public pushService: PushService, 
    private dialog: MatDialog, 
    private financieraService: FinancieraService,
    private _bottomSheet: MatBottomSheet) {}

  ngOnInit() {

    this.scrollMainContent = "";
    this.rutUsuario = "0";
    this.userName = "";
    this.userNameComplet = "";

    var hostname = window.location.hostname;
    if (hostname.indexOf('global') > -1 || hostname.indexOf('gps.grupoglobal.cl') > -1 ) {

      this.logo = "logo_global.svg";
    }

    if (hostname.indexOf('gpsone') > -1 || hostname.indexOf('127.0.0.1') > -1 ) {

      this.logo = "logo_gps-one2.png";
      
    }

    this.financieraService.getDataDominio(hostname).subscribe(
      response => {
        if (response['url_logo_menu'] != undefined ) {

          this.logo = response['url_logo_menu'] as string
        }
      }
    );

    if (this.isMovil()) {
      this.noEsMovil = false;
    }
     
    this.token = localStorage.getItem('sess');
    
    if (this.token != undefined && this.token != null && this.token != "" && this.token.length > 5) {

      this.validarPath();
      this.isloging = true;

      var usuario = JSON.parse(localStorage.getItem('user')) as User;
      this.userName = usuario.nombre;// + ' ' + usuario.apellido;
      this.rutUsuario = usuario.rut;
      this.userNameComplet = usuario.nombre;
      this.user = usuario;


      if (this.user.isTercero) {
        this.viewTercero = true;
      }

      if (this.user.on_smartreport) {

        this.viewAdminSmartReport = true;
      }

      var contratos = JSON.parse(localStorage.getItem('contratos')) as Contrato[];
      if (contratos != null && contratos[0] != null) {

        const listContratosOdometro = contratos.filter(c => c.odometroActivo == 1);

        if (listContratosOdometro.length > 0) {

          this.reporteOdometroOn = true;
        }
      }

      if (usuario.check_terminos == 0){
        setTimeout(()=>{
          this.openTerminoCondiciones()
        }, 2000)
        
      }
    }
    
    
    this.validateStatusSession();

    this.runGetTotalPush();
  }

  validarPath() {

    this.router.events.subscribe((call:any) => {
          
      var token = localStorage.getItem('sess');

      if(call.url == '/login' || call.url == '/' ) {

        this.isloging = false; 
        return false;
      }

      if (token == undefined || token == null || token == "" && call.url != '/login') {

        window.location.href = "login";
        return false;
      }
      

      if (call.url == undefined) {
        return false;
      }
      
      if (call.url == '/login' || call.url == '') {

        this.isHome = false;
      } else {

        this.isHome = true;
      }
      try {

        this.myScrollContainer.nativeElement.scrollTop = 0;//this.myScrollContainer.nativeElement.scrollHeight;
      } catch(err) {

      } 
      /**
       * Set Configuración del  Scroll 
       */
      if (call.url == '/home') {

        this.scrollMainContent = 'hidden';
        
      } else {

        this.scrollMainContent = 'auto';
      }

    });
  }

  runGetTotalPush() {

    var execPush = () => {

      const token = localStorage.getItem('sess');
      if (token != undefined && token != null) {
        this.pushService.getTotalPendiente().subscribe(response => {
          var r = response as Array<any>;
          if (r.length > 0) {

            this.totalNotificacionesPendientes = r[0]['totalPendientes'];
            if (this.totalNotificacionesPendientes > 99) {

              this.totalNotificacionesView = '+99'
            } else {
              this.totalNotificacionesView = r[0]['totalPendientes'];
            }

          } else {
            this.totalNotificacionesPendientes = 0;
            this.totalNotificacionesView = '';
          }
        });
      }
    }
    execPush();
    setInterval(execPush, 240000);
  }

  openChangePass() {

    swal.mixin({
      input: 'password',
      confirmButtonText: 'Siguiente',
      cancelButtonText: 'Cancelar',
      showCancelButton: true,
      progressSteps: ['1','2','3'],
      backdrop: 'left center no-repeat rgba(0, 0, 0, 0.78)'
    }).queue([
      {
        title: 'Contraseña Actual',
        text: 'Ingrese su contraseña actual'
      },{
        title: 'Nueva Contraseña',
        text: 'Ingrese la nueva contraseña'
      },{
        title: 'Nueva Contraseña',
        text: 'Repetir la nueva contraseña'
      }
    ]).then((result) => {
      if (result.value) {

        var passAntigual: string = result.value[0];
        var passNew1: string = result.value[1];
        var passNew2: string = result.value[2];


        if (passNew1 == passNew2 && passAntigual != '') {

          this.sessionService.login(this.rutUsuario, passAntigual).subscribe( response => {

            if (response['login'] == "OFF") {
    
              swal.fire({
                title: 'La contraseña antigua no es valida',
                type: 'error',
                backdrop: 'left center no-repeat rgba(0, 0, 0, 0.88)',
                confirmButtonText: 'Ok'
              });
            } else {

              this.sessionService.updatePass(passNew1).subscribe( response => {

                swal.fire({
                  title: 'Listo!',
                  type: 'success',
                  backdrop: 'left center no-repeat rgba(0, 0, 0, 0.88)',
                  html: 'Las contraseñas fue actualizada.',
                  confirmButtonText: 'Gracias!'
                })
              });
              
            }
          });
          
        } else {

          swal.fire({
            title: 'Las contraseñas ingresadas no coinciden.',
            type: 'error',
            backdrop: 'left center no-repeat rgba(0, 0, 0, 0.88)',
            html: '',
            confirmButtonText: 'Ok'
          })
        }

        
      }
    })
  }

  openClientSoport() {

    swal.fire({
      title: 'Atención al Cliente',
      html:  "<p>¿En qué te podemos ayudar? <br>Te contactamos a la brevedad:</p><textarea  style=' height:211px; width:440px;' id='textClient'></textarea>",
      showCancelButton: true,
      confirmButtonText: 'Enviar',
      cancelButtonText: 'Cancelar',
      backdrop: 'left center no-repeat rgba(0, 0, 0, 0.78)'
    }).then((result) => {

      if (result.value) {
       
        var textClient = document.getElementById('textClient')['value'];
        
        if (textClient != "") {

          var usuario = JSON.parse(localStorage.getItem('user')) as User;

          var hostname = window.location.hostname;

          if (hostname.indexOf('gpsone') > -1 || hostname.indexOf('127.0.0.1') > -1 ) {

            this.sessionService.sendMsgAtencionClienteGpsone({
              rut: usuario.rut,
              mail: usuario.mail,
              nombre: usuario.nombre,
              telefono: usuario.telefono,
              mensaje: textClient
            }).subscribe((response) => {

              swal.fire(
                'Enviado!',
                'El mensaje fue enviado.',
                'success'
              )
            });
          } else { 

            this.sessionService.sendMsgAtencionClient({
              rut: usuario.rut,
              mail: usuario.mail,
              nombre: usuario.nombre,
              telefono: usuario.telefono,
              mensaje: textClient
            }).subscribe((response) => {

              swal.fire(
                'Enviado!',
                'El mensaje fue enviado.',
                'success'
              )
            });
          }


        } else {

          swal.fire(
            'No Enviado',
            'El mensaje no fue enviado, por que no ingreso un texto.',
            'warning'
          )
        }      
      }
    });
  }


  openHelpWeb() {

      var hostname = window.location.hostname;



        swal.fire({
          imageUrl: 'assets/img/banner003.jpg',
          imageHeight: 150,
          imageWidth: 600,
          title: '<strong>Asistencia y Ayuda</strong>',
          html: '<h3>Material de apoyo</h3>'+
          '<center><table><tbody><tr><td>Manual aplicaci&oacute;n web</td><td>&nbsp;<a href="https://www.youtube.com/watch?v=zlbYXo-MiBE" target="_blank">Ver</a></td></tr>'+
          '<tr><td>Manual aplicaci&oacute;n m&oacute;vil</td><td>&nbsp;<a href="https://www.teigps.cl/wp-content/uploads/2019/03/tei_gps_manual_movil.pdf" target="_blank">Descargar</a></td></tr></tbody></table></center>'+
          '<h3>Descarga la App</h3><center><table><tbody><tr><td>&nbsp;<a href="https://apps.apple.com/cl/app/tei-gps/id1518676806?platform=iphone" target="_blank" class="app-link"><img  width="150" src="/assets/img/apple.png" alt="Consíguelo en el App Store" class="img-fluid"></a></td>'+
          '<td>&nbsp;<a href="https://play.google.com/store/apps/details?id=cl.teigps.appgps&hl=es_419&gl=US" target="_blank" class="play-link"><img width="150" src="/assets/img/play-store.png" alt="Disponible en Google Play" class="img-fluid"></a></td></tr></tbody></table></center>'+
          '<br>'+
          '<table><tbody><tr><td>&nbsp;<b>TEI GPS &copy; 2022</b><br>Todos los derechos reservados</td></tr><tr><td>&nbsp;Cerro Colorado 5240, Piso 5, Torres del Parque I, Las Condes, Santiago</td></tr>'+
          ' <tr><td>&nbsp;Servicio al cliente: 600 3600 181</td></tr></tbody></table>',
          showCancelButton: false,
          backdrop: 'left center no-repeat rgba(0, 0, 0, 0.78)',
          confirmButtonColor: '#3085d6',  
          confirmButtonText: 'Cerrar'
        }).then((result) => {
      
        })

      

  }

  openFromConfigUsuario() {

    const dialogRef = this.dialog.open(DialogConfiguracionUsuario);

    dialogRef.afterClosed().subscribe(result => {
      console.log(`Dialog result: ${result}`);
    });
  }

  openFromContactClient() {

    const dialogRef = this.dialog.open(DialogContact);

    dialogRef.afterClosed().subscribe(result => {
      console.log(`Dialog result: ${result}`);
    });
  }

  openTerminoCondiciones(){

      const dialogRef = this.dialog.open(DialogTerminosCondiciones);
  
      dialogRef.afterClosed().subscribe(result => {
        console.log(`Dialog result: ${result}`);
      });
  }

  openUsuarioTercero(){

    const dialogRef = this.dialog.open(DialogTerminosCondiciones);

    dialogRef.afterClosed().subscribe(result => {
      console.log(`Dialog result: ${result}`);
    });
  }
  
  logOut() {
  
        this.sessionService.logout(this.token).subscribe( response => {

          var flagInicio = localStorage.getItem('flagInicio');
          localStorage.clear();
          localStorage.setItem('flagInicio', flagInicio);
          window.location.href = "login";
        });
        //this.router.navigate(['/login'], { relativeTo: this.route });
  }

  validateStatusSession() {

    if (this.isloging == true) {

      this.sessionService.statusSession(this.token).subscribe( response => {

        if (response['is'] == false) {

          localStorage.clear();
          window.location.href = "login";
        }
      }, e => {

        window.location.href = "login";
      });

    }
  }

  isMovil = () => ( /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) ) ;
}


@Component({
  selector: 'dialog-contact',
  templateUrl: 'dialog-contact.html',
  styleUrls: ['dialog-contact.css']
})
export class DialogContact {

  listaContacto: Array<ContactoCliente>;
  usuario: User;

  close = false;


  constructor(private contactService: ContactoClienteService) {
    this.listaContacto = [] as Array<ContactoCliente>;
    this.load();
  }
  
  load() {
    this.usuario = JSON.parse(localStorage.getItem('user')) as User;
    this.contactService.getByRut(this.usuario.rut).subscribe( (response) => {
      this.listaContacto = response as Array<ContactoCliente>
    });
  }

  addContact() {
  
    if (this.listaContacto == undefined || this.listaContacto == null) {

      this.listaContacto = [] as  Array<ContactoCliente>;
    }

    if (this.listaContacto.length < 3) {

      var newContact = {} as ContactoCliente;
      this.listaContacto.push(newContact);
    }    
  }

  deleteContact(row: ContactoCliente) {

    if (row.id_contacto_cliente != undefined && row.id_contacto_cliente != null && row.id_contacto_cliente != 0) {
      row.activado = 0;
      this.contactService.update(row).subscribe( (response) => this.load());
    } else {

      this.load();
    }
  }

  cerrarSave() {

    var listaNewContacto: Array<ContactoCliente> = [];
    var listaUpdateContacto: Array<ContactoCliente> = [];

    if (this.listaContacto == undefined || this.listaContacto == null || this.listaContacto.length == 0) {

      return;
    }

    this.listaContacto
        .filter( (row) => (row.nombre_contacto != '' && row.telefono_contacto != ''))
        .forEach( (row) => {
          row.parentesco = (row.parentesco == undefined || row.parentesco == null || row.parentesco.trim() == "") ? 'N/A' : row.parentesco;
          if (row.id_contacto_cliente == null || row.id_contacto_cliente == undefined || row.id_contacto_cliente == 0 ) {

            row.rut_cliente = this.usuario.rut;
            row.activado= 1;
            this.contactService.create(row).subscribe(r => console.log(r));
          } else {

            this.contactService.update(row).subscribe(r => console.log(r));
          }
    });

    this.close = true;
  }

  isError() {

    var _error = false;
    var msg_error = "";

    if (this.listaContacto == undefined || this.listaContacto == null || this.listaContacto.length == 0) {

      return msg_error;
    }

    this.listaContacto
      .filter( (row) => (row.nombre_contacto != undefined && row.telefono_contacto != undefined)
                       && (row.nombre_contacto != null && row.telefono_contacto != null) 
                       && (row.nombre_contacto != '' && row.telefono_contacto != ''))
      .forEach( (row) => {

        if (row.nombre_contacto != undefined  && row.nombre_contacto != null && row.nombre_contacto.length < 3) {

          msg_error = msg_error + " No se permite hacer el ingreso de un nombre con menos de 3 caracteres."
          _error = true;
        }

        if ((row.telefono_contacto != undefined  && row.telefono_contacto != null && row.telefono_contacto.length != 9 ) || isNaN(Number.parseInt(row.telefono_contacto))) {

          msg_error = msg_error + " El numero del contacto tiene que se de un teléfono móvil y debe ser de solo 9 dígitos. Ejemplo: 967899997."
          _error = true;
        }
      });

      /*
      if (_error) {

        swal.fire({
          type: 'error',
          title: 'Oops...',
          html: msg_error
        })
      }*/

      return msg_error;
  }
}

@Component({
  selector: 'dialog-configuracion-usuario',
  templateUrl: 'dialog-configuracion-usuario.html',
  styleUrls: ['dialog-configuracion-usuario.css']
})
export class DialogConfiguracionUsuario {

  usuario: User;

  notificacion_activa_bool = false;
  close = false;

  constructor(private contactService: ContactoClienteService, private sessionService: SessionService) {

    this.load();
  }
  
  load() {

    this.usuario = JSON.parse(localStorage.getItem('user')) as User;
    this.notificacion_activa_bool = (this.usuario.notificacion_activa == 1) ? true : false;
  }

  cerrarSave() {

    this.usuario.notificacion_activa = (this.notificacion_activa_bool) ? 1 : 0;

    if (this.usuario.mail.trim() == '' || this.usuario.telefono.trim() == '') {

      return;
    }

    this.sessionService.updateDatosUsuario(this.usuario).subscribe( r => {

       localStorage.setItem('user', JSON.stringify(this.usuario));
    });

    this.close = true;
  }

  isError() {

    var _error = false;
    var msg_error = "";
  
    if (this.usuario.mail != undefined && this.usuario.mail != null && this.validarCorreo(this.usuario.mail) == false) {

      msg_error = msg_error + " El Correo electrónico no es valido. Ejemplo: juanito@gmail.com "
      _error = true;
    }

    if ((this.usuario.telefono != undefined  && this.usuario.telefono != null && this.usuario.telefono.length != 9 ) || isNaN(Number.parseInt(this.usuario.telefono))) {

      msg_error = msg_error + " El teléfono celular tiene que ser de una linea móvil y debe ser de solo 9 dígitos. Ejemplo: 967899997."
      _error = true;
    }

    return msg_error;
  }

  validarCorreo(email) {

    var emailRegex = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
    return emailRegex.test(email)
  }
 
}

@Component({
  selector: 'dialog-terminos-condiciones',
  templateUrl: 'dialog-terminos-condiciones.html',
  styleUrls: ['dialog-terminos-condiciones.css']
})
export class DialogTerminosCondiciones {

  usuario: User;

  check_terminos = false;
  close = false;

  constructor(private contactService: ContactoClienteService, private sessionService: SessionService) {

    this.load();
  }
  
  load() {

    this.usuario = JSON.parse(localStorage.getItem('user')) as User;
    this.check_terminos = (this.usuario.check_terminos == 0) ? true : false;
  }

  aceptarTermino(){

    this.usuario.check_terminos = 1;
    this.usuario.date_terminos = (new Date()).toISOString().slice(0, 19).replace('T', ' ');

    this.sessionService.updateDatosUsuario(this.usuario).subscribe( r => {

      localStorage.setItem('user', JSON.stringify(this.usuario));
    });

   this.close = true;
  }

}
