import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatSnackBar } from '@angular/material';
import { User } from 'src/app/model/user';
import { EstadosConductorService } from 'src/app/service/estados-conductor.service';
import { EstadoConductor } from 'src/app/model/estado-conductor';
import { ConductorService } from 'src/app/service/conductor.service';
import { Conductor } from 'src/app/model/conductor';
import { FormularioConductorComponent } from './formulario-conductor/formulario-conductor.component';
import { TableConductorComponent } from './table-conductor/table-conductor.component'; 
import { ExcelService } from 'src/app/service/excel.service';
import { DialogCambiarConductor} from '../main-home-content/main-home-content.component';
import { MapService } from 'src/app/service/map.service';
import { ComunaService } from 'src/app/service/comuna.service';
import { RegionService } from 'src/app/service/region.service';
import { Comuna } from 'src/app/model/comuna';
import { Region } from 'src/app/model/region';

@Component({
  selector: 'app-pantalla-admin-conductor',
  templateUrl: './pantalla-admin-conductor.component.html',
  styleUrls: ['./pantalla-admin-conductor.component.css']
})
export class PantallaAdminConductorComponent implements OnInit {

  public loading: Boolean = false;
  public user: User = {} as User;
  public lista: Conductor[] = [];
  public filtro: string = ''; 
  public listaEstadosConductor: Array<EstadoConductor>;
  public estado_conductor_id = -1
  public sinVehiculoAsignado: boolean = false;
  public region_filtro = -1;
  public listaComunas: Array<Comuna>
  public listaRegiones: Array<Region>
  public listaRegionesFiltro: Array<Region>
  @ViewChild('table') table: TableConductorComponent;

  constructor(public _estadosConductorService: EstadosConductorService, public _excelService: ExcelService, public conductorService: ConductorService, public dialog: MatDialog, private _snackBar: MatSnackBar, public mapService: MapService, private comunaService: ComunaService, private regionService: RegionService) { }

  ngOnInit() {

    this.load();
    this.subscripcion();
  }

  load() {

    this.loading = true;
    this.user = JSON.parse(localStorage.getItem('user')) as User
    this.filtro = ''

    this.conductorService.getAll().subscribe( 
      r => {

        this.lista = r as Conductor[]
        this.comunaService.getAll().subscribe(result => {
          this.listaComunas = result as Array<Comuna>
    
          this.regionService.getAll().subscribe(result => {
            this.listaRegiones = result as Array<Region>
            this.listaRegionesFiltro = this.listaRegiones
            
            const todasLasRegiones = { id_region: -1, nombre_region: "Todas las regiones"}
            this.listaRegionesFiltro.unshift(todasLasRegiones)
            this.table.ngRefreshData(this.lista)
            this.loading = false;
          })
        })

      }, error => {

        this.loading = false;
      }
    )

    this._estadosConductorService.getAll().subscribe(result => {
      const estadoConductor = {estado_conductor_id: -1,nombre_estado: "Todos", descripcion: "Todos"} as EstadoConductor;
      this.listaEstadosConductor = [];
      this.listaEstadosConductor.push(estadoConductor);
      this.listaEstadosConductor = this.listaEstadosConductor.concat(result as EstadoConductor[]);
    })

    
  }

  subscripcion() {

    this.table.emitEventActivaRow.subscribe( row => {

      const conductor = row as Conductor
      this.conductorService.updateConductor(conductor).subscribe( response => {
          this._snackBar.open("Usuario " + conductor.nombre_completo, (conductor.activo) ? 'Activado': 'Desactivado');
      })
    })         

    this.table.emitEventEditRow.subscribe( row => {

      const conductor = row as Conductor
      const dialogRef = this.dialog.open(FormularioConductorComponent, {
        width: '600px',
        data: conductor,
      });
  
      dialogRef.afterClosed().subscribe(result => {

        if (result) {
          this.limpiarFiltros()
          this.load();
          
        }
      });
    })

    this.table.emitEventAddCarRow.subscribe( row => {
      const dialogRef = this.dialog.open(DialogCambiarConductor, {
        data: {GpsMap: null, Conductor: row},
      });
      dialogRef.afterClosed().subscribe(result => {
        for (let index = 0; index < this.lista.length; index++) {
          if(this.lista[index].conductores_id == result.conductor_id){
              this.lista[index].vin = result.vin;
              this.lista[index].op_vehiculo = result.op_vehiculo;
              this.table.ngRefreshData(this.lista);
          }
        }
        for (let index = 0; index < this.mapService.gpsList.length; index++) {
          if(this.mapService.gpsList[index].operacion == result.operacion){
            if(result.conductores_id == 0){
              this.mapService.gpsList[index].conductores_id = null;
              this.mapService.gpsList[index].conductor_nombre_completo = null;
            }else{
              this.mapService.gpsList[index].conductores_id = result.conductores_id;
              this.mapService.gpsList[index].conductor_nombre_completo = result.nombre_completo;
            }
            
          }
        }
      })
    });
  }
  

  openCrearConductor() {

    const dialogRef = this.dialog.open(FormularioConductorComponent, {
      width: '600px',
      data: null,
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
      if (result) {
        this.limpiarFiltros()
        this.load();
        
      }
    });
  }

  downloadButtonPush() {

    let dataExport = this.table.dataSource.data.map(c => {
      return {
        'Nombre': c.nombre_completo,
        'N° documento': c.num_documento,
        'CA licencia': c.ca_licencia_conducir,
        'Fecha de nacimiento': c.fecha_nacimiento,
        'Edad': c.edad,
        'Email': c.correo_electronico,
        'Teléfono': c.telefono,
        'Dirección': (c.direccion != null) ? c.direccion : "N/A",
        'Comuna': (c.comuna_nombre != null) ? c.comuna_nombre.toUpperCase() : "N/A",
        'Región': (c.region_nombre != null) ? c.region_nombre.toUpperCase() : "N/A",
        'Fecha vencimiento licencia': (c.vencimiento_licencia != null) ? c.vencimiento_licencia : "N/A",
        'Empresa': c.empresa,
        'Cargo': c.cargo,
        'Estado': c.nombre_estado,
        'Observación': c.observacion,
        'Fecha de ingreso': c.fecha_ingreso,
        'Vehículo': (c.vin != null) ? c.vin : 'No asignado',
        'Cód. cliente': c.cliente_key,
        'Horas conducidas': c.horas_conduccion,
        'Km. recorrido': c.kilometraje_recorrido,
        'Evaluación': c.evaluacion_comportamiento,
        'Activo': (c.activo == 1) ? "Sí" : "No"
      } 
    })
    this._excelService.exportAsExcelFile(dataExport, `conductores`,false);
  }

  filtrar() {

    var listFilter = this.lista
    if (this.filtro.trim() != '') {

      listFilter = this.lista.filter( row => (
        row.correo_electronico.toUpperCase().includes(this.filtro.toUpperCase())
        || row.nombre_completo.toUpperCase().includes(this.filtro.toUpperCase())
        || row.telefono.toUpperCase().includes(this.filtro.toUpperCase())
        || row.op_vehiculo.toUpperCase().includes(this.filtro.toUpperCase())
        || row.num_documento.toUpperCase().includes(this.filtro.toUpperCase())
        || row.ca_licencia_conducir.toUpperCase().includes(this.filtro.toUpperCase())
        || row.empresa.toUpperCase().includes(this.filtro.toUpperCase())
        || row.cargo.toUpperCase().includes(this.filtro.toUpperCase())
        || row.direccion.toUpperCase().includes(this.filtro.toUpperCase())
        || row.comuna_nombre.toUpperCase().includes(this.filtro.toUpperCase())
      ))
    }
    if (this.estado_conductor_id != -1) {

      
      listFilter = listFilter.filter( row => (

        row.estado_conductor_id.toString().includes(this.estado_conductor_id.toString())
      ))
    }
    if (this.region_filtro != -1) {
      listFilter = listFilter.filter( row => row.region_id == this.region_filtro)
    }
    if (this.sinVehiculoAsignado == true) {
      let sinOp = "0"
      listFilter = listFilter.filter( row => (
        row.op_vehiculo.toString() == (sinOp)
      ))
    }
    this.table.ngRefreshData(listFilter)
  }

  limpiarFiltros(){
    this.filtro = ""
    this.estado_conductor_id = -1
    this.region_filtro = -1
    this.sinVehiculoAsignado = false
    this.table.ngRefreshData(this.lista)

  }
}

