import { Component, Inject, OnInit } from '@angular/core';
//import {ThemePalette} from '@angular/material/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { GeoConfiguracion, Geozona, TipoEvento } from 'src/app/model/geozona';
import { GeozonaService } from 'src/app/service/geozona.service';
import { MapService } from 'src/app/service/map.service';
import Swal from 'sweetalert2';
import { stringify } from '../../../../node_modules/wkt'
import { MatSnackBar } from '@angular/material';
import { GpsMap } from 'src/app/model/gps-map';
import {FormControl, Validators} from '@angular/forms';
import { User } from 'src/app/model/user';
import { Time } from '@angular/common';
import {OWL_DATE_TIME_FORMATS } from 'ng-pick-datetime';

export const MY_NATIVE_FORMATS = {
  fullPickerInput: {year: 'numeric', month: 'numeric', day: 'numeric', hour: 'numeric', minute: 'numeric', hour12: false},
  datePickerInput: {year: 'numeric', month: 'numeric', day: 'numeric'},
  timePickerInput: {hour: 'numeric', minute: 'numeric', hour12: false},
  monthYearLabel: {year: 'numeric', month: 'short'},
  dateA11yLabel: {year: 'numeric', month: 'long', day: 'numeric'},
  monthYearA11yLabel: {year: 'numeric', month: 'long'},
};

declare let L;

export interface Task {
  name: string;
  completed: boolean;
  dias?: Task[];
}

@Component({
  selector: 'app-form-geozona',
  templateUrl: './form-geozona.component.html',
  styleUrls: ['./form-geozona.component.css'],
  providers: [
    { provide: OWL_DATE_TIME_FORMATS, useValue: MY_NATIVE_FORMATS }
  ],
})
export class FormGeozonaComponent implements OnInit {

  usuario: User;

  selectall: boolean = true;

  eventIsChecked = false;
  vehicleList = [];

  nombreGeozona: string = '';
  descripcionGeozona: string = '';
  emailGeozona: string = '';

  selectedVehicles = [];
  loading = false;

  tipoEventoList: TipoEvento[] = [];
  tipoEventoSeleccionado: number;

  showMessaggeNombre = false;
  showMessaggeDescripcion = false;
  showMessaggeTipoEvento = false;
  showMessaggeEmail = false;
  showMessaggeVehiculo = false;
  showMessaggeDias = false;
  showMessaggeHora = false;  

  actionAdd = true;

  allComplete: boolean = false;

  emailFormControl = new FormControl('', [Validators.required, Validators.email]);

  seleccionDia: Task = {
    name: 'Seleccionar semana',
    completed: false,
    dias: [
      {name: 'Lunes', completed: false},
      {name: 'Martes', completed: false},
      {name: 'Miercoles', completed: false},
      {name: 'Jueves', completed: false},
      {name: 'Viernes', completed: false},
      {name: 'Sabado', completed: false},
      {name: 'Domingo', completed: false},
    ],
  };

  diasCompleto: boolean = false;

  horaInicio: Date;
  horaFin: Date;
  
  eventoHorarioCheck: boolean = false;
  
  constructor(
    public dialogRef: MatDialogRef<FormGeozonaComponent>,
    @Inject(MAT_DIALOG_DATA) public data: Geozona,
    private _snackBar: MatSnackBar,
    private _mapService: MapService,
    private _geozonaService: GeozonaService
  ) {

  }

  ngOnInit() {

    this.getTipoEventoGeozona();    

    this.vehicleList = JSON.parse(JSON.stringify(this._mapService.gpsList)) as Array<GpsMap>;

    console.log(this.data)

    this.horaInicio = new Date()
    this.horaFin = new Date()
    this.horaInicio.setHours(0)
    this.horaInicio.setMinutes(0)
    this.horaInicio.setSeconds(1)
    this.horaFin.setHours(23)
    this.horaFin.setMinutes(59)
    this.horaFin.setSeconds(59)


    if (this.data.action == 'editar') {
      this.setDataToEdit();
      this.vehicleList.forEach(g => {
        const gpsConf = this.data.configuracion.filter(c => c.imei == g.imei);
        g.isChecked = gpsConf.length > 0 ? true : false;
        this.selectall = this.selectall && g.isChecked;        
      });

      if(this.data.configuracion.length > 0){

        var conf = this.data.configuracion[0]
        this.eventoHorarioCheck = (conf.lunes==1 || conf.martes==1 || conf.miercoles==1 || conf.jueves==1 || conf.viernes==1 || conf.sabado== 1 || conf.domingo==1)        
        this.seleccionDia.dias[0].completed=(conf.lunes==1)
        this.seleccionDia.dias[1].completed=(conf.martes==1)
        this.seleccionDia.dias[2].completed=(conf.miercoles==1)
        this.seleccionDia.dias[3].completed=(conf.jueves==1)
        this.seleccionDia.dias[4].completed=(conf.viernes==1)
        this.seleccionDia.dias[5].completed=(conf.sabado==1)
        this.seleccionDia.dias[6].completed=(conf.domingo==1)

        this.horaInicio= new Date("2022-01-01 "+ conf.hora_inicio)
        this.horaFin= new Date("2022-01-01 "+ conf.hora_fin)

      }

    }

    this.usuario = JSON.parse(localStorage.getItem('user')) as User; 
    

    if (this.data != undefined && this.data.email == undefined || this.data.email == null) {

      this.emailGeozona = this.usuario.mail.toString();
      
    }
    
  }

  selectVehiculo(checked:boolean){

    this.vehicleList.forEach(v => this.selectall = this.selectall && v.isChecked)

  }

  /**
   * Función que permite seleccionar todo los vehiculos en el check de geozona
   */
  selectAllVehiculo(checked:boolean){

    this.vehicleList.forEach(v => v.isChecked = checked)

  }

  /**
   * Función que consulta los tipos de evento de geozona y rellena la propiedad tipoEventoList
   */
  getTipoEventoGeozona() {
    this._geozonaService.getTiposEventos().subscribe(data => {
      this.tipoEventoList = data as TipoEvento[];
      if (this.data.action == 'editar') {
        this.setDataToEdit()
      }
    });
  }

  /**
   * Función que agrega una geozona al mapa y lo registra en base de datos
   */
  addGeozona() {


    if(!this.eventIsChecked){

      this.emailGeozona = this.usuario.mail.toString();
      this.tipoEventoSeleccionado = 1;

    }
    
    if (this.validacionFormulario()) {
      this.loading = true;

      const userStorage = JSON.parse(localStorage.getItem('user'));
      const geozona: Geozona = {
        nombre: this.nombreGeozona,
        descripcion: this.descripcionGeozona,
        usuario_cod: userStorage.rut,
        figura: stringify(this.data),
        latitud: 0,
        longitud: 0,
        radio: 0,
        evento_activo: this.eventIsChecked ? 1 : 0,
        tipo_event_geo_id: this.tipoEventoSeleccionado,
        email: this.emailGeozona
      }

      this._geozonaService.createGeozona(geozona).subscribe(
        res => {

          this.selectedVehicles = this.vehicleList.filter(v => v.isChecked)
          console.log(this.selectedVehicles)
          const cantidad =  this.selectedVehicles.length  + 1
          this.selectedVehicles.forEach(v => {

            const configuracion: GeoConfiguracion = {
              imei: v.imei,
              operacion: v.operacion,
              rango_horario:  this.eventoHorarioCheck ? 1 : 0,
              hora_inicio: (this.horaInicio)?(this.horaInicio.getHours()) + ":" +(this.horaInicio.getMinutes()):'00:00',
              hora_fin: (this.horaFin)?(this.horaFin.getHours()) + ":" +(this.horaFin.getMinutes()):'23:59',
              geozonas_id: res['id'],
              lunes: (this.seleccionDia.dias[0].completed)? 1 : 0,      
              martes: (this.seleccionDia.dias[1].completed)? 1 : 0,   
              miercoles: (this.seleccionDia.dias[2].completed)? 1 : 0,   
              jueves: (this.seleccionDia.dias[3].completed)? 1 : 0,   
              viernes: (this.seleccionDia.dias[4].completed)? 1 : 0,  
              sabado: (this.seleccionDia.dias[5].completed)? 1 : 0,  
              domingo: (this.seleccionDia.dias[6].completed)? 1 : 0,  
            }

            this._geozonaService.createConfiguracionGeozona(configuracion).subscribe(res => {
              console.log('INSERT', res);
            });
          });

          this._snackBar.open('Creando la geozona', "CARGANDO", {
            duration: 2000,
          });

          const duracion = (cantidad * 1000) / 2.5

          console.log("duracion "+duracion)
          
          setTimeout(() => {

            this._snackBar.open('Creación exitosa', "Ok", {
              duration: 4000,
            });
            this.loading = false;
            this.dialogRef.close(geozona);
          }, duracion)   
        },
        err => {
          this.loading = false;
          Swal.fire('Error agregar', 'Hay un problema al intentar agregar la geozona', 'warning');
        }
      )
    }
  }

  editGeozona() {

    if(!this.eventIsChecked){

      this.emailGeozona = this.usuario.mail.toString();
      this.tipoEventoSeleccionado = 1;

    }

    if (this.validacionFormulario()) {

      this.loading = true;

      const geozona: Geozona = {
        geozonas_id: this.data.geozonas_id,
        nombre: this.nombreGeozona,
        descripcion: this.descripcionGeozona,
        usuario_cod: this.data.usuario_cod,
        figura: this.data.figura,
        latitud: this.data.latitud,
        longitud: this.data.longitud,
        radio: this.data.radio,
        evento_activo: this.eventIsChecked ? 1 : 0,
        tipo_event_geo_id: this.tipoEventoSeleccionado,
        email: this.emailGeozona

      }

      console.log('GEOZONA', geozona);

      this._geozonaService.editGeozona(geozona).subscribe(
        res => {
          this._geozonaService.deleteConfiguracionByIdGeozona(this.data.geozonas_id).subscribe(
            res => {
              let configList: GeoConfiguracion[] = [];
              this.vehicleList.filter(v => v.isChecked).forEach(v => {

                const configuracion: GeoConfiguracion = {
                  imei: v.imei,
                  operacion: v.operacion,
                  rango_horario: this.eventoHorarioCheck ? 1 : 0,
                  hora_inicio: (this.horaInicio)?(this.horaInicio.getHours()) + ":" +(this.horaInicio.getMinutes()):'00:00',
                  hora_fin: (this.horaFin)?(this.horaFin.getHours()) + ":" +(this.horaFin.getMinutes()):'23:59',
                  geozonas_id: geozona.geozonas_id,
                  lunes: (this.seleccionDia.dias[0].completed)? 1 : 0,      
                  martes: (this.seleccionDia.dias[1].completed)? 1 : 0,   
                  miercoles: (this.seleccionDia.dias[2].completed)? 1 : 0,   
                  jueves: (this.seleccionDia.dias[3].completed)? 1 : 0,   
                  viernes: (this.seleccionDia.dias[4].completed)? 1 : 0,  
                  sabado: (this.seleccionDia.dias[5].completed)? 1 : 0,  
                  domingo: (this.seleccionDia.dias[6].completed)? 1 : 0,  
                };
                configList.push(configuracion);
              });

              this._geozonaService.createListConfiguracionGeozona(configList).subscribe(
                res => {
                  console.log('Exito');
                },
                err => {
                  console.error();
                  ('ERROR');
                }
              );
            });

          this._snackBar.open('Modificación exitosa', "Ok", {
            duration: 4000,
          });
          this.loading = false;
          this.dialogRef.close(geozona);
        },
        err => {
          this.loading = false;
          Swal.fire('Error modificar', 'Hay un problema al intentar modificar la geozona', 'warning');
        }
      );
    }
  }

  /**
   * Función que cierra el dialog.
   */
  cerrar() {
    this.dialogRef.close();
  }

  /**
   * Función que limpia los campos del formularios.
   */
  cleanForm() {
    this.nombreGeozona = '';
    this.descripcionGeozona = '';
  }

  /**
   * Función que filtra los vehiculos asociados al cliente por la patente de estos.
   * @param event 
   */
  filterByVinAndAlias(event) {


    
    this.vehicleList = this._mapService.gpsList;
    const filterValue = event.target.value != null ? event.target.value.toLowerCase() : '';

    this.vehicleList = this.vehicleList.filter(v => v.vin.toLowerCase().includes(filterValue));
  }

  /**
   * Función que valida los campos del formulario y muestra los mensajes de error asociados a estos.
   * @returns boolean
   */
  validacionFormulario() {

    const nombre = (this.nombreGeozona != null && this.nombreGeozona != undefined && this.nombreGeozona.length <= 30 && this.nombreGeozona != '') ? true : false;
    const descripcion = (this.descripcionGeozona != null && this.descripcionGeozona != undefined && this.descripcionGeozona.length <= 80 && this.descripcionGeozona != '') ? true : false;
    const tipoEvento = (this.tipoEventoSeleccionado != null && this.tipoEventoSeleccionado != undefined) ? true : false;
    //const email = (this.emailGeozona != null && this.emailGeozona != undefined && this.emailGeozona != '' && this.validarCorreo(this.emailGeozona) == true );
    var email = (this.emailGeozona != null && this.emailGeozona != undefined && this.emailGeozona != '');

    if (email) {

      var listMail = this.emailGeozona.split(";");
      listMail.map(m => this.validarCorreo(m)).forEach(isValido => {
        if (isValido != true) {

          email = false;
        }
      });

    }
    
    var statusSeleccionVehiculo = true;

    this.showMessaggeNombre = !nombre;
    this.showMessaggeDescripcion = !descripcion;
    this.showMessaggeTipoEvento = !tipoEvento;
    this.showMessaggeEmail = !email;
    this.showMessaggeDias = false;
    this.showMessaggeVehiculo = false; 
    this.showMessaggeHora = false; 

    

    if(this.eventIsChecked){

      statusSeleccionVehiculo = (this.vehicleList.filter(v => v.isChecked).length > 0)

      this.showMessaggeVehiculo = !statusSeleccionVehiculo

    }
    
    if(this.eventoHorarioCheck){

      if(this.horaInicio == undefined || this.horaInicio == null || this.horaFin == undefined || this.horaFin == null /*|| (this.horaInicio.getTime() > this.horaFin.getTime())*/){

        this.showMessaggeHora = true;
        
      }
      
      
      return nombre && descripcion && tipoEvento && email && !(this.showMessaggeHora) && statusSeleccionVehiculo;  

    }

    return nombre && descripcion && tipoEvento && email && statusSeleccionVehiculo;  

  }

  setDataToEdit() {

    this.nombreGeozona = this.data.nombre;
    this.descripcionGeozona = this.data.descripcion;
    this.tipoEventoSeleccionado = this.data.tipo_evento.idtipo_event_geo_id;
    this.emailGeozona = this.data.email;

    if (this.data.evento_activo == 1) {

      this.eventIsChecked = true;

    } else {

      this.eventIsChecked = false;
    }
    
  }

  validarCorreo(email) {

    var emailRegex = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
    return emailRegex.test(email)
  }

  actualizarSeleccionCompleta() {
    this.diasCompleto = this.seleccionDia.dias != null && this.seleccionDia.dias.every(s => s.completed);
  }

  incompleto(): boolean {
    if (this.seleccionDia.dias == null) {
      return false;
    }
    return this.seleccionDia.dias.filter(s => s.completed).length > 0 && !this.diasCompleto;
  }

  seleccionarTodo(completed: boolean) {
    this.diasCompleto = completed;
    if (this.seleccionDia.dias == null) {
      return;
    }
    this.seleccionDia.dias.forEach(s => (s.completed = completed));
  }

  

}
