import { Component, OnInit, ViewChild, Output, EventEmitter  } from '@angular/core';
import { MatPaginator, MatSort } from '@angular/material';
import { ListDetailCrashEventDataSource } from './list-detail-crash-event-datasource';
import { Report } from '../../../model/report';

@Component({
  selector: 'app-list-detail-crash-event',
  templateUrl: './list-detail-crash-event.component.html',
  styleUrls: ['./list-detail-crash-event.component.css']
})
export class ListDetailCrashEventComponent implements OnInit {
  @Output() emitEventSelectedRow:EventEmitter<Report> = new EventEmitter<Report>();
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  dataSource: ListDetailCrashEventDataSource;

  displayedColumns = ['fecha_evento', 'speed', 'axis_x', 'axis_y', 'axis_z'];

  ngOnInit() {
    this.dataSource = new ListDetailCrashEventDataSource(this.paginator, this.sort, []);
  }

  setData(data: Report[]) {

    this.dataSource = new ListDetailCrashEventDataSource(this.paginator, this.sort, data);
  }

  setDisplayedColumns(columns: string[]) {

    this.displayedColumns = columns;
  }

  eventSelectedRow(row: Report) {

    this.emitEventSelectedRow.emit(row);
  }
}
