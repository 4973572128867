import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class EndPoindService {

  public END_POINT = environment.url_api;
  public END_POINT_FILE = environment.url_fotos;
}
