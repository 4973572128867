import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { EndPoindService } from './end-poind.service';
import { Conductor } from '../model/conductor';
import { HistorialAsignacionConductor } from '../model/historial-asignacion-conductor';

@Injectable({
  providedIn: 'root'
})
export class ConductorService {

  BASE_URL: String = this.api.END_POINT; 
  httpOptions = {};

  constructor(private http: HttpClient, public api: EndPoindService) {

    var token = localStorage.getItem('sess');
    if (token != undefined && token != null) {
    
      this.httpOptions = {
        headers: new HttpHeaders({
          'content-type':  'application/json',
          'authorization-x': token.toString()
        })
      };
    } 
  }

  getAll() {

    return this.http.get(this.BASE_URL.toString().concat('/conductor'), this.httpOptions);
  }
  
  getById(conductores_id: number) {

    return this.http.get(this.BASE_URL.toString().concat('/conductor/'+ conductores_id.toString()), this.httpOptions);
  }

  createConductor(data: Conductor) {

    return this.http.post(this.BASE_URL.toString().concat('/conductor'), data, this.httpOptions);
  }

  updateConductor(data: Conductor) {

    return this.http.put(this.BASE_URL.toString().concat('/conductor'), data, this.httpOptions);
  }

  deactivateConductor(conductor_id: number) {

    return this.http.delete(this.BASE_URL.toString().concat('/conductor/' + conductor_id.toString()), this.httpOptions);
  }

  asociarVehiculo(data: HistorialAsignacionConductor) {

    return this.http.post(this.BASE_URL.toString().concat('/conductor/asignacion'), data, this.httpOptions);
  }
  
  desasociarVehiculo(data: HistorialAsignacionConductor) {

    return this.http.post(this.BASE_URL.toString().concat('/conductor/desasignacion'), data , this.httpOptions);
  }
}
