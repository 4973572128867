import { Component, OnInit, ViewChild } from '@angular/core';
import {DateAdapter} from '@angular/material/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ReportListComponentComponent } from 'src/app/component/report-list-component/report-list-component.component';
import { MapComponent } from 'src/app/component/map/map.component';
import { ReportService } from 'src/app/service/report.service';
import { Report } from 'src/app/model/report';
import Swal from 'sweetalert2';
import { MapService } from '../../../service/map.service';

@Component({
  selector: 'app-report-crash-event',
  templateUrl: './report-crash-event.component.html',
  styleUrls: ['./report-crash-event.component.css']
})
export class ReportCrashEventComponent implements OnInit {

  public isRefresh: boolean = false; 
  public imeiId: string;
  public total: number;

  public indexRute: number;
  public isView: boolean = false;
  public dataList: Report[];

  public textoSinInfo: string;

  public mapSize: string;


  @ViewChild('table') table: ReportListComponentComponent;
  @ViewChild('map') map: MapComponent;

  constructor(private router: Router, private route: ActivatedRoute, private mapService: MapService, private reportService: ReportService, private adapter: DateAdapter<any>) { }

  ngOnInit() {

    this.imeiId = this.route.snapshot.paramMap.get('imeiid');
    this.total= 0;
    this.textoSinInfo = '';
    this.mapSize = '4';

    this.indexRute = 0;
    this.suscriber();

    var displayedColumns = ['fecha_evento_inicio', 'fecha_evento_fin', 'tiempo', 'geo', 'cod_evento', 'options'];
    this.table.setDisplayedColumns(displayedColumns as string[]);
    this.loadData();
  }

  loadData() {

      this.isRefresh = true;

      this.reportService.getHistorialChoques(this.imeiId).subscribe( response => {

        this.dataList = response as Report[];

        this.dataList.forEach( d => {

          this.mapService.getDireccionTextByLatLon(d.latitud, d.longitud).subscribe( response => d.geo = response['direccion'], err => console.log(err));
          //this.mapService.getDireccionTextOpenStreetMapByLatLon(d.latitud, d.longitud).subscribe( response => d.geo = response['display_name'], err => console.log(err));
          d.fechaGPS = d.fechaEvento;
          var a = new Date(d.fecha_evento_inicio);
          var b = new Date(d.fecha_evento_fin);
          var c = ((b.getTime()-a.getTime())/1000);
          d.duracion = c;
        });

        this.total = this.dataList.length;

        this.isRefresh = false;
        this.table.setData(this.dataList);

        var displayedColumns = ['fecha_evento_inicio', 'fecha_evento_fin', 'tiempo', 'geo', 'cod_evento', 'options'];
        this.table.setDisplayedColumns(displayedColumns as string[]);
        //this.map.setLineByReports(this.dataList);
        this.indexRute = 0;
        this.nextView(true);
      }, error => {

        Swal.fire({
          type: 'warning',
          title: 'No se puede obtener la información',
          text: 'Intente nuevamente más tarde.'
        });
        this.isRefresh = false;
      });

  }

  suscriber() {

    this.table.emitEventSelectedRow.subscribe( row => this.selectedRowEvent(row));
    this.table.emitEventSelectedOptions.subscribe(hash => this.selectedOption(hash));
  }

  nextView(isNext) {

    if (this.dataList != null && this.dataList.length > 0) {

      if (isNext) {

        if(this.dataList.length > this.indexRute) {

          this.indexRute = this.indexRute + 1;
          if ( this.dataList.length == this.indexRute) {
            this.indexRute = 0;
          }
        } else {

          this.indexRute = 0;
        }
        this.selectedRowEvent(this.dataList[this.indexRute]);
      } else {

        if(this.indexRute == 0) {

          this.indexRute = this.dataList.length -  1;
          
        } else {

          this.indexRute = this.indexRute - 1;
        }
        this.selectedRowEvent(this.dataList[this.indexRute]);
      }
    }
  }

  selectedRowEvent(row: Report) {

    this.map.clearPointReportMap();
    this.map.setPointReportMap(row);
    this.map.centerMap(row.latitud, row.longitud);
    this.mapSize = '100';
  }

  selectedOption(hash: Map<string, Report>) {

    if (hash.has('detail')) {

      //localStorage.setItem("d_crash", JSON.stringify(hash.get('detail')));
      var data: Report = hash.get('detail');
      var dataParam = {
        "id_gps" : data.id_gps,
        "fecha_evento_inicio" : data.fecha_evento_inicio.toString(),
        "fecha_evento_fin" : data.fecha_evento_fin.toString(),
        "duracion": data.duracion
      };

      this.router.navigate(['/detalle-deteccion-choque/'+JSON.stringify(dataParam)], { relativeTo: this.route});
    }
  }

  convertToCSV(objArray: any): string {
    var array = typeof objArray != 'object' ? JSON.parse(objArray) : objArray;
    var str = '';
    var row = "";

    for (var index in objArray[0]) {

        row += index + ';';
    }
    row = row.slice(0, -1);

    str += row + '\r\n';

    for (var i = 0; i < array.length; i++) {
        var line = '';
        for (var index in array[i]) {
            if (line != '') line += ';'

            line += array[i][index];
        }
        str += line + '\r\n';
    }
    return str;
  }

  downloadButtonPush() {

    var dataRows = [];
    this.dataList.forEach( eventos => dataRows.push({

      "Inicio Detección": eventos.fecha_evento_inicio,
      "Fin Detección": eventos.fecha_evento_fin,
      "Duración Seg": eventos.duracion,
      "Dirección": eventos.geo,
      "Longitud": eventos.longitud,
      "Latitud": eventos.latitud
    }));

    var csvData = this.convertToCSV(dataRows);
    var blob = new Blob(["\ufeff",csvData], { type: 'text/csv' });
    var url = window.URL.createObjectURL(blob);
  
    var filename = "deteciones-choques-gps-"+this.imeiId+".csv";

    if(navigator.msSaveOrOpenBlob) {

      navigator.msSaveBlob(blob, filename);
    } else {
      var a = document.createElement("a");
      a.href = url;
      a.download = filename;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    }
    window.URL.revokeObjectURL(url);
  }
}
