import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { GeoConfiguracion, Geozona, TipoEvento } from '../model/geozona';
import { EndPoindService } from './end-poind.service';

@Injectable({
  providedIn: 'root'
})
export class GeozonaService {
  BASE_URL: String = this.api.END_POINT;
  //BASE_URL: String = 'http://localhost/plataforma-tei-gps.core-api/api/apiClientes.php';
  httpOptions = {};
  eventList: TipoEvento[] = [];

  constructor(
    private http: HttpClient,
    public api: EndPoindService
  ) {

    var token = localStorage.getItem('sess');
    if (token != undefined && token != null) {

      this.httpOptions = {
        headers: new HttpHeaders({
          'content-type': 'application/json',
          'authorization-x': token.toString()
        })
      };
    }
  }

  /* 
    Metodos geozonas
   */
  getGeozonaById(id: number) {
    return this.http.get(`${this.BASE_URL}/geo/geozona/${id}`, this.httpOptions);
  }

  deleteGeozonaById(id: number) {
    return this.http.delete(`${this.BASE_URL}/geo/geozona/${id}`, this.httpOptions);
  }

  getGeozonas(): Observable<Geozona[]> {
    return this.http.get<Geozona[]>(`${this.BASE_URL}/geo/geozona`, this.httpOptions);
  }

  getGeozonasByPoint() {
    return this.http.get(`${this.BASE_URL}/geo/geozona/point/`, this.httpOptions); // TODO -> agregar parametros a la funcion y al path
  }

  createGeozona(geozona: Geozona) {
    return this.http.post(`${this.BASE_URL}/geo/geozona`, geozona, this.httpOptions);
  }

  editGeozona(geozona: Geozona) {
    return this.http.put(`${this.BASE_URL}/geo/geozona`, geozona, this.httpOptions);
  }

  /* 
    Metodos configuracion de geozonas
  */
  getConfiguracionById(id: number): Observable<GeoConfiguracion> {
    return this.http.get<GeoConfiguracion>(`${this.BASE_URL}/geo/config/${id}`, this.httpOptions);
  }

  deleteConfiguracionById(id: number) {
    return this.http.delete(`${this.BASE_URL}/geo/config/${id}`, this.httpOptions);
  }

  deleteConfiguracionByIdGeozona(id: number) {
    return this.http.delete(`${this.BASE_URL}/geo/config/bygeoid/${id}`, this.httpOptions);
  }

  getConfiguracionByGeozona(id: number): Observable<GeoConfiguracion[]> {
    return this.http.get<GeoConfiguracion[]>(`${this.BASE_URL}/geo/config/zona/${id}`, this.httpOptions);
  }

  createConfiguracionGeozona(configuracion: GeoConfiguracion) {
    return this.http.post(`${this.BASE_URL}/geo/config`, configuracion, this.httpOptions);
  }
  createListConfiguracionGeozona(configuracion: GeoConfiguracion[]) {
    return this.http.post(`${this.BASE_URL}/geo/config/list`, configuracion, this.httpOptions);
  }

  editConfiguracionGeozona(configuracion: GeoConfiguracion) {
    return this.http.put(`${this.BASE_URL}/geo/config`, configuracion, this.httpOptions);
  }

  getEvent= new Observable(observer => {
    setTimeout(() => {
      observer.next(this.eventList);
    }, 0);
  });
  /* 
    Metodos tipo eventos geozonas
   */
  getTiposEventos() {
    if(this.eventList != undefined && this.eventList.length > 0){
      return this.getEvent;
    }
    return this.http.get<TipoEvento[]>(`${this.BASE_URL}/geo/tipoeventos`, this.httpOptions);
  }
}
