import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { EndPoindService } from './end-poind.service';
import { GpsMap } from '../model/gps-map';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class MapService {

  BASE_URL: String = this.api.END_POINT; 
  httpOptions = {};
  public gpsList: Array<GpsMap> = [];


  constructor(private http: HttpClient, public api: EndPoindService) {

    var token = localStorage.getItem('sess');
    if (token != undefined && token != null) {
    
      this.httpOptions = {
        headers: new HttpHeaders({
          'content-type':  'application/json',
          'authorization-x': token.toString()
        })
      };
    } 
  }

  getGpsList = new Observable(observer => {
    setTimeout(() => {
        observer.next(this.gpsList);
    }, 0);
  });


  getAllLastPosition() {

    if (this.gpsList != undefined && this.gpsList.length > 0) {

      return this.getGpsList;
    }
    
    return this.http.get(this.BASE_URL.toString().concat('/map/getAllLastPosition'), this.httpOptions);
  }

  getAllLastPositionByStatus(status) {

    return this.http.get(this.BASE_URL.toString().concat('/map/getAllLastPosition/').concat(status), this.httpOptions);
  }

  getAllLastPositionLiteByStatus(status) {

    return this.http.get(this.BASE_URL.toString().concat('/map/getAllLastPosition/').concat(status).concat('/lite'), this.httpOptions);
  }

  getDireccionTextByLatLon(latitud, longitud) {

    return this.http.get(this.BASE_URL.toString().concat('/map/getDireccionTextByLatLon/').concat(latitud).concat('/').concat(longitud), this.httpOptions);
  }

  getDireccionTextGoogleByLatLon(latitud, longitud) {

    return this.http.get(this.BASE_URL.toString().concat('/map/getDireccionTextGoogleByLatLon/').concat(latitud).concat('/').concat(longitud), this.httpOptions);
  }

  getDireccionTextAWSByLatLon(latitud, longitud) {

    return this.http.get(this.BASE_URL.toString().concat('/map/getDireccionTextAwsByLatLon/').concat(latitud).concat('/').concat(longitud), this.httpOptions);
  }

  getDireccionTextOpenStreetMapByLatLon(latitud, longitud) {

    return this.http.get('https://nominatim.openstreetmap.org/reverse?format=json&lat=' + latitud + '&lon=' + longitud + '&zoom=18&addressdetails=1', this.httpOptions);
  }

  getAllLastPositionFromDB() {
 
    return this.http.get(this.BASE_URL.toString().concat('/map/getAllLastPosition'), this.httpOptions);
  }
}
