import { Component, OnInit, ViewChild, ModuleWithComponentFactories, AfterContentInit } from '@angular/core';
import { MAT_MOMENT_DATE_FORMATS, MomentDateAdapter, MomentDateModule } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { DateTimeAdapter, OWL_DATE_TIME_FORMATS, OWL_DATE_TIME_LOCALE, OwlDateTimeIntl } from 'ng-pick-datetime';

import { MomentDateTimeAdapter } from 'ng-pick-datetime-moment';
import swal from'sweetalert2';

import { ActivatedRoute } from '@angular/router';
import { ReportListComponentComponent } from 'src/app/component/report-list-component/report-list-component.component';
import { MapComponent } from 'src/app/component/map/map.component';
import { ReportService } from 'src/app/service/report.service';
import { Report } from 'src/app/model/report';
import { GpsMap } from 'src/app/model/gps-map';
import {
  DatetimeAdapter,
  MAT_DATETIME_FORMATS,
  MAT_NATIVE_DATETIME_FORMATS,
  MatDatetimepickerFilterType,
  NativeDatetimeAdapter
} from "@mat-datetimepicker/core";

import * as _moment from 'moment';
import Swal from 'sweetalert2';
import { MapService } from 'src/app//service/map.service';
import { User } from 'src/app/model/user';
import { TimeLineKilometraje, TimeLineKilometrajeComponent } from './time-line-kilometraje/time-line-kilometraje.component';

const moment = _moment;

export const MY_FORMATS = {
    parseInput: 'l LT',
    fullPickerInput: 'DD/MM/YYYY LT',
    datePickerInput: 'l',
    timePickerInput: 'LT',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY'
};

export type typeReportRuta = 'rango' | 'periodo';

export interface OptionReporteRuta {
  tipo: typeReportRuta;
  texto: string;
  targed: object;
}

export interface ReportePreproceRuta {
  id: number;
  operacion: string;
  fecha_actualizacion: string;
  datetime: string;
  reporte: Report[];
  length: number;
  mes: number;
  year: number;
}

@Component({
  selector: 'app-pantalla-reporte-kilometro-ruta',
  templateUrl: './pantalla-reporte-kilometro-ruta.component.html',
  styleUrls: ['./pantalla-reporte-kilometro-ruta.component.css'],
  providers: [

    { provide: MAT_DATE_LOCALE, useValue: 'es-CL' },
    { provide: DateTimeAdapter, useClass: MomentDateTimeAdapter, deps: [OWL_DATE_TIME_LOCALE] },
    { provide: OWL_DATE_TIME_FORMATS, useValue: MY_FORMATS }
  ],
})
export class PantallaReporteKilometroRutaComponent  implements OnInit, AfterContentInit {

  public isRefresh: boolean = false;
  public serialGps: string;
  public operacionGps: string;
  public total: number;
  public dateInit: Date;
  public dateEnd: Date;

  public user: User;
  public indexRute: number;
  public isView: boolean = false;
  public dataList: Report[];

  public timeRuta;
  public playEnable = false;
  public stopEnable = false;

  public textDireccionSelect: string;

  nombresMes = ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre" ];

  public listOptions: OptionReporteRuta[] = [
    {
      tipo: 'rango',
      texto: 'Rango de fechas (Max. 5 días)',
      targed: {}
    }
  ]

  public opcionSeleccionada: typeReportRuta;



  @ViewChild('timeline') timeline: TimeLineKilometrajeComponent;
  @ViewChild('table') table: ReportListComponentComponent;
  @ViewChild('map') map: MapComponent;

  constructor(private route: ActivatedRoute, 
    private reportService: ReportService, 
    private mapService: MapService, 
    private adapter: DateAdapter<any>,
    ) { }

  ngAfterContentInit() {

    setTimeout(() => this.map.selectMapSilver(), 1000)
  }

  ngOnInit() {

    this.serialGps = this.route.snapshot.paramMap.get('serial');
    this.operacionGps = this.route.snapshot.paramMap.get('operacion');
    this.dateInit = new Date();
    this.dateInit.setDate(this.dateInit.getDate() - 7);
    this.dateEnd = new Date();
    this.total = 0;
    this.user = JSON.parse(localStorage.getItem('user')) as User;

    this.indexRute = 0;
    
    this.suscriber();

    if (this.operacionGps) {

      this.reportService.getListReportRutaDisponiblesByOp(this.operacionGps).subscribe(
        response => {

          console.log(response)
          const listRepotes: ReportePreproceRuta[] = response as ReportePreproceRuta[];

          listRepotes.forEach( reportOption => {

            this.listOptions.push({
              tipo: 'periodo',
              texto: reportOption.mes + '/' + reportOption.year,
              targed: reportOption
            })

          })

        }, error => {
          console.error(error)
        }
      )
    }
  }

  changeOption(option: OptionReporteRuta) {

    console.log(option)

    this.isRefresh = true

    if (option.tipo == 'periodo') {

      const reportPreprocesado: ReportePreproceRuta = option.targed as ReportePreproceRuta
      this.reportService.getByReportRutaPreprocesado(this.operacionGps, reportPreprocesado.mes, reportPreprocesado.year).subscribe(
        response => {

          const report = response as ReportePreproceRuta
          this.dataList = report.reporte;
          this.dataList.forEach(d => setTimeout(() => {
            d.fechaGPS =  d.fechaGPS.replace('.000Z', '').replace('T', ' ')
            if (d.geo == null || d.geo == "") {

              this.mapService.getDireccionTextByLatLon(d.latitud, d.longitud).subscribe( response => d.geo = response['direccion'], err => console.log(err));
              //this.mapService.getDireccionTextOpenStreetMapByLatLon(d.latitud, d.longitud).subscribe( response => d.geo = response['display_name'], err => console.log(err));
            }
          }, 1));


          this.isRefresh = false;
          this.table.setData(this.dataList);
          var displayedColumns = ['geo', 'fechaGPS', 'velocidad'];
          this.table.setDisplayedColumns(displayedColumns as string[]);
          this.map.setLineByReports(this.dataList);
          this.indexRute = 0;
          this.nextView(true);
          this.playEnable = true;

          this.isRefresh = false;
        }, error => {

          console.error(error)
          this.isRefresh = false;
        } 
      )
    } else {

      this.isRefresh = false;
    }
  }

  loadData() {


    
    if (_moment.isMoment(this.dateInit)) {

      this.dateInit = new Date(this.dateInit.toDate());
    }

    if (_moment.isMoment(this.dateEnd)) {

      this.dateEnd = new Date(this.dateEnd.toDate());
    }

    if (this.dateInit != null && this.dateEnd != null) {

      var dateAux1 = new Date(this.dateInit.toDateString());
      var dateAux2 = new Date(this.dateEnd.toDateString());

      var diffMilisegundo = dateAux2.getTime() - dateAux1.getTime();
      var diffHoras = diffMilisegundo / (1000*60*60);

      var tiempoMaximoDeConsul = 743; // 744 hrs 31 dias y 120 horas.  
      
      if ( diffHoras > tiempoMaximoDeConsul) { //120 Horas = 5 dias 

        swal.fire({
          type: 'warning',
          title: 'Oops...',
          text: 'No se puede obtener un reporte de ruta con un rango de fecha mayor a 31 días.',
        })
        return;
      }

      this.isRefresh = true;

      this.reportService.getEventoGpsVelocidad(this.serialGps, this.dateInit.getTime(), this.dateEnd.getTime()).subscribe(response => {

        const tempList = response as Report[];

        this.dataList = [];

        //for filtra las tramas repetidas
        for (let i = 0; i < tempList.length; i++) {
          
          if (i > 0 && (i+1) < tempList.length) {

            let lastIndex = i - 1

            if (tempList[i].latitud != tempList[lastIndex].latitud) {

              this.dataList.push(tempList[i])
            }
          } else {

            this.dataList.push(tempList[i])
          }
        }


        var distancia_ini = 0;
        var velocidad_promedio_total = 0;
        var fecha_hasta = '';
        for (let i = 0; i < this.dataList.length; i++) {
            const e = this.dataList[i]

            const i2 = (this.dataList.length > (i+1)) ? i + 1 : i
     

            distancia_ini = distancia_ini + this.calcularDistancia(e, this.dataList[i2])//(calcularDistancia(queryPoint1, queryPoint2) * 100)
            velocidad_promedio_total =  Number.parseInt(e.velocidad) + velocidad_promedio_total
            fecha_hasta = e.fechaGPS


        }

        velocidad_promedio_total = velocidad_promedio_total / this.dataList.length

        console.log("distancia_ini ", distancia_ini);


        this.map.setLineByReports(this.dataList);
        this.indexRute = 0;
        this.nextView(true);
        this.playEnable = true;

        
        //build time line

        var hash_timeline = {};

        var data_timeline: TimeLineKilometraje[] = [] as TimeLineKilometraje[];

        this.dataList.forEach(data => {

          var fecha_evento = new Date(data.fechaGPS);

          var element = {
            mes: (this.nombresMes[fecha_evento.getMonth()]) + "",
            dia: fecha_evento.getDate() +  "",
            hora: '' ,
            fecha_evento: data.fechaGPS,
            velocidad_promedio: Number.parseInt(data.velocidad),
            count_prod: 0,
            kilometraje: 0,
            latitud: data.latitud,
            longitud: data.longitud,
            velocidad: data.velocidad,
            serial: this.serialGps,
            isTotal: false
          } as TimeLineKilometraje;
          data_timeline.push(element)
        })

        data_timeline.sort((a, b) => this.toDate(b.fecha_evento).getTime() - this.toDate(a.fecha_evento).getTime());

        for (let i = 0; i < data_timeline.length; i++) {

          const e = data_timeline[i]

          const i2 = (this.dataList.length > (i+1)) ? i + 1 : i

          var distancia = this.calcularDistancia(e, data_timeline[i2])//(calcularDistancia(queryPoint1, queryPoint2) * 100)
          e.kilometraje = distancia

          const key = e.dia + "-" + e.mes
          var map_value = (hash_timeline[key]) ? hash_timeline[key] : e

          map_value.kilometraje = e.kilometraje + map_value.kilometraje

  
          map_value.velocidad = (Number.parseInt(e.velocidad) + Number.parseInt(map_value.velocidad.toString())) 
          map_value.count_prod = map_value.count_prod + 1

          hash_timeline[key] = map_value
        }

        var total = {
          mes: "",
          dia:  "",
          hora: "",
          fecha_evento: "",
          velocidad_promedio: velocidad_promedio_total,
          count_prod: 0,
          kilometraje: distancia_ini,
          latitud: '0',
          longitud: '0',
          velocidad: '',
          serial: this.serialGps,
          isTotal: true
        } as TimeLineKilometraje;

        var data_timeline_group = []

        data_timeline_group.push(total)



        for (const key in hash_timeline) {
            const element = hash_timeline[key];
            element.velocidad_promedio = element.velocidad / element.count_prod
            data_timeline_group.push(element)
        }

        if (data_timeline_group.length > 2) {

          this.timeline.setTimeLine(data_timeline_group)
        } else {

          data_timeline_group = []

          data_timeline_group.push(total)

          hash_timeline = {};
          for (let i = 0; i < data_timeline.length; i++) {

            const e = data_timeline[i]
  
            const i2 = (this.dataList.length > (i+1)) ? i + 1 : i
  
            var distancia = this.calcularDistancia(e, data_timeline[i2])//(calcularDistancia(queryPoint1, queryPoint2) * 100)
            e.kilometraje = distancia
  
            const key = e.mes + "-" + e.dia + "_" + e.fecha_evento.split(" ")[1].substring(0,2)
            var map_value = (hash_timeline[key]) ? hash_timeline[key] : e
  
            map_value.kilometraje = e.kilometraje + map_value.kilometraje
  
    
            map_value.velocidad = (Number.parseInt(e.velocidad) + Number.parseInt(map_value.velocidad.toString())) 
            map_value.count_prod = map_value.count_prod + 1

            map_value.hora = e.fecha_evento.split(" ")[1].substring(0,2)
  
            hash_timeline[key] = map_value
          }

          for (const key in hash_timeline) {
            const element = hash_timeline[key];
            element.velocidad_promedio = element.velocidad / element.count_prod
            //element.dia = ''
            data_timeline_group.push(element)
          }

          this.timeline.setTimeLine(data_timeline_group)
        }

        

        


        this.isRefresh = false;
       /*
        this.table.setData(this.dataList);
        var displayedColumns = ['geo', 'fechaGPS', 'velocidad'];
        this.table.setDisplayedColumns(displayedColumns as string[]);
       
       
        */


      }, error => {

        Swal.fire({
          type: 'warning',
          title: 'No se puede obtener la información',
          text: 'Intente nuevamente más tarde.'
        });
        this.isRefresh = false;
      });
    } else {

      Swal.fire({
        type: 'error',
        title: 'Una de las fecha no es válida.'
      });
    }

  }

  suscriber() {

    this.table.emitEventSelectedRow.subscribe(row => this.selectedRowEvent(row));
  }
  
  playView() {

    this.playEnable = false;
    this.stopEnable = true;
    this.timeRuta = setTimeout(() => {

      this.nextView(true);
      this.playView();
    }, 1500); 
  }

  stopView() {

    this.playEnable = true;
    this.stopEnable = false;
    clearTimeout(this.timeRuta);
  }

  nextView(isNext) {

    if (this.dataList != null && this.dataList.length > 0) {

      if (isNext) {

        if (this.dataList.length > this.indexRute) {

          this.indexRute = this.indexRute + 1;
          if (this.dataList.length == this.indexRute) {
            this.indexRute = 0;
          }
        } else {

          this.indexRute = 0;
        }
        this.selectedRowEvent(this.dataList[this.indexRute]);
      } else {

        if (this.indexRute == 0) {

          this.indexRute = this.dataList.length - 1;

        } else {

          this.indexRute = this.indexRute - 1;
        }
        this.selectedRowEvent(this.dataList[this.indexRute]);
      }
    }
  }

  selectedRowEvent(row: Report) {

    this.map.clearPointReportMap();
    this.map.setPointReportMap(row);
    this.map.centerMap(row.latitud, row.longitud, 12);
    this.textDireccionSelect = row.geo;
  }

  calcularDistancia(p1, p2): number {

    /**
    El radio de la Tierra es de aproximadamente 6,371 kilómetros. Sin embargo, este valor puede variar ligeramente debido a la irregularidad en la distribución de masa en la Tierra. El radio promedio es tomado a través de varias medidas y estudios geodésicos.
    
    Sí, el radio ecuatorial de la Tierra es de aproximadamente 6,378 kilómetros, mientras que el radio polar es de aproximadamente 6,357 kilómetros. 
    Esto se debe a la forma elipsoidal de la Tierra, debido a su rotación y a la distribución desigual de masa en el interior del planeta. Por lo tanto, 
    se utilizan diferentes valores de radio dependiendo del contexto y la precisión requerida en la medición.
     */
        //sin²(Δlat/2) + cos(lat1).cos(lat2).sin²(Δlong/2)
        var earthRadiusKm = 6378.388; //Radio Ecuatorial 
      
        var dLat = this.degreesToRadians(p2.latitud-p1.latitud);
        var dLon = this.degreesToRadians( p2.longitud-p1.longitud);
        
        let lat1 = this.degreesToRadians(p2.latitud);
        let lat2 = this.degreesToRadians(p1.latitud);
    
        var a = Math.sin(dLat/2) * Math.sin(dLat/2) +
                Math.sin(dLon/2) * Math.sin(dLon/2) * Math.cos(lat1) * Math.cos(lat2); 
        var c = 2.025 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a)); //Fix Radio 2 a 2.1 holgura 
        return earthRadiusKm * c;
  }

  degreesToRadians(degrees): number {
    return degrees * Math.PI / 180;
  }

  convertToCSV(objArray: any): string {
    var array = typeof objArray != 'object' ? JSON.parse(objArray) : objArray;
    var str = '';
    var row = "";

    for (var index in objArray[0]) {

      row += index + ';';
    }
    row = row.slice(0, -1);

    str += row + '\r\n';

    for (var i = 0; i < array.length; i++) {
      var line = '';
      for (var index in array[i]) {
        if (line != '') line += ';'

        line += array[i][index];
      }
      str += line + '\r\n';
    }
    return str;
  }

  downloadButtonPush() {

    var csvData = this.convertToCSV(this.dataList);
    var blob = new Blob(["\ufeff", csvData], { type: 'text/csv' });
    var url = window.URL.createObjectURL(blob);

    var filename = "reporte-ruta-gps-" + this.serialGps + ".csv";

    if (navigator.msSaveOrOpenBlob) {

      navigator.msSaveBlob(blob, filename);
    } else {
      var a = document.createElement("a");
      a.href = url;
      a.download = filename;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    }
    window.URL.revokeObjectURL(url);
  }

  toDate(fecha: string): Date {
    return new Date(fecha);
  }

  isMovil = () => ( /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) ) ;
}
